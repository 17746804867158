@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{margin:0;padding:0;outline:0;}
html{font-size:63.5%;height:100%;}
body{font-family:"Poppins", sans-serif;font-weight:400;font-style:normal;box-sizing:border-box;position:relative;font-size:1.4rem;line-height:26px;color:#000000;background-color:#f4f6fd;background-image:url(../img/bgImg.png);background-repeat:no-repeat;background-size:cover;background-position:center;background-attachment:fixed;}
a,
a:hover,
a:focus,
a:active{text-decoration:none;outline:0;color:#444343;}
input,
input:focus,
input:hover,
input:active{outline:0;box-shadow:none;}
ul{margin:0 auto;padding:0;list-style:none;}
button,
button:hover,
button:active,
button:focus,
.btn.focus,
.btn:focus{box-shadow:none;outline:0;border:0;}

/*-------authPage-------*/
.authPageWrap{width:100%;height:100vh;background-color:#ffffff;}
.authPageWrap .pad0{padding:0;}
.authPageWrap .leftDataWrap{background:linear-gradient(180deg, #DDD1FF 0%, #7A56FF 100%);box-shadow:16px 4px 24.5px 0px #00000033;display:flex;flex-flow:row wrap;align-items:center;justify-content:center;border-radius:0 50% 50% 0;height:100vh;padding:60px 0 0;position:relative;}
/* .authPageWrap .leftDataWrap:after{content:"";background-image:url(../img/loginPageImg-2.png);background-repeat:no-repeat;width:300px;height:300px;position:absolute;background-size:contain;right:-150px;top:50%;transform:translateY(-50%);} */
.authPageWrap .leftDataWrap h2{text-align:left;width:100%;max-width:440px;color:#ffffff;margin:0;font-size:3.4rem;font-weight:700;}
.authPageWrap .leftDataWrap .imgWrap{width:100%;height:calc(100vh - 210px);}
.authPageWrap .leftDataWrap .imgWrap img{width: 100%;height:100%;object-fit:contain;}
.authPageWrap .formWrap{padding:0 100px;text-align:left;}
.authPageWrap .formWrap .logoWrap{text-align:left;margin:0;display:block;border:0;height:auto;}
.authPageWrap .formWrap .logoWrap img{width:160px;}
.authPageWrap .formWrap .logoWrap h3{margin:0;display:inline-block;background-color:#E6E6EE;color:#ffffff;border-radius:6px;font-size:2.4rem;font-weight:400;text-transform:uppercase;padding:6px 30px;}
.authPageWrap .formWrap .title{margin:10px 0 26px;}
.authPageWrap .formWrap .title h2{color:#000000;margin:0;font-size:3.2rem;font-weight:700;}
.authPageWrap .formWrap ul{width:100%;margin:0;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.authPageWrap .formWrap ul li.half{width:48%;}
.authPageWrap .formWrap ul li{margin:0 0 26px;width:100%;}
.authPageWrap .formWrap ul li.martBtm{margin:0 0 32px;}
.authPageWrap .formWrap ul li.martBtmLess{margin:0 0 6px;}
.authPageWrap .formWrap ul li .inputField{position:relative;}
.authPageWrap .formWrap ul li .inputField input{border:1px solid #AFAFAF;height:42px;width:100%;border-radius:6px;padding:5px 16px;color:#000000;font-weight:400;}
.authPageWrap .formWrap ul li .inputField .btnShowPwd{position:absolute;right:10px;top:14px;padding:0;margin:0;border:0;outline:0;box-shadow:none;}
.authPageWrap .formWrap ul li .inputField .btnShowPwd:after{content:"";width:4px;height:20px;background-color:#434343;position:absolute;transform:rotate(45deg);left:8px;top:-2px;border:1px solid #ffffff;}
.authPageWrap .formWrap ul li .inputField .btnShowPwd.active:after{content:none;}
.authPageWrap .formWrap ul li .inputField a.forgotPwd{display:block;text-align:right;color:#8D6EFF;font-weight:400;font-size:1.3rem;}
.authPageWrap .formWrap ul li .inputField input::placeholder{color:#CECCCC;}
.authPageWrap .formWrap ul li .ctaWrap{margin:0;}
.authPageWrap .formWrap ul li .ctaWrap .btn{background-color:#8D6EFF;box-shadow:0px 4px 4px 0px #00000040;border:1px solid #9A7DFF;width:100%;color:#ffffff;font-size:1.6rem;font-weight:500;border-radius:30px;padding:10px 20px;}
.authPageWrap .formWrap ul li .checkBoxWrap p,
.authPageWrap .formWrap ul li .ctaWrap p{margin:4px 0 0;color:#000000;font-size:1.3rem;font-weight:400;}
.authPageWrap .formWrap ul li .checkBoxWrap p a,
.authPageWrap .formWrap ul li .ctaWrap p a{color:#8D6EFF;}
.authPageWrap .formWrap ul li .ctaWrap p{margin:4px 0 0;color:#000000;font-size:1.3rem;font-weight:400;}
.authPageWrap .formWrap ul li .checkBoxWrap{margin:-6px 0 0 -38px;display:flex;flex-flow:row wrap;align-items:center;justify-content:center;width:calc(100% + 50px);}
.authPageWrap .formWrap ul li .checkBoxWrap .form-check .form-check-input{margin:8px 10px 0 -10px;outline:0;box-shadow:none;border:0.5px solid #AFAFAF;width:14px;height:14px;padding:0;border-radius:3px;}
.authPageWrap .formWrap ul li .checkBoxWrap .form-check .form-check-input:checked{background-color:#8D6EFF;border-color:#8D6EFF;}
.authPageWrap .formWrap ul li .checkBoxWrap .form-check .form-check-label{color:#000000;font-size:1.3rem;font-weight:400;}
.authPageWrap .formWrap ul li .checkBoxWrap p{margin:0 0 0 4px;}
.authPageWrap .formWrap ul li .ctaWrap .socialIcoWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:center;margin:0 auto 12px;}
.authPageWrap .formWrap ul li .ctaWrap .socialIcoWrap .btn{width:auto;background-color:#ffffff;border-radius:6px;border:1px solid #e7e7e7;box-shadow:none;padding:8px 30px;margin:0 6px;}
.authPageWrap .formWrap ul li .ctaWrap .socialIcoWrap .btn img{width:28px;}
/*-------authPage-------*/

/*-------SiderBar-------*/
.app-container .sidebar{position:fixed;top:60px;left:0px;box-shadow:4px -1px 8.2px 0px #00000033;height:calc(100vh - 70px);line-height:inherit;transition:.3s;width:80px;z-index:9;border-radius:0 26px 26px 0;background-color:#1944F1;}
.app-container.sidebar-open .sidebar.open{left:0;width:200px;}
.toggle-btn{border:none;padding:0px;position:absolute;right:-28px;background-color:#d2dbff;box-shadow:0px 4px 4px 0px #00000033!important;top:35%;transform:translateY(-50%);width:28px;height:38px;display:flex;align-items:center;justify-content:center;border-radius:0 8px 8px 0;}
.app-container.sidebar-open .toggle-btn svg{transform:none;}
.toggle-btn svg{transform:rotate(180deg);width:18px;height:14px;}
.logoWrap{height:70px;text-align:center;display:flex;flex-flow:row wrap;align-items:center;justify-content:center;
  /* border-bottom:1px solid #f2f2f2;background-color:#FFFFFF; */
  border-radius:0 26px 0 0;
}
/* .app-container.sidebar-open .logoWrap a{font-size:2.2rem;padding:10px 30px;}
.app-container .logoWrap a{font-size:1.6rem;padding:6px 10px;color:#ffffff;background-color:#1944F1;font-weight:400;border-radius:8px;text-transform:uppercase;letter-spacing:1.2px;} */
.app-container.sidebar-open .sidebar.open ul.menu{padding:12px 12px 0 12px;}
ul.menu>li{padding:0;background-color:#FFFFFF;border-radius:12px;}
ul.menu>li:last-child{margin:0;}
ul.menu li a{display:flex;align-items:center;flex-flow:row wrap;}
.app-container.sidebar-open .sidebar>ul.menu>li a{padding:8px 12px;justify-content:flex-start;}
.app-container .sidebar ul.menu li{position:relative;}
.app-container .sidebar ul.menu li a{padding:12px 0;justify-content:center;}
ul.menu li a .iconWrap{width:30px;text-align:center;}
ul.menu li a .dataWrap{font-size:1.4rem;color:#515151;margin:0 0 0 6px;font-weight:500;}
ul.menu li .accordion{margin:0;}
ul.menu li .accordion .accordion-item{border:0;border-radius:0;background-color:transparent;}
ul.menu li .accordion .accordion-item .accordion-header{padding:8px 10px 8px 12px;}
ul.menu li .accordion .accordion-item .accordion-header .accordion-button{padding:0;}
ul.menu li .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed){background-color:transparent;outline:0;box-shadow:none;}
ul.menu li .accordion .accordion-item .accordion-header .accordion-button .dataWrap{padding:0;color:#515151;text-transform:capitalize;font-weight:500;font-size:1.4rem;line-height:26px;margin:0 0 0 12px;}
ul.menu li .accordion .accordion-item:last-of-type>.accordion-header .accordion-button.collapsed{background-color:transparent!important;outline:0!important;box-shadow:none!important;}
.app-container.sidebar-open .sidebar ul.menu li ul.sidebarMenu{margin:0;position:relative;}
.app-container.sidebar-open .sidebar ul.menu li ul.sidebarMenu:before{content:"";background-color:rgba(81, 81, 81, 0.50);width:0.5px;height:calc(100% - 12px);position:absolute;left:-18px;top:-8px;}
.app-container.sidebar-open .sidebar ul.menu li ul.sidebarMenu li{line-height:normal;margin:0 0 3px;position:relative;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.app-container.sidebar-open .sidebar ul.menu li ul.sidebarMenu li:before{content:"";background:url("data:image/svg+xml;utf8,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.79303 1.01709C0.79303 4.19969 2.05731 7.25176 4.30775 9.5022C6.55819 11.7526 9.61043 13.0171 12.793 13.0171H13.233' stroke='%23515151' stroke-opacity='0.5' stroke-width='0.5' strokeMiterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E");background-repeat:no-repeat;background-size:contain;width:14px;height:14px;position:absolute;left:-18px;top:9px;}
.app-container.sidebar-open .sidebar ul.sidebarMenu li a{display:flex;align-items:center;justify-content:flex-start;flex-flow:row wrap;padding:8px 6px;position:relative;font-size:1.3rem;letter-spacing:0.1px;-webkit-transition:all .4s;transition:all .4s;font-weight:500;border-radius:8px;color:#515151;width:100%;max-width:110px;border:1px solid transparent;}
.app-container.sidebar-open .sidebar ul.sidebarMenu li.active a,
.app-container.sidebar-open .sidebar ul.sidebarMenu li:hover a{background-color:rgb(26 68 241 / 13%);border:1px solid rgb(26 68 241 / 47%);line-height:normal;}
ul.sidebarMenu li a .dataWrap{font-size:1.1rem;font-weight:400;margin:0 0 0 3px;}
ul.sidebarMenu li a .iconWrap{width:22px;text-align:center;}
.app-container.sidebar-open .sidebar ul.sidebarMenu li span.badge{color:#ffffff;background-color:rgba(244, 34, 34, 0.70);border:1px solid #AF1515;border-radius:30px;font-size:0.8rem;font-weight:500;width:auto;height:auto;padding:2px 4px;position:absolute;right:-12px;top:50%;transform:translateY(-50%);}
ul.sidebarMenu li a span{display:inline-block;text-align:left;width:calc(100% - 30px);color:#1e2f65;}
ul.sidebarMenu li:hover a,
ul.sidebarMenu li:hover a span,
ul.sidebarMenu li.active a .dataWrap,
ul.sidebarMenu li:hover a .dataWrap{color:#1944F1;}
ul.sidebarMenu li.active a .iconWrap svg path,
ul.sidebarMenu li:hover a .iconWrap svg path{fill:#1944F1;}
ul.sidebarMenu li.active a .iconWrap.icoStroke svg path,
ul.sidebarMenu li:hover a .iconWrap.icoStroke svg path{stroke:#1944F1;fill:none;}
.app-container .sidebar ul.menu{padding:12px 12px 0 12px;}
.app-container .sidebar ul.menu>li{margin:0 0 20px;}
.app-container.sidebar-open .sidebar ul.menu>li{margin:0 0 10px;}
.app-container .sidebar ul.menu li a .dataWrap,
.app-container .sidebar ul.menu li .accordion .accordion-item .accordion-header .accordion-button .dataWrap{display:none;}
.app-container.sidebar-open .sidebar ul.menu li a .dataWrap,
.app-container.sidebar-open .sidebar ul.menu li .accordion .accordion-item .accordion-header .accordion-button .dataWrap{display:inline-block;}
.app-container.sidebar-open .sidebar ul.menu li .accordion .accordion-item .accordion-collapse .accordion-body{padding:0px 14px 20px 42px;}
.app-container .sidebar ul.menu li .accordion .accordion-item .accordion-collapse .accordion-body{padding:20px 14px 20px 14px;}
.app-container.sidebar-open .sidebar ul.menu li .accordion-button:not(.collapsed)::after{transform:rotate(1800deg);margin:0 0 0 auto;}
.app-container .sidebar ul.menu li .accordion-button:not(.collapsed)::after{transform:rotate(-90deg);margin:0 0 0 8px;}
.app-container .sidebar ul.menu li ul.sidebarMenu li:before,
.app-container .sidebar ul.menu li ul.sidebarMenu:before{content:none;}
.app-container .sidebar ul.menu li ul.sidebarMenu li a{border:1px solid transparent;background-color:transparent;padding:6px 4px;}
.app-container .sidebar ul.sidebarMenu li span.badge{color:transparent;width:6px;height:6px;padding:0;position:absolute;top:12px;right:4px;background-color:rgba(244, 34, 34, 0.70);border:1px solid #AF1515;border-radius:100%;}
/*-------SiderBar-------*/

/*-------Header-------*/
.header{background-color:transparent;max-width:100vw;position:fixed;top:0;transition:.5s;z-index:9;width:100%;height:50px;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
/* .app-container.sidebar-open .header{padding:0 0 0 210px;} */
/* .app-container .header{padding:0 0 0 90px;} */
.app-container .header .hearderWrap{width:100%;background-color:#ffffff;height:100%;display:flex;align-items:center;justify-content:space-between;border-radius:0 0 12px 12px;padding:2px 10px;}
.app-container .header .hearderWrap .leftPannel{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.app-container .header .hearderWrap .leftPannel .logoWrap{height:auto;margin:0 80px 0 0;}
.app-container .header .hearderWrap .leftPannel .logoWrap a{padding:0.6rem;display:inline-block;width:120px;}
.header .leftPannel .ctaListWrap ul{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.header .leftPannel .ctaListWrap ul li{margin:0 6px 0 0;}
.header .leftPannel .ctaListWrap ul li:last-child{margin:0;}
.header .leftPannel .ctaListWrap ul li .btn{outline:0;background-color:transparent;border:1px solid rgba(237, 237, 237, 0.70);padding:8px 6px;font-size:1rem;color:#515151;font-weight:400;border-radius:6px;} 
.header .leftPannel .ctaListWrap ul li:hover .btn,
.header .leftPannel .ctaListWrap ul li.active .btn{border:1px solid rgb(26 68 241 / 47%);background-color:#ebefff;color:#1944F1;}
.header .leftPannel .ctaListWrap ul li .btn svg{margin:0 4px 0 0;}
.header .leftPannel .ctaListWrap ul li:hover .btn svg path,
.header .leftPannel .ctaListWrap ul li.active .btn svg path{fill:#1944F1;}
/* .header .leftPannel .ctaListWrap ul li:last-child .btn{color:#1944F1;background-color:#ebefff;font-weight:500;border:1px solid rgb(26 68 241 / 47%);} */
.header .rightPannel{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.header .rightPannel .userWelcomeWrap{position:relative;margin:0;padding:0 14px 0 0;}
.header .rightPannel .userWelcomeWrap:after{content:"";background-color: #CCCCCC;width:1px;height:100%;position:absolute;right:0px;top:50%;transform:translateY(-50%);}
.header .rightPannel .userWelcomeWrap h3{color:#868C9C;margin:0;font-size:1.1rem;font-weight:700;text-align:right;} 
.header .rightPannel .userWelcomeWrap h2{color:#020202;margin:0;font-size:1.1rem;font-weight:700;}
.header .rightPannel .userWelcomeWrap h2 span{color:#868C9C;}
.header .rightPannel .rightNavWrap ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.header .rightPannel .rightNavWrap ul li{margin:0 0 0 14px;}
.header .rightPannel .rightNavWrap ul li a{position:relative;display:inline-block;}
.header .rightPannel .rightNavWrap ul li a .icoWrap svg{width:18px;height:18px;}
.header .rightPannel .rightNavWrap ul li a .badge{position:absolute;display:flex;background-color:#1944F1;padding:0;width:14px;height:14px;align-items:center;justify-content:center;top:-3px;right:-8px;font-size:0.8rem;}
.header .rightPannel .rightNavWrap ul li a .imgWrap{width:38px;height:38px;}
.header .rightPannel .rightNavWrap ul li a .imgWrap img{width:100%;height:100%;object-fit:cover;}
/*-------Header-------*/

.app-container.sidebar-open .content{padding:60px 0 0 210px;}
.app-container .content{padding:60px 0 0 90px;background-color:transparent;}

/*-------Products-------*/
.productListWrap{width:100%;padding:0 14px 0 4px;margin:0 auto;}
.productListWrap .topFilterWrap{background-color:#FFFFFF;border-radius:10px;padding:6px 2px;}
.productListWrap .topFilterWrap .leftWrap{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.productListWrap .topFilterWrap .leftWrap .arrowIco{margin:0 8px 0 0;}
.productListWrap .topFilterWrap .leftWrap .arrowIco .btn{border:0;outline:0;box-shadow:none;background-color:#E6E6EE;padding:0;width:30px;height:30px;border-radius:6px;display:flex;align-items:center;justify-content:center;}
.productListWrap .topFilterWrap .leftWrap h2{margin:0 28px 0 0;color:#000000;font-size:1.6rem;font-weight:500;text-transform:capitalize;}
.productListWrap .topFilterWrap .leftWrap .searchWrap{background:linear-gradient(148.02deg, #757575 30.73%, #919191 125.02%);border-radius:30px;padding:1px;width:300px;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;height:32px;}
.productListWrap .topFilterWrap .leftWrap .searchWrap input{width:calc(100% - 36px);height:100%;border:0;border-radius:30px 0 0 30px;padding:2px 14px;font-size:1.3rem;color:#000000;font-weight:400;}
.productListWrap .topFilterWrap .leftWrap .searchWrap input::placeholder{color:#B5B5B5;font-size:1.2rem;font-weight:400;}
.productListWrap .topFilterWrap .leftWrap .searchWrap .btn{background-color:#ffffff;border-radius:0 30px 30px 0;border:0;outline:0;box-shadow:none;height:100%;width:36px;}
.productListWrap .topFilterWrap .rightWrap{display:flex;flex-flow:row wrap;justify-content:flex-end;align-items:center;}
.productListWrap .topFilterWrap .rightWrap ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;margin:0;}
.productListWrap .topFilterWrap .rightWrap ul li{margin:0 8px 0 0;}
.productListWrap .topFilterWrap .rightWrap ul li .btn{color:#939393;background-color:transparent;outline:0;box-shadow:none;border:1px solid #C1C1C1;border-radius:30px;font-size:1.2rem;font-weight:300;padding:6px 14px;}
.productListWrap .topFilterWrap .rightWrap ul li .btn svg{margin:0 0 0 6px;}
.productListWrap .topFilterWrap .rightWrap ul li select{appearance:none;-webkit-appearance:none;outline:0;box-shadow:none;color:#939393;border:1px solid #C1C1C1;border-radius:30px;font-size:1.2rem;font-weight:300;height:32px;padding:1px 26px 1px 10px;text-align:left;background-size:10px 8px;}
.productListWrap .topFilterWrap .rightWrap .ctaWrap .btn{background-color:#1a44f1;outline:0;box-shadow:none;color:#FFFFFF;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;border:1px solid #1a44f1;font-size:1.2rem;font-weight:500;padding:6px 10px;border-radius:6px;}
.productListWrap .topFilterWrap .rightWrap .ctaWrap .btn .icoWrap{background-color:#ffffff;width:14px;height:14px;display:flex;align-items:center;justify-content:center;border-radius:100%;margin:0 4px 0 0;}
.productListWrap .sortingWrap{padding:10px 0 0;}
.productListWrap .sortingWrap ul{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.productListWrap .sortingWrap ul li{margin:0 6px 0 0;}
.productListWrap .sortingWrap ul li .btn{outline:0;box-shadow:none;border:1px solid #C1C1C1;color:#939393;background-color:#ffffff;border-radius:30px;font-size:1.1rem;font-weight:400;min-width:78px;display:inline-block;padding:5px 2px;}
.productListWrap .sortingWrap ul li:hover .btn,
.productListWrap .sortingWrap ul li.active .btn{background-color:#ebefff;color:#1944F1;border:1px solid rgb(26 68 241 / 47%);}
.productListWrap .topFilterWrap .rightWrap ul li .viewWrapCta{outline:0;box-shadow:none;min-width:78px;display:inline-block;padding:0;position:relative;}
.productListWrap .topFilterWrap .rightWrap ul li .viewWrapCta .btn{border:0;border:1px solid #C1C1C1;}
.productListWrap .topFilterWrap .rightWrap ul li .viewWrapCta .btn.btnGrid{border-right:0;border-radius:30px 0 0 30px;}
.productListWrap .topFilterWrap .rightWrap ul li .viewWrapCta .btn.btnList{border-left:0;border-radius:0 30px 30px 0;}
.productListWrap .topFilterWrap .rightWrap ul li .viewWrapCta .btn svg{margin:0;width:14px;height:14px;}
.productListWrap .topFilterWrap .rightWrap ul li .viewWrapCta .btn.active svg path{fill:#1944F1;}
.productListWrap .topFilterWrap .rightWrap ul li .viewWrapCta .btn.active{background-color:#ebefff;border:1px solid rgb(26 68 241 / 47%);} 
.productListWrap .productListing{padding:12px 10px 0;}
.productListWrap .productListing .padLtRt{padding:0 8px 16px;}
.productListWrap .itemWrap{background-color:rgb(255, 255, 255);box-shadow:0px 2px 2px 0px #0000001A;border-radius:6px;margin:0;height:100%;}
.productListWrap .itemWrap .imgWrap{position:relative;background-color:#1a44f14f;overflow:hidden;/* height:140px;*/ border-radius:6px 6px 0 0;
  height: 22vh; /* Base height */
}
.productListWrap .itemWrap .imgWrap:after{content:"";background:linear-gradient(180deg, #0b002a 0%, #1a44f1 100%);position:absolute;width:100%;height:100%;top:0;left:0;opacity:0.22;}
.productListWrap .itemWrap .imgWrap img{width:100%;height:100%;object-fit:cover;}
.productListWrap .itemWrap .imgWrap .btnEdit{position:absolute;background-color:transparent;padding:0;border:1px solid #ffffff;width:26px;height:26px;display:flex;align-items:center;justify-content:center;border-radius:100%;right:10px;top:10px;z-index:2;}
.productListWrap .itemWrap .dataWrap{padding:10px;}
.productListWrap .col-md-4 .itemWrap .dataWrap{min-height:102px;}
.productListWrap .itemWrap .dataWrap .topTtl{display:flex;flex-flow:column;justify-content:flex-start;align-items:flex-start;}
.productListWrap .itemWrap .dataWrap .topTtl h2{color:#000000;margin:0;font-size:1.2rem;font-weight:500;line-height:20px;padding:0;    overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;}
.productListWrap .itemWrap .dataWrap .topTtl ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;margin:0;}
.productListWrap .itemWrap .dataWrap .topTtl ul li{margin:0 0 0 8px;position:relative;line-height:16px;}
.productListWrap .itemWrap .dataWrap .topTtl ul li:first-child{margin:0;}
.productListWrap .itemWrap .dataWrap .topTtl ul li:nth-child(2){padding:0 0 0 8px;}
.productListWrap .itemWrap .dataWrap .topTtl ul li:nth-child(2):before{content:"";background-color:#efeeee;width:1px;height:100%;position:absolute;left:0;}
.productListWrap .itemWrap .dataWrap .topTtl ul li p{color:rgba(0,0,0,0.30);margin:0;font-size:1.1rem;font-weight:400;line-height:14px;}
.productListWrap .itemWrap .dataWrap .topTtl ul li .icoWrap svg{width:14px;height:14px;}
.productListWrap .itemWrap .dataWrap .descWrap{margin:4px 0 0;max-width:100%;}
.productListWrap .itemWrap .dataWrap .descWrap p{color:#878787;margin:0;font-size:1.1rem;font-weight:400;line-height:16px;
overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;}
.productListWrap .itemWrap .dataWrap .btmData{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.productListWrap .itemWrap .dataWrap .btmData .leftWrap{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.productListWrap .itemWrap .dataWrap .btmData .leftWrap h3{color:#000000;margin:0;font-size:2rem;font-weight:600;}
.productListWrap .itemWrap .dataWrap .btmData .leftWrap h3 span{font-size:1.4rem;}
.productListWrap .itemWrap .dataWrap .btmData .leftWrap h4{color:#8A8A8A;margin:0 0 0 8px;background-color:#F2EEFE;font-size:1.2rem;font-weight:400;padding:6px 16px;border-radius:30px;}
.productListWrap .itemWrap .dataWrap .btmData .ctaWrap .btn{background-color:#F2EEFE;border:1px solid #5F2DF054;padding:0;border-radius:8px;height:28px;width:28px;display:flex;align-items:center;justify-content:center;box-shadow:none;outline:none;}
/*-------Products-------*/

/*-------AsideBar-------*/
.asideBarWrap{background-color:#ffffff;box-shadow:0px 6px 8.6px 0px #00000033;width:calc(100% + 30px);padding:20px;border-radius:20px 0 0 20px;height:calc(100vh - 68px);}
@supports (position:sticky){
    .is-sticky{position:sticky;top:58px!important;-webkit-transition:all .3s linear;-moz-transition:all .3s linear;-o-transition:all .3s linear;transition:all .3s linear;}
}
.asideBarWrap .topTTlWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;}
.asideBarWrap .topTTlWrap .btn{padding:0;border:0;outline:0;box-shadow:none;margin:0 10px 0 0;}
.asideBarWrap .topTTlWrap .btn svg{width:12px;height:12px;}
.asideBarWrap .topTTlWrap h2{margin:0;color:#1944F1;font-size:1.6rem;font-weight:500;}
.asideBarWrap .imgWrap{background-color:#D3DAEF;height:160px;border-radius:22px;margin:16px auto 6px;position:relative;}
.asideBarWrap .imgWrap img{ width: 100%; height: 100%; object-fit:cover;border-radius:22px;}
.asideBarWrap .imgWrap .btnEdit{position:absolute;background-color:transparent;padding:0;border:1px solid #ffffff;width:26px;height:26px;display:flex;align-items:center;justify-content:center;border-radius:100%;right:10px;top:10px;}
.asideBarWrap .btmDataWrap .ProduTtl{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;margin:0 0 16px;}
.asideBarWrap .btmDataWrap .ProduTtl h2{color:#000000;margin:0;font-size:2rem;font-weight:600;}
.asideBarWrap .btmDataWrap .ProduTtl .ratingWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-end;}
.asideBarWrap .btmDataWrap .ProduTtl .ratingWrap .icoWrap{margin:0 8px 0 0;}
.asideBarWrap .btmDataWrap .ProduTtl .ratingWrap .icoWrap svg{width:22px;height:22px;}
.asideBarWrap .btmDataWrap .ProduTtl .ratingWrap h3{color:#000000;margin:0;font-size:2.4rem;font-weight:500;}
.asideBarWrap .btmDataWrap .ProduTtl .ratingWrap h3 span{color:#999999;font-size:1.6rem;}
.asideBarWrap .btmDataWrap ul li{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;border-top:0.5px solid #E3E3E3;  padding:5px 0 0;margin:5px 0 0;line-height:normal;}
.asideBarWrap .btmDataWrap ul li:last-child{border:0;}
.asideBarWrap .btmDataWrap ul li h4{color:#797979;margin:0;font-size:1.4rem;font-weight:500;}
.asideBarWrap .btmDataWrap ul li p{text-align:right;color:#797979;margin:0;font-size:1.4rem;font-weight:500;}
.asideBarWrap .btmDataWrap ul li.full{flex-flow:column;align-items:flex-start;}
.asideBarWrap .btmDataWrap ul li textarea{width:100%;border:0.5px solid #00000045;border-radius:8px;resize:none;height:80px;padding:6px;font-size:1.2rem;color:#797979;margin:0;outline:0!important;box-shadow:none!important;}
.asideBarWrap .btmDataWrap ul li h3{color:#000000;margin:0;font-size:2rem;font-weight:600;}
.asideBarWrap .btmDataWrap ul li h5{color:#000000;margin:0;text-align:right;font-size:2rem;font-weight:600;}
.asideBarWrap .btmDataWrap .ctaWrap{text-align:right;margin:12px 0 0;}
.asideBarWrap .btmDataWrap .ctaWrap .btn{background-color:#1944F1;box-shadow:0px 4px 4px 0px #00000033;border-radius:30px;border:0;outline:0;color:#ffffff;font-size:1.2rem;font-weight:400;padding:8px 26px;}
.asideBarWrap .btmDataWrap ul li.inputFields{border:0;margin:0 0 10px;position:relative;}
.asideBarWrap .btmDataWrap ul li.inputFields input,
.asideBarWrap .btmDataWrap ul li.inputFields select{border:1px solid #D9D9D9;width:100%;outline:0;box-shadow:none;border-radius:6px;height:42px;padding:2px 10px;font-size:1.2rem;color:#797979;appearance:none;appearance:none;-webkit-appearance:none;position:relative;}
.asideBarWrap .btmDataWrap ul li.inputFields select{padding:2px 38px 2px 10px;}
/* .asideBarWrap .btmDataWrap ul li.inputFields.selectWrap:after{content:"";width:1px;height:28px;background-color:rgba(0,0,0,0.16);position:absolute;right:32px;top:12px;} */
.asideBarWrap .btmDataWrap ul li.inputFields .css-b62m3t-container{ width: 100%;}
.asideBarWrap .btmDataWrap ul li.inputFields .css-13cymwt-control{border:0.5px solid #00000045;border-radius:8px;box-shadow:none!important;outline:none!important;}
.css-t3ipsp-control:hover,
.css-t3ipsp-control:focus{border:0.5px solid #00000045!important;box-shadow:none!important;outline:none!important;}
/*-------AsideBar-------*/

/*-------Order-------*/
.orderTblWrap{margin:10px 0 0;}
.orderTblWrap .table-container{overflow-x:auto;white-space:nowrap;padding:12px;background-color:#ffffff;border-radius:8px;}
.orderTblWrap .react-bootstrap-table .table thead{box-shadow:0px 3px 7.5px 0px #00000033;position:relative;border-radius:6px;}
.orderTblWrap .react-bootstrap-table .table thead tr{border:0;}
.orderTblWrap .react-bootstrap-table .table thead tr th{border:0;color:#636363;text-align:center;font-weight:500;font-size:1.2rem;letter-spacing:0.2px;padding:16px 8px;line-height:14px;border-radius:6px;}
.orderTblWrap .react-bootstrap-table .table thead tr th:nth-child(1){border-radius:6px 0 0 6px;}
.orderTblWrap .react-bootstrap-table .table tbody tr{border:0;}
.orderTblWrap .react-bootstrap-table .table tbody tr:nth-child(1) td{padding:20px 8px 8px;}
.orderTblWrap .react-bootstrap-table .table tbody tr td{border:0;color:#000000;font-size:1.2rem;font-weight:500;letter-spacing:0.2px;padding:10px 8px;border-bottom:0.5px solid #0000004D;text-align:center;}
.orderTblWrap .react-bootstrap-table .table tbody tr td .btnAction{border:0;border-radius:0;outline:0;box-shadow:none;background-color:transparent;margin:0 4px;}
.orderTblWrap .react-bootstrap-table .table tbody tr td .btnAction:hover svg path{fill:#1944F1;}
.orderTblWrap .asideBarWrap{overflow-y:auto;}
.orderTblWrap .asideBarWrap .topTtl{text-align:left;}
.orderTblWrap .asideBarWrap .topTtl h2{color:#000000;font-size:1.5rem;margin:0;}
.orderTblWrap .asideBarWrap .topTtl p{color:#808080;font-size:1.2rem;line-height:20px;}
.orderTblWrap .asideBarWrap .productTbl{border:1px solid #C7C7C7;border-radius:6px;padding:12px 0px;}
.orderTblWrap .asideBarWrap .productTbl table{width:100%;} 
.orderTblWrap .asideBarWrap .productTbl table tr th{color:#000000;font-weight:500;padding:0 8px 10px;font-size:1.3rem;}
.orderTblWrap .asideBarWrap .productTbl table tr td{color:#000000;font-weight:500;vertical-align:top;padding:0 8px 10px;font-size:1.3rem;}
.orderTblWrap .asideBarWrap .productTbl table tr td .imgWrap{background-color:#D9D9D9;width:60px;height:40px;border-radius:6px;margin:0;}    
.orderTblWrap .asideBarWrap .productTbl table tr td .dataWrap{margin:0 0 0 6px;padding:2px 0;}
.orderTblWrap .asideBarWrap .productTbl table tr td .dataWrap h2{font-size:1.3rem;font-weight:500;margin:0;}
.orderTblWrap .asideBarWrap .productTbl table tr td .dataWrap p{color:#878787;margin:0;font-size:1.1rem;line-height:16px;}
.orderTblWrap .asideBarWrap .productTbl table tr td.bdrTop{border-top:0.5px solid #0000004D;padding-bottom:0;}
.orderTblWrap .asideBarWrap .productTbl table tr td ul{padding:8px 0 0;}
.orderTblWrap .asideBarWrap .productTbl table tr td ul li{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;margin:0 0 2px;}
.orderTblWrap .asideBarWrap .productTbl table tr td ul li p{color:#808080;font-size:1.3rem;font-weight:400;margin:0;}
.orderTblWrap .asideBarWrap .productTbl table tr td ul li h4{color:#000000;font-size:1.3rem;font-weight:500;margin:0;}
.orderTblWrap .asideBarWrap .productTbl table tr td ul li h3{color:#000000;font-size:1.3rem;font-weight:400;margin:0;}
.orderTblWrap .asideBarWrap .productTbl table tr td ul li:last-child{border-top:0.5px solid #0000004D;padding:4px 0 0;margin:4px 0 0;}
.orderTblWrap .asideBarWrap .productTbl table tr td ul li:last-child p{color:#000000;font-size:1.3rem;font-weight:400;}
.orderTblWrap .asideBarWrap .productTbl table tr td ul li:last-child h3{font-size:1.3rem;font-weight:500;}
.orderTblWrap .asideBarWrap .accordion{margin:16px 0 0;}
.orderTblWrap .asideBarWrap .accordion .accordion-item{border-radius:6px;}
.orderTblWrap .asideBarWrap h2.accordion-header{border-bottom:1px solid #dee2e6;}
.orderTblWrap .asideBarWrap h2.accordion-header .accordion-button{color:#000000;font-size:1.5rem;font-weight:500;outline:0;background-color:transparent;box-shadow:none;}
.orderTblWrap .asideBarWrap .accordion-body{padding:10px;}
.orderTblWrap .asideBarWrap .accordion-body ul{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.orderTblWrap .asideBarWrap .accordion-body ul li{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;width:50%;margin:0 0 4px;}
.orderTblWrap .asideBarWrap .accordion-body ul li.full{width:100%;}
.orderTblWrap .asideBarWrap .accordion-body ul li p{margin:0 1px 0 0;color:rgba(0,0,0,0.40);font-size:1.2rem;}
.orderTblWrap .asideBarWrap .accordion-body ul li h6{margin:0;color:#000000;font-size:1.2rem;font-weight:400;}
.orderTblWrap .asideBarWrap .accordion-body ul li h6.clrPending{color:#E09320;}
.orderTblWrap .asideBarWrap .accordion-body ul li h6.clrCancled{color:#E22929;}
.orderTblWrap .asideBarWrap .accordion-body ul li h6.Delivered{color:#16A124;}
.orderTblWrap .asideBarWrap .ctaWrap{text-align:right;margin:12px 0 0;}
.orderTblWrap .asideBarWrap .ctaWrap .btn{background-color:#5F2DF0;box-shadow:0px 4px 4px 0px #00000033;border-radius:30px;border:0;outline:0;color:#ffffff;font-size:1.2rem;font-weight:500;padding:8px 26px;}
/*-------Order-------*/

/*-------Chat-------*/
.chatWrap{padding:0;display:flex;justify-content:space-between;flex-flow:column;}
.chatPannelWrap{width:100%;background-color:#ffffff;height:100%;border-radius:8px;}
.chatApplication{display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-between;}
.chatApplication .chatSidebar{background-color:#ffffff;box-shadow:5px 0px 11.1px 0px #00000033;border-radius:8px;width:340px;}
.chatApplication .chatSidebar .chatSidebarContent .chatSearch{position:fixed;width:340px;background-color:#ffffff;border-radius:8px 8px 0 0;}
.chatApplication .chatSidebar .chatSidebarContent .chatSearch .inputField{height:52px;border-bottom:1px solid #D0D0D0;display:flex;align-items:center;justify-content:flex-start;padding:5px 16px;}
.chatApplication .chatSidebar .chatSidebarContent .chatSearch .inputField svg{margin:0 16px 0 0;width:16px;height:16px;}
.chatApplication .chatSidebar .chatSidebarContent .chatSearch .inputField input{border:0;outline:0;box-shadow:none;height:100%;background-color:transparent;color:#000000;font-weight:500;font-size:1.4rem;}
.chatApplication .chatSidebar .chatSidebarContent .chatSearch .inputField input::placeholder{color:#ADB8CC;font-weight:400;}
.chatApplication .chatSidebar .listGroup{padding:52px 0 0;}
.chatApplication .chatSidebar .listGroup ul{height:calc(100vh - 142px);overflow-y:auto;}
.chatApplication .chatSidebar .listGroup ul li{padding:12px 10px;border-bottom:1px solid rgba(208, 208, 208, 0.50);}
.chatApplication .chatSidebar .listGroup ul li .itemWrap a{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.chatApplication .chatSidebar .listGroup ul li .itemWrap .imgWrap{width:46px;height:46px;border-radius:100%;position:relative;}
.chatApplication .chatSidebar .listGroup ul li .itemWrap .imgWrap img{width:100%;height:100%;object-fit:cover;border-radius:100%;}
.chatApplication .chatSidebar .listGroup ul li .itemWrap .imgWrap .statusBadge{position:absolute;display:inline-block;background-color:#29CC39;border:2px solid #ffffff;width:12px;height:12px;border-radius:100%;top:0px;right:0px;}
.chatApplication .chatSidebar .listGroup ul li .itemWrap .dataWrap{width:calc(100% - 62px);}
.chatApplication .chatSidebar .listGroup ul li .itemWrap .dataWrap .topTxt{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;margin:0 0 4px;}
.chatApplication .chatSidebar .listGroup ul li .itemWrap .dataWrap .topTxt h2{color:#000000;margin:0;font-size:1.2rem;font-weight:600;}
.chatApplication .chatSidebar .listGroup ul li .itemWrap .dataWrap .topTxt span{color:#ADB8CC;font-size:1.2rem;font-weight:700;line-height:14px;} 
.chatApplication .chatSidebar .listGroup ul li .itemWrap .dataWrap p{color:#ADB8CC;font-size:1.2rem;font-weight:400;margin:0;max-width:83%;line-height:18px;}
.chatApplication .chatContent{width:calc(100% - 340px);}
.chatApplication .chatContent .topChatContent{position:relative;display:flex;align-items:center;justify-content:center;height:52px;border-bottom:1px solid rgba(208, 208, 208, 0.50);}
.chatApplication .chatContent .topChatContent .chatName{position:relative;display:flex;align-items:center;justify-content:center;width:100%;}
.chatApplication .chatContent .topChatContent .chatName span.statusBadge{display:inline-block;background-color:#29CC39;border:1px solid #ffffff;width:10px;height:10px;border-radius:100%;margin:0 10px 0 0;}
.chatApplication .chatContent .topChatContent .chatName h2{color:#000000;margin:0;font-size:1.6rem;font-weight:700;}
.chatApplication .chatContent .topChatContent .chatSetting{position:absolute;right:10px;top:50%;transform:translateY(-50%);}
.chatApplication .chatContent .topChatContent .chatSetting .btn{padding:0;outline:0;box-shadow:none;border:0;border-radius:0;}
.chatFormWrap{padding:10px;}
.chatFormWrap form{background-color:#F8F8F8;border-radius:12px;height:52px;padding:10px;box-shadow:0px 3px 4.5px 0px #00000024;} 
.chatFormWrap form input,
.chatFormWrap form input:focus{border:0;outline:0;background-color:transparent;height:100%;box-shadow:none;color:#231F20;font-size:1.4rem;    width:calc(100% - 148px);} 
.chatFormWrap form input::placeholder{color:#B2BBCE;font-size:1.2rem;}
.chatFormWrap form button{padding:0;border-radius:0;outline:0;box-shadow:none;} 
.chatFormWrap form button svg{fill:rgb(56 0 250 / 100%);}  
.chatFormWrap .imgWrap{width:32px;}
.chatFormWrap .ctasWrap{display:flex;align-items:center;justify-content:center;flex-flow:row wrap;}
.chatFormWrap .ctasWrap .btn{padding:0;outline:0;border-radius:0;border:0;box-shadow:none;margin:0 4px;}
.chatFormWrap .ctasWrap .btn img{width:24px;}
.chatFormWrap .ctasWrap .btn svg{width:18px;height:18px;}
.chatWrap .chatAppWindow{height:calc(100vh - 216px);padding:20px 22px;}
.chatAppWindow .chat{margin:0 0 20px;display:flex;flex-flow:column;align-items:flex-end;justify-content:flex-end;float:right;width:100%;}
.chatAppWindow .chat .chatTimeWrap{text-align:center;width:100%;padding:0 0 10px;}
.chatAppWindow .chat .chatTimeWrap p{text-align:center;margin:0 auto;color:#ADB8CC;font-size:1.2rem;}
.chatAppWindow .chat .chat-body{display:flex;flex-flow:column;align-items:flex-end;margin:10px 10px 0 0;max-width:60%;}
.chatAppWindow .chat.chat-left{display:flex;flex-flow:column;justify-content:flex-start;align-items:flex-start;float:left;}
.chatAppWindow .chat.chat-left .chat-body{margin:0;}
.chatAppWindow .chat.chat-left .chat-body,
.chatAppWindow .chat.chat-left{align-items:flex-start;}
.chatAppWindow .chat .chat-content{background-color:#EEEAFF;border-radius:6px;margin:8px 0 0;padding:10px 16px;align-self:flex-end;position:relative;}
.chatAppWindow .chat .chat-content:after{content:"";background-image:url(../img/rightChat.png);background-repeat:no-repeat;width:20px;height:20px;background-size:contain;position:absolute;right:-16px;top:0;}
.chatAppWindow .chat.chat-left .chat-content{border-radius:6px;background-color:#EEEAFF;align-self:flex-start;margin:8px 0 0;position:relative;}
.chatAppWindow .chat.chat-left .chat-content:after{content:"";background-image:url(../img/leftChat.png);background-repeat:no-repeat;width:36px;height:42px;background-size:contain;position:absolute;top:0px;left:-32px;}
.chatAppWindow .chat .chat-content p{color:#231F20;margin:0;font-weight:500;line-height:18px;font-size:1.3rem;}
.chatAppWindow .chat .chat-content span{color:rgba(38, 50, 56, 0.4);font-size:1.3rem;}
.avatar.avatar-md img{width:40px;height:40px;border-radius:100%;}

.chatWrap .rightPanelWrap{height:calc(100vh - 63px);background:linear-gradient(180deg, #D5C9F7 0%, #7A56FF 100%);box-shadow:-10px 0px 17.1px 0px #00000033;width:320px;margin:0;border-radius:16px 0 0 16px;padding:16px;transition:.3s;position:fixed;right:-350px;top:63px;display:flex;flex-flow:column;justify-content:space-between;}
.chatWrap .rightPanelWrap.showRightSidebar{right:0;}
.chatWrap .rightPanelWrap .closeBtn{padding:0;outline:0;border:0;position:absolute;top:50%;transform:translateY(-50%);left:-30px;margin:0;width:30px;height:36px;background-color:#D3DAEF;box-shadow:0px 4px 4px 0px #00000033!important;border-radius:8px 0 0 8px;}
.chatWrap .rightPanelWrap .topPannel .itemWrap{box-shadow:0px 4px 4px 0px #00000026;background-color:#ffffff;border-radius:10px;margin:0 auto 16px;}
.chatWrap .rightPanelWrap .topPannel .itemWrap .topTTlWrp{background-color:#EDEDED;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;border-radius:10px;padding:8px 12px;min-height:38px;}
.chatWrap .rightPanelWrap .topPannel .itemWrap .topTTlWrp .imgWrap{height:42px;width:42px;border-radius:100%;}
.chatWrap .rightPanelWrap .topPannel .itemWrap .topTTlWrp .imgWrap img{width:100%;height:100%;object-fit:cover;border-radius:100%;}
.chatWrap .rightPanelWrap .topPannel .itemWrap .topTTlWrp h2{margin:0 auto;text-align:center;font-size:1.4rem;font-weight:600;color:#000000;}
.chatWrap .rightPanelWrap .topPannel .itemWrap .dataWrap{padding:10px;}
.chatWrap .rightPanelWrap .topPannel .itemWrap .dataWrap textarea{resize:none;border:0;outline:0;width:100%;height:100%;font-size:1.4rem;color:#000000;font-weight:400;}
.chatWrap .rightPanelWrap .topPannel .itemWrap .dataWrap textarea::placeholder{color:#AAAAAA;font-size:1.2rem;font-weight:500;}
.chatWrap .rightPanelWrap .topPannel .conditionsWrap .ttlWrap{box-shadow:0px 4px 4px 0px #00000026;background-color:#ffffff;border-radius:10px;    padding:14px 8px;margin:0 auto 12px;}
.chatWrap .rightPanelWrap .topPannel .conditionsWrap .ttlWrap h2{margin:0 auto;text-align:center;font-size:1.4rem;font-weight:600;color:#000000;}
.chatWrap .rightPanelWrap .topPannel .conditionsWrap ul{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.chatWrap .rightPanelWrap .topPannel .conditionsWrap ul li{margin:0 6px 6px 0;}
.chatWrap .rightPanelWrap .topPannel .conditionsWrap ul li span{background-color:#EEE9E9;display:inline-block;border:1px solid #BEBEBE;box-shadow:0px 4px 4px 0px #00000026;border-radius:3px;color:#767676;font-size:1.2rem;font-weight:400;padding:2px 8px;min-width:90px;text-align:center;}
.chatWrap .rightPanelWrap .bottmPannle{background-color:#ffffff;border-radius:10px;padding:10px;position:relative;min-height:70px;}
.chatWrap .rightPanelWrap .bottmPannle:after{content:"";background-image:url(../img/ChatBtm.png);background-repeat:no-repeat;background-size:contain;width:20px;height:20px;position:absolute;right:-17px;bottom:-3.3px;}
.chatWrap .rightPanelWrap .bottmPannle .conversBoxWrap{background-color:#F2EEFE;border:1px solid #B3A5DC;border-radius:5px;padding:8px;}
.chatWrap .rightPanelWrap .bottmPannle .conversBoxWrap h2{color:#8564FE;margin:0 auto;font-size:1.4rem;font-weight:500;text-align:center;}
/*-------Chat-------*/



.leftCardWrap ul.nodesList{background-color:#FFFFFF;margin:0;border-radius:6px;box-shadow:0px 4px 4px 4px rgba(0,0,0,0.1);}
.leftCardWrap ul.nodesList li{padding:0 4px;}
.leftCardWrap ul.nodesList li:last-child{border-bottom:none;}
.leftCardWrap ul.nodesList li a{text-align:center;padding:8px 0;display:inline-block;width:100%;border-bottom:1px solid #EDEDED;}
.leftCardWrap ul.nodesList li a .iconWrap{width:16px;display:flex;align-items:center;justify-content:center;margin:0 auto;}
.leftCardWrap ul.nodesList li a .iconWrap img{opacity:0.5;}
.leftCardWrap ul.nodesList li a .dataWrap{font-size:1.1rem;color:#585858;font-weight:400;line-height:16px;letter-spacing:0.1px;margin:5px 0 0;}

.rightCardWrap{position:relative;}
.rightCardWrap ul.toolsList{
  /* background-image:linear-gradient(#D5C9F7 , #7A56FF); */
  box-shadow:0px 6px 8.6px 0px #00000033;width:calc(100% + 20px);padding:20px;border-radius:20px 0 0 20px;height:calc(100vh - 68px);background-color:#ffffff;}
/* .rightCardWrap ul.toolsList li{padding:1rem 0;} */

.inputCardWrap{margin:0;}
.inputCardWrap h3{text-align:left;font-size:1.6rem;font-weight:600;padding:0;margin:0;}
.inputCardWrap h3.interListTtl{margin:0 0 16px;}
.inputCardWrap h3.midTtl{margin:14px 0 6px;font-size:1.2rem;font-weight:500;}
.inputCardWrap p{text-align:left;font-size:1rem;color:#E22929;font-weight:600;margin:0;}
.inputCardWrap .inputCardHeader{background-color:#ffffff;border-radius:0;margin:0 0 16px;}
.inputCardWrap .inputCardHeader h3{font-size:1.6rem;padding:0;text-align:left;font-weight:600;}
.inputCardWrap .inputField input{width:100%;border:0;padding:3px;height:80px;border-radius:6px;}
.inputCardWrap .textField textarea{width:100%;height:56px;border:0;padding:3px 8px;border:1px solid #e3e3e3;border-radius:6px;font-size:1.3rem;color:#000000;}
.triggerBtn.overFlowForm{overflow-y:auto;height:calc(100vh - 200px);}
.triggerBtn ul h4{font-size:1.3rem;margin: 12px 0 4px 0;font-weight:500;}
.triggerBtn ul.btnList{display:flex;align-items:center;justify-content:center;}
.triggerBtn ul.btnList li{width:33.33%;margin:0 0 0 2.7rem;}
.triggerBtn ul.btnList li button{background-color:#EEE9E9;font-size:1.5rem;color:#767676 ;border:1px solid #BEBEBE;box-shadow:0px 4px 4px 4px rgba(0, 0, 0, 0.1);padding:0.5rem 1.6rem;}
.startNodeWrap .startCard{background-color:#1944F1;padding:16px 10px;border-radius:30px;width:160px;display:flex;align-items:center;justify-content:center;box-shadow:0px 4px 4px 0px #00000026;}
.startNodeWrap .startCard .labelWrap h4{margin:0;color:#FFFFFF;font-weight:400;}
.startNodeWrap .startCard .labelWrap{position:relative;}
.startNodeWrap .startCard .labelWrap:before{content:"";background-image:url(../img/startBtnIco.png);background-repeat:no-repeat;   background-size:contain;width:26px;height:32px;position:absolute;left:-30px;top:-1px}
.startNodeWrap .startCard .labelWrap:after{content:"";background-image:url(../img/startBtnRightIco.png);background-repeat:no-repeat;background-size:contain;width:48px;height:48px;position:absolute;right:-50px;top:65%;transform:translateY(-50%);}
.conditionNodeWrap .conditionCard{background-color:#A6CAF3;border-radius:8px;width:230px;box-shadow:0px 4px 4px 4px #00000026;}
.conditionNodeWrap .conditionCard .headerWrap,
.triggerNodeWrap .triggerCard .headerWrap,
.gotoNodeWrap .gotoCard .headerWrap,
.waitNodeWrap .waitCard .headerWrap,
.exitNodeWrap .exitCard .headerWrap,
.messageNodeWrap .messageCard .headerWrap{display:flex;align-items:center;justify-content:flex-start;padding:0.4rem;margin:0 0 0.8rem;}
.conditionNodeWrap .conditionCard .headerWrap .titleWrap h4,
.triggerNodeWrap .triggerCard .headerWrap .titleWrap h4,
.gotoNodeWrap .gotoCard .headerWrap .titleWrap h4,
.waitNodeWrap .waitCard .headerWrap .titleWrap h4,
.exitNodeWrap .exitCard .headerWrap .titleWrap h4,
.messageNodeWrap .messageCard .headerWrap .titleWrap h4{margin:0 0 0 1.2rem;color:#FFFFFF;font-weight:600;}
.conditionNodeWrap .conditionCard .bodyWrap .inputWrap,
.triggerNodeWrap .triggerCard .bodyWrap,
.gotoNodeWrap .gotoCard .bodyWrap .inputWrap,
.waitNodeWrap .waitCard .bodyWrap .inputWrap,
.exitNodeWrap .exitCard .bodyWrap .inputWrap,
.messageNodeWrap .messageCard .bodyWrap{padding:0 10px;}
.conditionNodeWrap .conditionCard .bodyWrap input,
.gotoNodeWrap .gotoCard .bodyWrap input,
.waitNodeWrap .waitCard .bodyWrap input,
.triggerNodeWrap .triggerCard .bodyWrap textarea,
.exitNodeWrap .exitCard .bodyWrap input,
.messageNodeWrap .messageCard .bodyWrap textarea{border:none;border-radius:10px;width:100%;margin:0 0 1.2rem ;padding:0 0.5rem;resize:none;}
.triggerNodeWrap .triggerCard .bodyWrap textarea::placeholder{color:#B5B5B5;font-size:1.2rem;text-transform:capitalize;}
.exitNodeWrap .exitCard .bodyWrap input::placeholder{color:#B5B5B5;font-size:1.2rem;text-transform:capitalize;}
.messageNodeWrap .messageCard .bodyWrap textarea::placeholder{color:#B5B5B5;font-size:1.2rem;text-transform:capitalize;}
.conditionNodeWrap .conditionCard .bodyWrap .inputWrap .addMoreBtn,
.gotoNodeWrap .gotoCard .bodyWrap .inputWrap .addMoreBtn,
.waitNodeWrap .waitCard .bodyWrap .inputWrap .addMoreBtn,
.exitNodeWrap .exitCard .bodyWrap .inputWrap .addMoreBtn{display:flex;align-items:center;justify-content:flex-end;}
.conditionNodeWrap .conditionCard .bodyWrap .inputWrap .addMoreBtn .iconWrap{background-color:#7CA7D9;margin:0 0 1.3rem;border-radius:100%;}
.triggerNodeWrap .triggerCard{background-color:#DAE0FB;border-radius:10px;width:230px;box-shadow:0px 4px 4px 4px #00000026;}
.gotoNodeWrap .gotoCard{background-color:#DAE0FB;border-radius:10px;width:230px;box-shadow:0px 4px 4px 4px #00000026;}
.gotoNodeWrap .gotoCard .bodyWrap .inputWrap .addMoreBtn .iconWrap{background-color:#F8F1FC;margin:0 0 1.3rem;border-radius:100%;}
.waitNodeWrap .waitCard{background-color:#DAE0FB;border-radius:10px;width:230px;box-shadow:0px 4px 4px 4px #00000026;}
.waitNodeWrap .waitCard .bodyWrap .inputWrap .addMoreBtn .iconWrap{background-color:#DAE0FB;margin:0 0 1.3rem;border-radius:100%;}
.exitNodeWrap .exitCard{background-color:#DAE0FB;border-radius:10px;width:230px;box-shadow:0px 4px 4px 4px #00000026;}
.exitNodeWrap .exitCard .bodyWrap .inputWrap .addMoreBtn .iconWrap{background-color:#DAFAE1;margin:0 0 1.3rem;border-radius:100%;}
.messageNodeWrap .messageCard{background-color:#DAE0FB;border-radius:10px;width:230px;box-shadow:0px 4px 4px 4px #00000026;}

.conditionNodeWrap .conditionCard .bodyWrap .inputWrap .addMoreBtn .btn, 
.gotoNodeWrap .gotoCard .bodyWrap .inputWrap .addMoreBtn .btn, 
.waitNodeWrap .waitCard .bodyWrap .inputWrap .addMoreBtn .btn, 
.exitNodeWrap .exitCard .bodyWrap .inputWrap .addMoreBtn .btn{padding:0;width:24px;height:24px;display:flex;align-items:center;justify-content:center;outline:0;border:0;box-shadow:none;}

.conditionNodeWrap .conditionCard .headerWrap .iconWrap,
.triggerNodeWrap .triggerCard .headerWrap .iconWrap,
.gotoNodeWrap .gotoCard .headerWrap .iconWrap,
.waitNodeWrap .waitCard .headerWrap .iconWrap,
.exitNodeWrap .exitCard .headerWrap .iconWrap,
.messageNodeWrap .messageCard .headerWrap .iconWrap,
.triggerNodeWrap .triggerCard .headerWrap .iconWrap,
.buttonNodeWrap .buttonCard .headerWrap .iconWrap,
.menuNodeWrap .menuCard .headerWrap .iconWrap{width:24px;}
.conditionNodeWrap .conditionCard .headerWrap .iconWrap img,
.triggerNodeWrap .triggerCard .headerWrap .iconWrap img,
.gotoNodeWrap .gotoCard .headerWrap .iconWrap img,
.waitNodeWrap .waitCard .headerWrap .iconWrap img,
.exitNodeWrap .exitCard .headerWrap .iconWrap img,
.messageNodeWrap .messageCard .headerWrap .iconWrap img,
.triggerNodeWrap .triggerCard .headerWrap .iconWrap img,
.buttonNodeWrap .buttonCard .headerWrap .iconWrap img,
.menuNodeWrap .menuCard .headerWrap .iconWrap img{width:18px;}

.buttonNodeWrap .buttonCard,
.triggerNodeWrap .triggerCard,
.menuNodeWrap .menuCard,
.exitNodeWrap .exitCard,
.messageNodeWrap .messageCard{padding:0 0 10px;}
.exitNodeWrap .exitCard .bodyWrap,
.buttonNodeWrap .buttonCard h4,
.triggerNodeWrap .triggerCard .bodyWrap .dataWrap h4{font-size:1.1rem;margin:0;font-weight:400;line-height:1.8rem;letter-spacing:0.2px;}


.buttonNodeWrap .buttonCard,
.menuNodeWrap .menuCard{
  background-color: #DAE0FB;
  border-radius:6px;
  width: 230px;
  box-shadow: 0px 4px 4px 4px #00000026;
}

.pannelBtn{
  background-color: #1944F1;
  padding: 6px 10px;
  font-size: 1.2rem;
  color:#FFFFFF;
  font-weight: 500;
  border-radius: 6px;
}

.conditionNodeWrap .conditionCard .bodyWrap,
.triggerNodeWrap .triggerCard .bodyWrap,
.gotoNodeWrap .gotoCard .bodyWrap,
.waitNodeWrap .waitCard .bodyWrap,
.buttonNodeWrap .buttonCard .bodyWrap,
.messageNodeWrap .messageCard .bodyWrap,
.menuNodeWrap .menuCard .bodyWrap,
.exitNodeWrap .exitCard .bodyWrap
{
  padding: 0.5rem 0.5rem;
  background-color: #FFFFFF;
  border-radius: 6px;
  margin: 0 1.3rem;
  min-height: 30px;
}

.conditionNodeWrap .conditionCard .headerWrap .titleWrap h4,
.triggerNodeWrap .triggerCard .headerWrap .titleWrap h4,
.gotoNodeWrap .gotoCard .headerWrap .titleWrap h4,
.waitNodeWrap .waitCard .headerWrap .titleWrap h4,
.exitNodeWrap .exitCard .headerWrap .titleWrap h4,
.messageNodeWrap .messageCard .headerWrap .titleWrap h4,
.menuNodeWrap .menuCard .headerWrap .titleWrap h4,
.buttonNodeWrap .buttonCard .headerWrap .titleWrap h4{
  margin:0;
  color: #000000;
  font-weight: 600;
  font-size:1.2rem;
}
p:empty{display: none !important;;}
.conditionNodeWrap .conditionCard .headerWrap,
.triggerNodeWrap .triggerCard .headerWrap,
.gotoNodeWrap .gotoCard .headerWrap,
.waitNodeWrap .waitCard .headerWrap,
.exitNodeWrap .exitCard .headerWrap,
.messageNodeWrap .messageCard .headerWrap,
.menuNodeWrap .menuCard .headerWrap,
.buttonNodeWrap .buttonCard .headerWrap{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding:4px 15px;
  margin:0;
  position: relative;
}

.headerWrap .btnWrap{
position: absolute;
right: 0;
}

.rightCardWrap .rightBtnWrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin:0;position:absolute;bottom:0;background-color:#ffffff;padding:10px 12px;width:calc(100% + 20px);border-radius:0 0 0 30px;
}

.rightCardWrap .rightBtnWrap button{
  background-color:#ebefff;width:95%;
    padding: 6px 2px;
    font-size: 1.2rem;
    color: #1944F1;
    font-weight: 500;
    border-radius: 6px;
    margin:0 4px;
    border:1px solid rgb(26 68 241 / 47%);
}
.inputCardWrap .triggerBtn{position:relative;}
.inputCardWrap .triggerBtn ul li{
  display:flex;
  align-items: center;
  margin:0 0 10px;
}
.inputCardWrap .triggerBtn ul li.flexColumn{flex-flow:column;}
.inputCardWrap .triggerBtn ul li.flexColumn .leftWrap{width:100%;}
.inputCardWrap .triggerBtn ul li.flexColumn .rightWrap{margin:0;width:100%;}

.inputCardWrap .triggerBtn ul li .leftWrap{
  width:100px;
  margin:0;
}
.inputCardWrap .triggerBtn ul li .leftWrap label{font-size:1.2rem;color:#575757;}
.inputCardWrap .triggerBtn ul li .rightWrap{
  width:100%;
  margin:0;
}
.inputCardWrap .triggerBtn ul li .rightWrap.textareaHight{height:56px;}
.inputCardWrap .triggerBtn ul li.imgWrap .leftWrap{width:auto;margin:0 12px 0 0;}
.inputCardWrap .triggerBtn ul li.imgWrap .rightWrap{height:auto;}
.inputCardWrap .triggerBtn ul li .rightWrap textarea{
  width: 100%;
  border:1px solid #e3e3e3;
  border-radius:6px;
  margin:0;
  padding:3px 8px;
  resize:none;
  background-color: #ffffff;
  color:#000000;font-size:1.2rem;height:56px;
}
.inputCardWrap .triggerBtn ul li .rightWrap textarea::placeholder{color:#999999;font-size:1.1rem;}
.inputCardWrap .triggerBtn ul li .rightWrap input{
  width: 100%;
  border:1px solid #e3e3e3;
  border-radius:6px;
  margin:0;
  padding:3px 8px;
  background-color: #ffffff;
  color:#000000;font-size:1.2rem;
}
.inputCardWrap .triggerBtn ul li .rightWrap input::placeholder{color:#999999;font-size:1.1rem;}
.inputCardWrap .triggerBtn ul li .rightWrap input[type='checkbox']{width:auto;}

.rightCardWrap .topTitleWrap{
  display: flex;
  position: relative;
}
.rightCardWrap .topTitleWrap h4{
  margin: 0 0 0 15px;
}
.rightCardWrap .topTitleWrap a.rmvIcon{
  position: absolute;
    right:-5px;
    bottom:0;
}
.rightCardWrap .topTitleWrap a.rmvIcon svg{width:14px;height:14px;}
.rightCardWrap .topTitleWrap a.rmvIcon svg path:nth-child(1){fill:#bdbdbd;}
.rightCardWrap .topTitleWrap a.rmvIcon:hover svg path:nth-child(1){fill:#f44336;}
.rightCardWrap .addIconWrap{display:flex;align-items:center;justify-content:center;margin:0;position:absolute;bottom:-46px;right:0;width:100%;border:1px solid #e3e3e3;border-radius:6px;padding:3px 8px;background-color:#fafafa;}
.rightCardWrap .addIconWrap a.addBtn{font-size:1.2rem;text-transform:capitalize;color:#aaaaaa;}
.rightCardWrap .addIconWrap svg{width:14px;height:14px;margin:0 0 0 10px;}
  .rightCardWrap .addIconWrap svg path:nth-child(1){fill:#bdbdbd;}
  .rightCardWrap .addIconWrap:hover{background-color:#ebefff;border:1px solid rgb(26 68 241 / 47%);}
  .rightCardWrap .addIconWrap:hover a.addBtn{color:#1944F1;}
  .rightCardWrap .addIconWrap:hover a.addBtn svg path:nth-child(1){fill:#1944F1;}
.inputCardWrap .triggerBtn .css-b62m3t-container{
  width: 100%;
  margin: 0;
}
.buttonNodeWrap .buttonCard .bodyWrap .buttonWrap,
.menuNodeWrap .menuCard .bodyWrap .buttonWrap{
display: flex;
justify-content: center;
}
.buttonNodeWrap .buttonCard .bodyWrap .buttonWrap button,
.menuNodeWrap .menuCard .bodyWrap .buttonWrap button{
  background-color: rgba(95, 45, 240, 0.10);
  border: 0;
  padding: 4px 8px;
  font-size:0.9rem;
  font-weight: 500;
  color: #5F2DF0;
  border: 1px solid rgba(95, 45, 240, 0.20);
  /* width: 100px; */
}
.menuNodeWrap .menuCard .bodyWrap p{
  font-size: 1.2rem;
  
}
.mainFlowWrap{width:100%;height:calc(100vh - 68px);background-color:#ffffff;}
.is-invalid~.invalid-feedback, 
.is-invalid~.invalid-tooltip, 
.was-validated :invalid~.invalid-feedback, 
.was-validated :invalid~.invalid-tooltip{display:inline-block;}
.invalid-feedback,
.asideBarWrap .btmDataWrap ul li.inputFields .error{width:auto;position:absolute;bottom:-8px;background-color:#ffffff;padding:2px 6px;right:8px;font-size:1rem;font-weight:500;margin:0;color:#dc3545;}
.asideBarWrap .btmDataWrap.productBtm{overflow-y:auto;height:calc(100vh - 310px);}
.rcs-custom-scroll .kpmZQS{background:linear-gradient(180deg, #a3b6ff 0%, #b5c4ff 100%);}
.asideBarWrap .btmDataWrap ul li.checkBoxWrap{justify-content:flex-start;}
.asideBarWrap .btmDataWrap ul li.checkBoxWrap label{display:inline-block;margin:0 0 0 6px;font-size:1.4rem;color:#797979;}
.orderAsidebar.asideBarWrap{height:calc(100vh - 128px);}
.orderTblWrap .orderAsidebar.asideBarWrap .ctaWrap{padding:0 0 10px;}

.messageNodeWrap .react-flow__handle,
.menuNodeWrap .react-flow__handle,
.exitNodeWrap .react-flow__handle,
.triggerNodeWrap .react-flow__handle,
.buttonNodeWrap .react-flow__handle{background-image:linear-gradient(180deg, #DAE0FB 0%, #7E88BC 100%);border:0;width:8px;height:8px;}
.messageNodeWrap .react-flow__handle:before,
.menuNodeWrap .react-flow__handle:before,
.exitNodeWrap .react-flow__handle:before,
.buttonNodeWrap .react-flow__handle:before,
.triggerNodeWrap .react-flow__handle:before{content:"";position:absolute;background-color:#9AA5DE;width:6px;height:6px;border-radius:100%;left:50%;top:50%;transform:translate(-50%, -50%);}

.buttonNodeWrap .buttonCard .buttonWrap, 
.menuNodeWrap .menuCard .buttonWrap{text-align:center;}
.btnListNode .btn,
.btnInteract .btn{margin:6px auto 0;background-color:#d8dfff;border:1px solid #7A94F2;color:#7A94F2;font-size:0.9rem;font-weight:500;letter-spacing:0.2px;padding:3px 6px;}
.btnListNode .btn:hover,
.btnInteract .btn:hover{background-color:#9AA5DE;color:#000000;}

.menuNodeWrap .menuCard .bodyWrap h4,
.messageNodeWrap .messageCard .bodyWrap h4{font-size:1.2rem;color:#222222;margin:0;}
.menuNodeWrap .menuCard .bodyWrap h5,
.messageNodeWrap .messageCard .bodyWrap h5{font-size:1.1rem;margin:6px 0;font-weight:400;line-height:16px;letter-spacing:0.2px;}
.menuNodeWrap .menuCard .bodyWrap p,
.messageNodeWrap .messageCard .bodyWrap p{font-size:1.1rem;color:#666666;margin:0;line-height:14px;}
.css-1fdsijx-ValueContainer{font-size:1.2rem;}

.workFlowChartWrap .leftCardWrap{width:74px;margin:0 12px 0 0;}
.productListing .tblWrap{overflow-x:auto;white-space:nowrap;padding:12px;background-color:#ffffff;border-radius:8px;}
.productListing .tblWrap table thead{box-shadow:0px 3px 7.5px 0px #00000033;position:relative;border-radius:6px;}
.productListing .tblWrap table thead tr{border:0;}
.productListing .tblWrap table thead tr th{border:0;color:#636363;text-align:left;font-weight:500;font-size:1.2rem;letter-spacing:0.2px;padding:16px 8px;line-height:14px;border-radius:6px;}
.productListing .tblWrap table thead tr th:last-child,
.productListing .tblWrap table tbody tr td:last-child{text-align:center;}
.productListing .tblWrap table tbody tr td{border:0;color:#000000;font-size:1.2rem;font-weight:500;letter-spacing:0.2px;padding:6px 8px;border-bottom:0.5px solid #0000001f;text-align:left;vertical-align:middle;}
.productListing .tblWrap table tbody tr:nth-child(1) td{padding:20px 8px 6px;}
.productListing .tblWrap table tbody tr td .imgWrap{width:32px;height:32px;}
.productListing .tblWrap table tbody tr td .imgWrap img{width:100%;height:100%;object-fit:cover;}
.productListing .tblWrap table tbody tr td .imgWrap .img-placeholder{width:32px;height:32px;background-color:#ececec;border:1px solid #dddddd;}
.productListing .tblWrap table tbody tr td.titleTxt,
.productListing .tblWrap table tbody tr td.descripTxt{text-align:left;}
.productListing .tblWrap table tbody tr td.titleTxt p,
.productListing .tblWrap table tbody tr td.descripTxt p{display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;
white-space:normal;overflow:hidden;text-overflow:ellipsis;width:100%;max-width:200px;color:#000000;font-size:1.2rem;font-weight:500;letter-spacing:0.2px;margin:0;line-height:18px;text-align:left;}

.productListing .tblWrap table tbody tr td .btnEdit{background-color:transparent;padding:0;outline:0;box-shadow:none;border:0;}
.productListing .tblWrap table tbody tr td .btnEdit:hover svg path{fill:#1944F1;}
.productListing .tblWrap table tbody tr:last-child td{border:0;}

.custom-checkbox{position:absolute;opacity:0;}
.custom-checkbox + label{position:relative;padding-left:0;cursor:pointer;display:inline-block;line-height:16px;}
.custom-checkbox + label::before{content:'';position:absolute;left:0;top:-14px;width:20px;height:20px;border:1px solid #e3e3e3;background-color:#ffffff;border-radius:4px;}
.custom-checkbox:checked + label::before{background-color:#1a43ef;border:1px solid #1a43ef;}
.custom-checkbox:checked + label::after{content:'\2714';font-size:1.2rem;color:#ffffff;position:absolute;top:-12px;left:5px;}
.custom-checkbox + label:hover::before{background-color:#1a43ef;border:1px solid #1a43ef;}

.triggerNodeWrap .triggerCard .headerWrap .editNode .btn,
.buttonNodeWrap .buttonCard .headerWrap .editNode .btn,
.menuNodeWrap .menuCard .headerWrap .editNode .btn,
.exitNodeWrap .exitCard .headerWrap .editNode .btn, 
.messageNodeWrap .messageCard .headerWrap .editNode .btn{display:none;padding:0;outline:0;box-shadow:none;position:absolute;right:6px;top:6px;}
.triggerNodeWrap .triggerCard:hover .headerWrap .editNode .btn,
.buttonNodeWrap .buttonCard:hover .headerWrap .editNode .btn,
.menuNodeWrap .menuCard:hover .headerWrap .editNode .btn,
.exitNodeWrap .exitCard:hover .headerWrap .editNode .btn, 
.messageNodeWrap .messageCard:hover .headerWrap .editNode .btn{width:18px;height:18px;border-radius:100%;background-color:#ebefff;border:1px solid rgb(122 147 250);display:flex;align-items:center;justify-content:center;}

/* .content.contactListPage{padding:60px 10px 0 90px;} */
.content.contactListPage{padding:60px 10px 0 10px;}
.contactListWrap{width:100%;border-radius:10px;background-color:#ffffff;padding:10px 0 16px;height:calc(100vh - 70px);position:relative;}
.contactListWrap .topDataWrap{padding:0 10px 12px;}
.contactListWrap .topDataWrap .titleWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;}
.contactListWrap .topDataWrap .titleWrap .icoWrap{margin:0 1rem 0 0;}
.contactListWrap .topDataWrap .titleWrap .icoWrap svg{width:3.2rem;height:3.2rem;}
.contactListWrap .topDataWrap .titleWrap h2{color:#000000;margin:0;font-size:2rem;font-weight:600;}
.contactListWrap .topDataWrap .fileUploadContainer{display:flex;align-items:flex-end;}
.contactListWrap .topDataWrap .fileUploadContainer .fileUploadBox{border:1px dashed #5674EE;border-radius:5px;padding:10px 24px;text-align:center;display:flex;flex-direction:column;justify-content:center;align-items:center;position:relative;}
.contactListWrap .topDataWrap .fileUploadContainer .fileInput{display:none;}
.contactListWrap .topDataWrap .fileUploadContainer .fileLabel{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;cursor:pointer;}
.contactListWrap .topDataWrap .fileUploadContainer .icoWrap{margin:0 14px 0 0;}
.contactListWrap .topDataWrap .fileUploadContainer .fileLabel .dataWrap{text-align:left;}
.contactListWrap .topDataWrap .fileUploadContainer .fileLabel .dataWrap p{margin:0;font-weight:400;font-size:1.4rem;color:#282828;display:block;line-height:22px;}
.contactListWrap .topDataWrap .fileUploadContainer .fileLabel .dataWrap span{color:#676767;font-size:1rem;display:block;line-height:12px;}
.contactListWrap .topDataWrap .fileUploadContainer .uploadBtn{padding:6px 24px;background-color:#1944F1;color:#ffffff;border:none;border-radius:5px;cursor:pointer;transition:background-color 0.3s ease;box-shadow:0px 4px 4px 0px #00000036;outline:0;margin:0 0 0 10px;font-size:1.3rem;font-weight:500;line-height:20px;}
.contactListWrap .topDataWrap .rightWrap,
.contactListWrap .topDataWrap .leftContentWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-end;}
.contactListWrap .topDataWrap .rightWrap .filterWrap,
.contactListWrap .topDataWrap .leftContentWrap .filterWrap{margin:0 16px 0 0;}
.contactListWrap .topDataWrap .rightWrap .filterWrap select,
.contactListWrap .topDataWrap .leftContentWrap .filterWrap select{outline:0;box-shadow:none;border:1px solid #DBDBDB;border-radius:5px;background-size:10px;height:40px;width:100%;min-width:320px;font-size:1.3rem;color:#000000;}
.contactListWrap .topDataWrap .rightWrap .btnAddNew{border:0;color:#ffffff;background-color:#1944F1;font-size:1.3rem;font-weight:500;outline:0;border-radius:5px;padding:6px 16px;margin:0 0 0 16px;}
.contactListWrap .topDataWrap .rightWrap .btnAddNew svg{width:12px;height:12px;margin:0 6px 0 0;}
.contactListWrap .topDataWrap .leftContentWrap{justify-content:flex-start;width:calc(100% - 171px);}
.contactListWrap .topDataWrap .leftContentWrap .titleWrap{border:1px solid #DBDBDB;border-radius:5px;position:relative;height:40px;display:flex;align-items:center;width:100%;max-width:280px;margin:0 16px 0 0;padding:8px 12px;}
.contactListWrap .topDataWrap .leftContentWrap .titleWrap h2{color:#1944F1;margin:0;font-size:2rem;font-weight:500;}
.contactListWrap .topDataWrap .leftContentWrap .titleWrap .btn{padding:0;outline:0;box-shadow:none;width:17px;height:17px;border-radius:100%;
border:1px solid #98A2B3;position:absolute;left:98px;top:6px;display:flex;align-items:center;justify-content:center;}
.contactListWrap .topDataWrap .leftContentWrap .filterWrap{width:35.50%;}
.contactListWrap .topDataWrap .leftContentWrap .filterWrap select{min-width:1px;width:100%;}
.contactListWrap .topDataWrap .leftContentWrap .filterWrap.mart0px{margin:0;}
.contactListWrap .topDataWrap .rightWrap .searchWrap{border:1px solid #C1C1C1;border-radius:5px;height:36px;padding:2px 10px;max-width:230px;width:100%;}
.contactListWrap .topDataWrap .rightWrap .searchWrap input{border:0;border-radius:0;outline:0;box-shadow:none;color:#000000;font-size:1.2rem;font-weight:500;padding:0 6px 0 0;height:100%;width:calc(100% - 14px);}
.contactListWrap .topDataWrap .rightWrap .searchWrap .btnSearch{outline:0;border:0;background-color:transparent;border-radius:0;box-shadow:none;} 
.contactListWrap .topDataWrap .rightWrap .searchWrap .btnSearch svg{width:14px;height:14px;} 
.contactListWrap .topDataWrap .rightWrap .btn{outline:0;border:0;box-shadow:none;border-radius:0;padding:0 20px;position:relative;}
.contactListWrap .topDataWrap .rightWrap .btn.btnFilter:after{content:"";width:1px;background-color:#C1C1C1;height:34px;position:absolute;right:0px;top:50%;transform:translateY(-50%);}
.contactListWrap .ctaSave{position:absolute;bottom:10px;right:10px;}
.contactListWrap .ctaSave .btn{background-color:#1944F1;color:#ffffff;border-radius:5px;font-size:1.4rem;font-weight:500;outline:0;border:0;box-shadow:none;padding:6px 22px;margin:0 0 0 0.6rem;}

.contactListWrap .contactListTbl{height:calc(100vh - 170px);}
.contactListWrap .contactListTbl table{width:100%;border-collapse:collapse;}
.contactListWrap .contactListTbl table thead{position:relative;background-color:#F4F6FF;}
.contactListWrap .contactListTbl table thead tr th{text-align:left;padding:14px 8px;color:#515151;border:0;font-weight:500;font-size:1.2rem;letter-spacing:0;line-height:14px;}
.contactListWrap .contactListTbl table thead tr th:nth-child(1),
.contactListWrap .contactListTbl table tbody tr td:nth-child(1){padding:12px 8px 8px 30px;}
.contactListWrap .contactListTbl table tbody tr:nth-child(1) td{padding:12px 8px 8px;}
.contactListWrap .contactListTbl table tbody tr:nth-child(1) td:nth-child(1){padding:12px 8px 8px 30px;}
.contactListWrap .contactListTbl table tbody tr td{text-align:left;padding:8px;border-bottom:0.5px solid #0000004D;font-size:1.2rem;font-weight:400;letter-spacing:0;color:#515151;line-height:1.6rem;}
.contactListWrap .contactListTbl table tbody tr:hover{background-color:#e9eeff;cursor:pointer;}
/* .contactListWrap .contactListTbl table thead tr th:nth-child(9),
.contactListWrap .contactListTbl table thead tr th:nth-child(10),
.contactListWrap .contactListTbl table tbody tr td:nth-child(9),
.contactListWrap .contactListTbl table tbody tr td:nth-child(10){text-align:center;} */
.contactListWrap .contactListTbl table thead tr th:nth-child(3),
.contactListWrap .contactListTbl table tbody tr td:nth-child(3),
.contactListWrap .contactListTbl table thead tr th:last-child,
.contactListWrap .contactListTbl table tbody tr td:last-child{text-align:center;}
table tr input[type=checkbox]{position:relative;appearance:none;-webkit-appearance:none;width:16px;height:16px;border:1px solid #B3B3B3;cursor:pointer;background:#ffffff;outline:none;margin:0;padding:0;}
table tr input[type=checkbox]:checked{background-color:#ffffff;}
table tr input[type=checkbox]:checked:before{content:'';position:absolute;width:14px;height:14px;background-image:url(../img/Done.png);background-repeat:no-repeat;background-size:contain;background-position:center;top:0px;left:0px;}
.contactListWrap .contactListTbl table tbody tr td .labelWrap{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.contactListWrap .contactListTbl table tbody tr td .labelWrap span{display:inline-block;color:#1944F1;font-size:0.9rem;font-weight:400;background-color:#D9E0FF;border-radius:4px;padding:4px 12px;line-height:12px;margin:0 4px 0 0;}
.contactListWrap .contactListTbl table tbody tr td.activeTxt{color:#72C954;}
.contactListWrap .contactListTbl table tbody tr td .btn{padding:0;outline:0;border-radius:0;box-shadow:none;margin:0 4px;border:0;}
.contactListWrap .contactListTbl table tbody tr td .btn svg{width:14px;height:14px;}
/* .contactListWrap .contactListTbl table tbody tr td .btn:hover svg{opacity:1;}
.contactListWrap .contactListTbl table tbody tr td .btn:hover svg path:nth-child(1){fill:#ff0000;} */
.contactListWrap .contactListTbl table tr input[type=checkbox]{border-radius:5px;}
.contactListWrap .contactListTbl.contactListEditTbl table tr td .inputField input{width:100%;border-radius:5px;border:1px solid #C1C1C1;height:28px;color:#000000;font-size:1.2rem;font-weight:400;padding:2px 6px;}
.contactListWrap .addListCtaWrap{text-align:right;margin:16px 0 0;}
.contactListWrap .addListCtaWrap .btn{color:#ffffff;background-color:#1944F1;box-shadow:0px 4px 4px 0px #00000036;border-radius:5px;font-size:1.3rem;font-weight:500;outline:0;border:0;padding:10px 26px;}
.newContactListPopup{box-shadow:-5px 0px 8.6px 0px #00000033;background-color:#ffffff;border-radius:20px;max-width:380px;width:100%;position:fixed;top:58px;height:calc(100vh - 70px);z-index:99;right:-100%;transition:right 0.3s ease-in-out;padding:20px;}
.newContactListPopup.active{right:0;}
.newContactListPopup .topTtlWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;padding:0 0 20px;}
.newContactListPopup .topTtlWrap .leftTtl{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;}
.newContactListPopup .topTtlWrap .leftTtl .btn{padding:0;outline:0;border-radius:0;box-shadow:none;border:0;margin:0 8px 0 0;}
.newContactListPopup .topTtlWrap .leftTtl .btn svg{width:16px;height:16px;}
.newContactListPopup .topTtlWrap .leftTtl h2{color:#1944F1;margin:0;font-size:1.8rem;font-weight:500;}
.newContactListPopup .topTtlWrap .rightData p{color:#A9A9A9;margin:0;font-size:1.4rem;font-weight:500;}
.newContactListPopup .listTblWrap{background-color:#F3F6FF;border-radius:20px;height:calc(100vh - 210px);padding:10px 0;}
.newContactListPopup .listTblWrap table{width:100%;border-collapse:collapse;}
.newContactListPopup .listTblWrap table tr td{text-align:left;padding:10px;border-bottom:1px solid #DADADA;}
.newContactListPopup .listTblWrap table tr td h3{margin:0;color:#000000;font-size:1.3rem;font-weight:500;}
.newContactListPopup .listTblWrap table tr td p{margin:0;font-size:1.1rem;font-weight:400;line-height:16px;}
.newContactListPopup .listTblWrap table tr td .btn{padding:0;outline:0;border:0;box-shadow:none;}
.newContactListPopup .listTblWrap table tr td .btn svg{width:16px;height:16px;opacity:0.6;}
.newContactListPopup .listTblWrap table tr td .btn:hover svg{opacity:1;}
.newContactListPopup .listTblWrap table tr td .btn:hover svg path:nth-child(1){fill:#ff0000;}
.newContactListPopup .viewListCtaWrap{text-align:center;margin:16px auto 0;}
.newContactListPopup .viewListCtaWrap .btn{background-color:#1944F1;border-radius:5px;color:#ffffff;font-size:1.3rem;font-weight:500;box-shadow:none;outline:0;border:0;padding:10px 26px;}
.newContactListPopup .rcs-custom-scroll .kpmZQS{background:linear-gradient(180deg, #D9D9D9 0%, #D9D9D9 100%);}


.content.broadcastListPage{padding:60px 10px 0 90px;}
.broadcastListWrap{width:100%;border-radius:10px;background-color:#ffffff;padding:0;}
.broadcastListWrap .serachWrap{border-bottom:1px solid #DADADA;padding:6px 5px 6px 10px;}
.broadcastListWrap .serachWrap form{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;height:38px;}
.broadcastListWrap .serachWrap form .icoWrap{margin:0 10px 0 0}
.broadcastListWrap .serachWrap form .icoWrap svg{width:15px;height:15px;}
.broadcastListWrap .serachWrap form input{border:0;width:100%;max-width:320px;font-size:1.3rem;color:#000000;font-weight:500;}
.broadcastListWrap .serachWrap form input::placeholder{color:#ADB8CC;font-size:1.2rem;font-weight:400;}
.broadcastListWrap .serachWrap .rightWrap{text-align:right;}
.broadcastListWrap .serachWrap .rightWrap .btnAddNew{border:0;color:#ffffff;background-color:#1944F1;font-size:1.3rem;font-weight:500;outline:0;border-radius:5px;padding:6px 16px;}
.broadcastListWrap .rightWrap .btnAddNew svg{width:12px;height:12px;margin:0 6px 0 0;}
.broadcastListWrap .broadcastListTbl table{width:100%;border-collapse:collapse;}
.broadcastListWrap .broadcastListTbl table thead{background-color:#F6F6F6;}
.broadcastListWrap .broadcastListTbl table thead th{padding:8px 16px;color:#848484;font-size:1.4rem;font-weight:500;text-align:center;}
.broadcastListWrap .broadcastListTbl table thead th:nth-child(1),
.broadcastListWrap .broadcastListTbl table thead th:nth-child(2){text-align:left;}
.broadcastListWrap .broadcastListTbl table tbody td{padding:10px 16px;text-align:center;border-bottom:1px solid #DADADA;color:#000000;font-weight:500;font-size:1.4rem;}
.broadcastListWrap .broadcastListTbl table tbody tr:last-child td{border:0;}
.broadcastListWrap .broadcastListTbl table tbody td:nth-child(1),
.broadcastListWrap .broadcastListTbl table tbody td:nth-child(2){text-align:left;}
.broadcastListWrap .broadcastListTbl table tbody td ul{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;width:230px;}
.broadcastListWrap .broadcastListTbl table tbody td ul li{line-height:normal;width:72px;height:26px;display:flex;align-items:center;justify-content:center;margin:0 2px 4px;font-size:1rem;font-weight:400;border-radius:5px;}
.broadcastListWrap .broadcastListTbl table tbody td ul li.clrBlue{color:#1944F1;background-color:#b9c7ff91;border:1px solid rgba(25, 68, 241, 0.40);}
.broadcastListWrap .broadcastListTbl table tbody td ul li.clrYellow{color:#D1AC29;background-color:#faebb8ad;border:1px solid rgba(200, 158, 10, 0.40)}
.broadcastListWrap .broadcastListTbl table tbody td ul li.clrGray{background-color:#e2e2e2ad;border:1px solid rgba(189, 189, 189, 0.40);}
.broadcastListWrap .broadcastListTbl table tbody td ul li .btn:hover svg path{fill:#8E8E8E;}
.broadcastListWrap .broadcastListTbl table tbody td ul li.clrGray .btn{width:100%;height:100%;margin:0;}
.broadcastListWrap .broadcastListTbl table tbody td .statusWrap{position:relative;background-color:#DCF5D1;border:0.5px solid #78AE32;color:#78AE32;border-radius:30px;width:76px;height:26px;margin:0 auto;padding:0 0 0 10px;}
.broadcastListWrap .broadcastListTbl table tbody td .statusWrap:before{content:"";width:8px;height:8px;border-radius:100%;background-color:#78AE32;position:absolute;left:10px;top:50%;transform:translateY(-50%);}
.broadcastListWrap .broadcastListTbl table tbody td .statusWrap p{font-size:1rem;margin:0 auto;font-weight:400;}
.broadcastListWrap .broadcastListTbl table tbody td .btn{border:0;border-radius:0;outline:0;box-shadow:none;background-color:transparent;margin:0 6px;padding:0;}

.broadcastListWrap .broadcastListTbl .newLabelPopup{position:absolute;background:#ffffff;border:1px solid #E2E2E2;border-radius:8px;box-shadow:0px 4px 4px 0px #00000040;padding:0;z-index:2;top:0;width:260px;}
.broadcastListWrap .broadcastListTbl .newLabelPopup .titleWrap{text-align:center;border-bottom:1px solid #E0E0E0;padding:10px 10px;}
.broadcastListWrap .broadcastListTbl .newLabelPopup .titleWrap h2{margin:0 auto;font-size:1.2rem;color:#000000;font-weight:600;}
.broadcastListWrap .broadcastListTbl .newLabelPopup .newLabelFieldWrap{padding:16px 20px 16px;}
.broadcastListWrap .broadcastListTbl .newLabelPopup .newLabelFieldWrap input{border:1px solid #D3D3D3;border-radius:5px;height:26px;padding:2px 10px;color:#000000;font-size:1.2rem;font-weight:500;outline:0;box-shadow:none;width:100%;}
.broadcastListWrap .broadcastListTbl .newLabelPopup .newLabelFieldWrap input::placeholder{color:#BEBEBE;}
.broadcastListWrap .broadcastListTbl .newLabelPopup .newLabelFieldWrap .btn{padding:6px 20px;background-color:#1944F1;color:#ffffff;border:0;border-radius:4px;cursor:pointer;box-shadow:none;outline:0;font-size:1rem;font-weight:500;margin:10px auto 0;}


.content.workflowListPage{padding:60px 10px 0 90px;}
.workflowListWrap{width:100%;border-radius:10px;background-color:#ffffff;padding:10px 10px 16px;}
.workflowListWrap .workflowListTop{padding:0 0 10px;}
.workflowListWrap .workflowListTop .ctaWrap{text-align:right;}
.workflowListWrap .workflowListTop .ctaWrap .btn{border:0;color:#ffffff;background-color:#1944F1;font-size:1.3rem;font-weight:500;outline:0;border-radius:5px;padding:6px 16px;}
.workflowListWrap .workflowListTbl table{width:100%;}
.workflowListWrap .workflowListTbl table thead{box-shadow:0px 3px 7.5px 0px #00000033;position:relative;border-radius:6px;}
.workflowListWrap .workflowListTbl table thead tr{border:0;}
.workflowListWrap .workflowListTbl table thead tr th{border:0;color:#636363;text-align:center;font-weight:500;font-size:1.2rem;letter-spacing:0.2px;padding:16px 8px;line-height:14px;border-radius:6px;}
.workflowListWrap .workflowListTbl table thead tr th:nth-child(1){border-radius:6px 0 0 6px;text-align:left;}
.workflowListWrap .workflowListTbl table tbody tr{border:0;}
.workflowListWrap .workflowListTbl table tbody tr:nth-child(1) td{padding:20px 8px 8px;}
.workflowListWrap .workflowListTbl table tbody tr td{border:0;color:#000000;font-size:1.2rem;font-weight:500;letter-spacing:0.2px;padding:10px 8px;border-bottom:0.5px solid #0000004D;text-align:center;}
.workflowListWrap .workflowListTbl table tbody tr td:nth-child(1){text-align:left;}
.workflowListWrap .workflowListTbl table tbody tr td .btn{border:0;border-radius:0;outline:0;box-shadow:none;background-color:transparent;margin:0 4px;}
.workflowListWrap .workflowListTbl table tbody tr td .btn:hover svg path,
.workflowListWrap .workflowListTbl table tbody tr td .btn:hover svg circle{fill:#1944F1;}


/* .content.newBroadcastPage{padding:60px 10px 0 90px;} */
.content.newBroadcastPage{padding:60px 10px 0 10px;}
.newBroadcastWrap{width:100%;border-radius:10px;background-color:#ffffff;}
.newBroadcastWrap .leftContentWrap .titleWrap{display:flex;justify-content:center;align-items:center;border-bottom:1px solid #D3D3D3;padding:10px 0;}
.newBroadcastWrap .leftContentWrap .titleWrap .icoWrap{margin:0 8px 0 0;}
.newBroadcastWrap .leftContentWrap .titleWrap h2{font-size:1.8rem;color:#000000;font-weight:600;margin:0;}
.newBroadcastWrap .leftContentWrap .scrollContentWrap{height:calc(100vh - 170px);}
.newBroadcastWrap .leftContentWrap .scrollContentWrap .rcs-custom-scroll .kpmZQS{background:linear-gradient(180deg, #D9D9D9 0%, #D9D9D9 100%)!important;}
.newBroadcastWrap .messageTempWrap{padding:16px;}
.newBroadcastWrap .messageTempWrap .dataWrap{margin:0 0 8px;}
.newBroadcastWrap .messageTempWrap .dataWrap h3{font-size:1.5rem;color:#000000;font-weight:600;margin:0;}
.newBroadcastWrap .messageTempWrap .dataWrap p{font-size:1.2rem;color:#000000;font-weight:500;margin:0;line-height:16px;}
.newBroadcastWrap .messageTempWrap .messageTempBox{background-color:#F2F5FF;border-radius:10px;box-shadow:0px 4px 4px 0px #00000033;width:100%;padding:10px 30px;}
.newBroadcastWrap .messageTempWrap .messageTempBox ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;}
.newBroadcastWrap .messageTempWrap .messageTempBox ul li{width:48%;line-height:normal;}
.newBroadcastWrap .messageTempWrap .messageTempBox ul li .inputField{position: relative;}
.newBroadcastWrap .messageTempWrap .messageTempBox ul li .inputField label{color:#000000;font-size:1.3rem;font-weight:600;margin:0 0 4px;}
.newBroadcastWrap .messageTempWrap .messageTempBox ul li .inputField input,
.newBroadcastWrap .messageTempWrap .messageTempBox ul li .inputField select{background-color:#FFFFFF;border:0;outline:0;box-shadow:none;border-radius:5px;height:46px;font-size:1.3rem;font-weight:500;color:#000000;width:100%;padding:5px 10px;}
.newBroadcastWrap .messageTempWrap .messageTempBox ul li .inputField input::placeholder{color:#C1C1C1;}
.newBroadcastWrap .messageTempWrap .messageTempBox ul li .inputFieldWrap .addTempCta{text-align:right;margin:1px 0 0;}
.newBroadcastWrap .messageTempWrap .messageTempBox ul li .inputFieldWrap .addTempCta .btn{padding:0;outline:0;border:0;border-radius:0;box-shadow:none;color:#1944F1;font-size:1.1rem;font-weight:500;}
.newBroadcastWrap .leftContentWrap .ctaSubmit{text-align:right;margin:12px 16px 0 0;}
.newBroadcastWrap .leftContentWrap .ctaSubmit .btn{border:0;background-color:#1944F1;box-shadow:none;outline:0;color:#ffffff;font-size:1.2rem;font-weight:500;padding:6px 20px;border-radius:5px;}

.broadcastListDateTime{padding:6px 16px 16px;}
.broadcastListDateTime .contentWrap{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.broadcastListDateTime .contentWrap label{color:#000000;margin:0 16px 0 0;font-size:1.4rem;font-weight:500;width:230px;}
.broadcastListDateTime ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;margin:0;max-width:392px;width:100%;}
.broadcastListDateTime ul li{margin:0;width:48.60%;}
.broadcastListDateTime ul li .react-datepicker__input-container:before{content:"";background-repeat:no-repeat;background-position:center;background-size:contain;position:absolute;width:16px;height:16px;z-index:1;top:50%;transform:translateY(-50%);left:10px;}
.broadcastListDateTime ul li.datePicker .react-datepicker__input-container:before{background-image:url(../img/icoDate.png);}
.broadcastListDateTime ul li.timePicker .react-datepicker__input-container:before{background-image:url(../img/icoTime.png);}
.broadcastListDateTime ul li .react-datepicker__input-container input{border:1px solid #BBBBBB;border-radius:5px;font-size:1.3rem;padding:5px 10px 5px 34px;height:38px;font-weight:500;position:relative;}
.broadcastListDateTime ul li .ctaWrap{margin:0 0 0 6px;} 
.broadcastListDateTime ul li .ctaWrap .btn{background-color:#1944F1;border-radius:30px;outline:0;box-shadow:none;border:0;color:#ffffff;font-size:1.3rem;font-weight:500;padding:8px 16px;} 
.broadcastListImpWrap{padding:0 16px 10px;}
.broadcastListImpWrap .contentWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.broadcastListImpWrap .leftWrap{width:calc(100% - 200px);}
.broadcastListImpWrap .leftWrap .inputWrap{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;position:relative;}
.broadcastListImpWrap .leftWrap .inputWrap label{color:#000000;margin:0 16px 0 0;font-size:1.4rem;font-weight:500;width:230px;}
.broadcastListImpWrap .leftWrap .inputWrap select{width:100%;display:inline-block;max-width:392px;border:1px solid #BBBBBB;border-radius:5px;outline:0;background-size:10px 10px;height:40px;font-size:1.2rem;font-weight:500;outline:0;box-shadow:none;}
.broadcastListImpWrap .leftWrap .inputWrap .error{text-align:left!important;right:auto!important;left:246px!important;}
.broadcastListImpWrap .rightWrap .customFileUpload{display:inline-block;padding:6px 16px;cursor:pointer;color:#1944F1;background-color:#1944F112;border:1px dashed #1944F1;border-radius:8px;font-size:1.3rem;font-weight:600;outline:0;box-shadow:none;}
.broadcastListImpWrap .rightWrap #fileUpload{display:none;}
.newBroadcasttblWrap{padding:0 16px 0;}
.newBroadcasttblWrap .contentWrap{background-color:#F2F5FF;border-radius:10px;padding:16px;}
.newBroadcasttblWrap .topWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;margin:0 0 10px;}
.newBroadcasttblWrap .topWrap .searchWrap .inputWrap{display:flex;flex-flow:row wrap;justify-content:flex-start;background-color:#ffffff;border-radius:5px;padding:5px 10px;width:380px;height:32px;}
.newBroadcasttblWrap .topWrap .searchWrap .inputWrap .icoWrap{margin:0 6px 0 0;}
.newBroadcasttblWrap .topWrap .searchWrap .inputWrap input{outline:0;border:0;box-shadow:none;font-size:1.3rem;font-weight:500;color:#000000;width:calc(100% - 26px);height:100%;}
.newBroadcasttblWrap .topWrap .searchWrap .inputWrap input::placeholder{color:#ADB8CC;font-size:1.3rem;}
.newBroadcasttblWrap .topWrap .rightData{text-align:right;}
.newBroadcasttblWrap .topWrap .rightData p{margin:0;color:#A9A9A9;font-size:1.4rem;line-height:18px;}
.newBroadcasttblWrap .newBroadcastListTbl{position:relative;}
.newBroadcasttblWrap .newBroadcastListTbl table{width:100%;}
.newBroadcasttblWrap .newBroadcastListTbl table thead{box-shadow:0px 3px 7.5px 0px #00000033;position:relative;border-radius:10px;background-color:#ffffff;}
.newBroadcasttblWrap .newBroadcastListTbl table thead tr{border:0;background-color:#ffffff;}
.newBroadcasttblWrap .newBroadcastListTbl table thead tr th{border:0;color:#515151;text-align:center;font-weight:500;font-size:1.2rem;letter-spacing:0.2px;padding:12px 8px;line-height:14px;background-color:#ffffff;text-transform:capitalize;}
.newBroadcasttblWrap .newBroadcastListTbl table thead tr th:nth-child(1){border-radius:10px 0 0 10px;}
.newBroadcasttblWrap .newBroadcastListTbl table thead tr th:last-child{border-radius:0 10px 10px 0;}
.newBroadcasttblWrap .newBroadcastListTbl table tbody tr{border:0;}
.newBroadcasttblWrap .newBroadcastListTbl table tbody tr:nth-child(1) td{padding:14px 8px 8px;}
.newBroadcasttblWrap .newBroadcastListTbl table tbody tr td{border:0;color:#515151;font-size:1.2rem;font-weight:400;letter-spacing:0.2px;padding:10px 8px;border-bottom:0.5px solid #0000004D;text-align:center;background-color:transparent;}
.newBroadcasttblWrap .newBroadcastListTbl .error{color:#ff0000;line-height:10px;font-size:1rem;font-weight:500;letter-spacing:0.1px;padding:0;display:inline-block;text-align:left;width:100%;position:absolute;left:2px;bottom:-4px;background-color: #f2f5ff;}

.previewWrap{box-shadow:-5px 0px 11.3px 0px #00000033;border-radius:12px;height:calc(100vh - 7.2rem);padding:12px;}
.previewWrap .titleWrap{text-align:center;}
.previewWrap .titleWrap h2{color:#1944F1;margin:0 auto;font-size:1.8rem;font-weight:500;}
.pad0px{padding-left:0;padding-right:0;}
.previewWrap .preview{height:calc(100vh - 12rem);display:flex;align-items:center;justify-content:center;}
.previewWrap .preview .imgWrap{width:30rem;margin:0 auto;position:relative;} 
.previewWrap .preview .imgWrap img{width:100%;height:100%;object-fit:contain;}
.previewWrap .messageTempChatWrap{background-color:#ffffff;border-radius:8px;position:absolute;top:10rem;max-width:20rem;left:4rem;}
.previewWrap .messageTempChatWrap:after{content:"";background-image:url(../img/chatArrow.png);background-repeat:no-repeat;background-size:contain;width:16px;height:16px;position:absolute;left:-12px;bottom:10px;}
.previewWrap .messageTempChatWrap .topImgWrap{padding:4px;position:relative;}
.previewWrap .messageTempChatWrap .topImgWrap .imgBox{background-color:#D9D9D9;border-radius:4px;width:100%;height:86px;}
.previewWrap .messageTempChatWrap .topImgWrap .btnEdit{box-shadow:0px 2.66px 2.66px 0px #0000001F;background-color:#ffffff;width:20px;height:20px;border-radius:100%;padding:0;outline:0;border:0;position:absolute;top:-4px;right:-4px;display:flex;align-items:center;justify-content:center;}
.previewWrap .messageTempChatWrap .dataWrap{padding:6px 8px 0px;border-bottom:1px solid #D3D3D3;}
.previewWrap .messageTempChatWrap .dataWrap p{color:#000000;font-weight:500;font-size:1rem;line-height:16px;margin:0 0 10px;}
.previewWrap .messageTempChatWrap .ctaWrap{text-align:center;padding:8px 6px 6px;}
.previewWrap .messageTempChatWrap .ctaWrap .btn{color:#1944F1;padding:0;outline:0;border:0;box-shadow:none;font-weight:500;display:block;text-align:center;margin:0 auto 4px;font-size:0.9rem;letter-spacing:0.2px;}
.previewWrap .messageTempChatWrap .ctaWrap .btn svg{margin:0 3px 0 0;}

.content.messageTempPage{padding:60px 10px 0 90px;}
.messageTemplateWrap{width:100%;border-radius:10px;background-color:#ffffff;}
.messageTemplateWrap .leftContentWrap .titleWrap{display:flex;justify-content:flex-start;align-items:center;border-bottom:1px solid #D3D3D3;padding:10px 10px 10px 30px;}
.messageTemplateWrap .leftContentWrap .titleWrap .icoWrap{margin:0 8px 0 0;}
.messageTemplateWrap .leftContentWrap .titleWrap h2{font-size:1.8rem;color:#000000;font-weight:600;margin:0;}
.messageTemplateWrap .leftContentWrap .scrollContentWrap{height:calc(100vh - 146px);}
.messageTemplateWrap .leftContentWrap .scrollContentWrap .rcs-custom-scroll .kpmZQS{background:linear-gradient(180deg, #D9D9D9 0%, #D9D9D9 100%)!important;}
.messageTemplateWrap .messageTempFrmWrap{padding:16px 12px 16px 30px;}
.messageTemplateWrap .messageTempFrmWrap ul li{margin:0 0 16px;}
.messageTemplateWrap .messageTempFrmWrap ul li:last-child{margin:0;}
.messageTemplateWrap .messageTempFrmWrap ul li .inputField input{border:1px solid #BBBBBB;border-radius:5px;height:36px;color:#000000;font-size:1.3rem;padding:2px 10px;width:100%;max-width:380px;font-weight:400;}
.messageTemplateWrap .messageTempFrmWrap ul li label{color:#000000;font-size:1.2rem;font-weight:400;margin:0;line-height:14px;}
.messageTemplateWrap .messageTempFrmWrap ul li select{border:1px solid #BBBBBB;border-radius:5px;color:#000000;font-size: 1.3rem;height: 36px;width:100%;max-width:300px;}
.messageTemplateWrap .messageTempFrmWrap ul li textarea{border:1px solid #BBBBBB;border-radius:10px;width:100%;resize:none;padding:5px 10px;}
.messageTemplateWrap .messageTempFrmWrap ul li textarea::placeholder{color:#00000069;font-size:1.2rem;}
.messageTempFrmWrap .uploadBox{border:1px dashed #1944F1;border-radius:10px;padding:8px 12px 12px;text-align:center;position:relative;}
.messageTempFrmWrap .uploadBox .fileInput{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer;}
.messageTempFrmWrap .uploadBox .icoWrap{margin:0 auto 6px;}
.messageTempFrmWrap .uploadBox .uploadText{font-size:1.8rem;font-weight:500;color:#282828;}
.messageTempFrmWrap .uploadBox .chooseLink{color:#282828;font-weight:700;}
.messageTempFrmWrap .uploadBox .uploadInfo{font-size:1.2rem;color:#676767;line-height:16px;}
.messageTemplateWrap .messageTempFrmWrap ul li .btn{position:relative;border-radius:5px;font-size:1.2rem;font-weight:500;padding:10px 10px;}
.messageTemplateWrap .messageTempFrmWrap ul li .btn svg{margin:0 6px 0 0;}
.messageTemplateWrap .messageTempFrmWrap ul li .btnConfirm{background-color:#DCE3FF;border:1px solid #1944F1;color:#1944F1;}
.messageTemplateWrap .messageTempFrmWrap ul li .confirmCta .btnConfirm{width:100%;max-width:190px;text-align:left;} 
.messageTemplateWrap .messageTempFrmWrap ul li .confirmCta .btnConfirm:after{content:"";background-image:url(../img/icoCancel-1.png);background-repeat:no-repeat;background-size:contain;width:14px;height:14px;position:absolute;right:8px;top:50%;transform:translateY(-50%);}
.messageTemplateWrap .messageTempFrmWrap ul li .addCta{margin:14px 0;}
.messageTemplateWrap .messageTempFrmWrap ul li .addCta .btnGray{text-align:center;width:100%;max-width:190px;}
.messageTemplateWrap .messageTempFrmWrap ul li .btnGray{color:#9F9F9F;background-color:#EEEEEE;border:1px solid #D4D4D4}
.messageTemplateWrap .messageTempFrmWrap ul li .ctaGroup .btn{margin:0 10px 0 0;padding:9px 42px 9px 8px;}
.messageTemplateWrap .messageTempFrmWrap ul li .btnGreen{color:#2EB83C;border:1px solid #2EB83C;background-color:#D4FFD9;}
.messageTemplateWrap .messageTempFrmWrap ul li .btnGreen:after{content:"";background-image:url(../img/icoCancel-3.png);background-repeat:no-repeat;background-size:contain;width:14px;height:14px;position:absolute;right:8px;top:50%;transform:translateY(-50%);}
.messageTemplateWrap .messageTempFrmWrap ul li .ctaGroup .btnGray{margin:0;}
.messageTemplateWrap .messageTempFrmWrap ul li .ctaGroup .btnGray:after{content:"";background-image:url(../img/icoCancel-2.png);background-repeat:no-repeat;background-size:contain;width:14px;height:14px;position:absolute;right:8px;top:50%;transform:translateY(-50%);}
.messageTemplateWrap .saveCtaWrap{text-align:right;}
.messageTemplateWrap .saveCtaWrap .btn{box-shadow:0px 4px 4px 0px #00000036;border:0;outline:0;border-radius:5px;background-color:#1944F1;color:#ffffff;padding:8px 16px;font-size:1.3rem;font-weight:500;}
.messageTemplateWrap .rightBlankBox{background-color:#EDEDED;border-radius:10px;position:relative;min-height:210px;margin:50px 6px 0 0;}
.messageTemplateWrap .rightBlankBox .btnEdit{background-color:#EDEDED;padding:0;outline:0;border:0;border-radius:100%;position:absolute;right:8px;top:8px;}
.messageTemplateWrap .rightBlankBox .btnEdit svg{width:20px;height:20px;}
.messageTemplateWrap .previewWrap .preview .imgWrap{width:26rem;} 
.messageTemplateWrap .previewWrap .messageTempChatWrap{max-width:18rem;}

/* .content.broadcastHistoryPage{padding:60px 0 0 90px;} */
.content.broadcastHistoryPage{padding:60px 0 0 10px;}
.broadcastHistoryWrap{width:100%;border-radius:10px;background-color:#ffffff;}
.broadcastHistoryWrap .pad0{padding:0;}
.broadcastHistoryWrap .titleWrap{border-bottom:1px solid #D3D3D3;padding:10px 20px;}
.broadcastHistoryWrap .titleWrap ul{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;margin:0;}
.broadcastHistoryWrap .titleWrap ul li{display:flex;justify-content:flex-start;align-items:center;margin:0 36px 0 0;}
.broadcastHistoryWrap .titleWrap ul li .icoWrap{margin:0 8px 0 0;}
.broadcastHistoryWrap .titleWrap ul li h2{font-size:1.6rem;color:#B7B7B7;font-weight:600;margin:0;}
.broadcastHistoryWrap .titleWrap ul li.active h2{color:#000000;}


.broadcastHistoryWrap .topFiltersWrap{padding:16px 12px;}
.broadcastHistoryWrap .topFiltersWrap h2.topTTlTxt{color:#000000;font-size:1.4rem;margin:0 0 0.4rem;font-weight:600;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap{border-radius:10px;background-color:#F2F5FF;padding:16px 20px 29px;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap .topTitle{display:flex;flex-flow:row wrap;justify-content:flex-end;align-items:center;margin:0 0 12px;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap .topTitle .btn{outline:0;border:0;padding:0;box-shadow:none;color:#000000;font-size:1.1rem;font-weight:500;text-align:right;} 
.broadcastHistoryWrap .topFiltersWrap .filterWrap .topTitle .btn svg{margin:0 6px 0 0;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-end;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap ul li{width:24%;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap ul li:last-child{width:90px;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap ul li .inputField.fromDateWrap{position:relative;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap ul li .inputField.fromDateWrap:after{content:"";background-image:url(../img/arrowRight.png);background-repeat:no-repeat;background-size:contain;width:16px;height:12px;position:absolute;bottom:12px;right:-21px;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap ul li .inputField label{display:block;color:#959595;font-size:1.2rem;font-weight:500;margin:0;line-height:18px;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap ul li .inputField input{background-color:#ffffff;border:0;outline:0;box-shadow:none;border-radius:5px;font-size:1.3rem;color:#4D4D4D;font-weight:500;height:38px;padding:2px 10px;max-width:160px;width:100%;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap ul li .inputField select{background-color:#ffffff;border:0;outline:0;box-shadow:none;border-radius:5px;font-size:1.3rem;color:#4D4D4D;font-weight:500;height:38px;padding:2px 10px;max-width:160px;width:100%;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap ul li .ctaWrap{width:90px;}
.broadcastHistoryWrap .topFiltersWrap .filterWrap ul li .ctaWrap .btn{background-color:#1944F112;border:1px solid #1944F1;color:#1944F1;border-radius:8px;font-size:1.2rem;font-weight:600;padding:0 11px;height:36px;line-height:14px;}
.broadcastHistoryWrap .overviewTTl{padding:0 12px;}
.broadcastHistoryWrap .overviewTTl h2{color:#000000;font-size:1.4rem;font-weight:600;}
.broadcastHistoryWrap ul.overviewList{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;background-color:#F2F5FF;border-radius:10px;margin:0;padding:16px 0;}
.broadcastHistoryWrap ul.overviewList li{width:20%;position:relative;padding:0 16px;}
.broadcastHistoryWrap ul.overviewList li:after{content:"";background-color:#E0E0E0;width:1px;height:100%;position:absolute;top:0;right:0;}
.broadcastHistoryWrap ul.overviewList li:last-child::after{content:none;}
.broadcastHistoryWrap ul.overviewList li .itemWrap{position:relative;}
.broadcastHistoryWrap ul.overviewList li .itemWrap .title{min-height:30px;}
.broadcastHistoryWrap ul.overviewList li .itemWrap .title h2{color:#000000;font-size:3rem;font-weight:600;line-height:30px;margin:0 0 14px;}
.broadcastHistoryWrap ul.overviewList li .itemWrap .icoWrap{box-shadow:0px 0px 8.8px 0px #00000042 inset;
background-color:#ffffff;border-radius:12px;width:54px;height:54px;display:flex;align-items:center;justify-content:center;}
.broadcastHistoryWrap ul.overviewList li .itemWrap .icoWrap svg{width:24px;height:24px;}
.broadcastHistoryWrap ul.overviewList li .itemWrap .btn{position:absolute;right:-4px;display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;padding:0;outline:0;box-shadow:none;bottom:0;border:0;color:#A3A3A3;font-size:1.1rem;font-weight:500;}
.broadcastHistoryWrap ul.overviewList li .itemWrap .btn svg{width:12px;height:12px;margin:0 0 0 3px;}
.broadCastRepTblWrap{width:100%;margin:0 auto;padding:6px 12px 12px;}
.broadCastRepTblWrap .filterWrap{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;padding:0 0 22px;width:calc(100% - 200px);}
.broadCastRepTblWrap .filterWrap h2{color:#000000;font-size:1.4rem;font-weight:600;margin:0 4.8rem 0 0;}
.broadCastRepTblWrap .filterWrap .sortWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;max-width:260px;width:100%;}
.broadCastRepTblWrap .filterWrap .sortWrap label{color:#000000;font-size:1.4rem;font-weight:600;width:70px;}
.broadCastRepTblWrap .filterWrap .sortWrap select{width:calc(100% - 80px);border:0.8px solid #BBBBBB;border-radius:4px;color:#000000;font-size:1.2rem;font-weight:400;outline:0;box-shadow:none;height:34px;}
.broadCastRepTblWrap .filterWrap .btnFilter{margin:0 30px 0 40px;border:0;outline:0;box-shadow:none;background-color:transparent;padding:0;}
.broadCastRepTblWrap .filterWrap .searchWrap{border:0.8px solid #BBBBBB;border-radius:4px;position:relative;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;height:34px;padding:2px 6px 2px 10px;width:320px;}
.broadCastRepTblWrap .filterWrap .searchWrap input{border:0;outline:0;box-shadow:none;background-color:transparent;color:#000000;font-size:1.2rem;height:100%;width:calc(100% - 20px);font-weight:400;}
.broadCastRepTblWrap .filterWrap .searchWrap .btn{outline:0;box-shadow:none;border:0;width:20px;}
.broadcastHistoryWrap .broadCastRepTblWrap .NewBroadCastctaWrap{text-align:right;}
.broadcastHistoryWrap .broadCastRepTblWrap .NewBroadCastctaWrap .btn{border:0;outline:0;background-color:#1944F1;color:#FFFFFF;font-size:1.4rem;font-weight:500;letter-spacing:0.1px;box-shadow:0px 4px 4px 0px #00000036;padding:10px 20px;border-radius:5px;}
.broadcastHistoryWrap .broadCastRepTblWrap .NewBroadCastctaWrap .btn svg{width:16px;height:16px;margin:0 6px 0 0;}
.broadCastRepTblWrap .tblWrap table{width:100%;}
.broadCastRepTblWrap .tblWrap table thead{box-shadow:0px 3px 7.5px 0px #00000033;position:relative;border-radius:6px;}
.broadCastRepTblWrap .tblWrap table tr th{color:#000000;font-size:1.4rem;font-weight:600;padding:10px 12px;text-align:center;}
.broadCastRepTblWrap .tblWrap table tr td{padding:10px 12px 8px;text-align:center;color:#000000;font-size:1.3rem;font-weight:400;border-bottom:0.5px solid #0000004D;}
.broadCastRepTblWrap .tblWrap table tr:nth-child(1) td{padding:14px 12px 0px;}
.broadCastRepTblWrap .tblWrap table tr td span{display:block;line-height:22px;text-align:center;}
.broadCastRepTblWrap .tblWrap table tr th:first-child,
.broadCastRepTblWrap .tblWrap table tr td:first-child{text-align:left;}
.broadCastRepTblWrap .tblWrap table tr td .btn{padding:0;outline:0;box-shadow:none;border:0;margin:0 6px;}
.CircularProgressBox{display:flex;align-items:center;justify-content:center;flex-flow:row wrap;}
.CircularProgressBox .CircularProgressWrap{width:32px;height:32px;position:relative;}
.CircularProgressBox .CircularProgressWrap .CircularProgressOuter{position:absolute;top:0;left:0;width:100%;height:100%;border-radius:100%;background-color:#F2F5FF;box-sizing:border-box;padding:0;}
.CircularProgressBox .CircularProgressWrap .CircularProgressOuter .CircularProgressInner{position:relative;width:100%;height:100%;border-radius:100%;background-color:#ffffff;}
.CircularProgressBox .CircularProgressWrap .CircularProgressOuter .CircularProgressInner:after{content:"";width:26px;height:26px;position:absolute;background-color:#ffffff;z-index:-1;left:50%;top:50%;transform:translate(-50%, -50%);border-radius:30px;box-shadow:0px 0px 1.58px 0px #0000004f inset;}
.CircularProgressBox span{display:inline-block;margin:0 0 0 0.8rem;font-weight:600;font-size:1.3rem;width:40px;}


.content.userManagePage{padding:60px 10px 0 90px;}
.userManageWrap{width:100%;border-radius:10px;background-color:#ffffff;}
.userManageWrap .leftContentWrap .topDataWrap{display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid #D3D3D3;padding:10px 20px;}
.userManageWrap .leftContentWrap .topDataWrap .titleWrap{display:flex;justify-content:flex-start;align-items:center;}
.userManageWrap .leftContentWrap .topDataWrap .titleWrap .icoWrap{margin:0 8px 0 0;}
.userManageWrap .leftContentWrap .topDataWrap .titleWrap h2{font-size:1.8rem;color:#000000;font-weight:600;margin:0;}
.userManageWrap .leftContentWrap .topDataWrap .rightDataWrap{display:flex;justify-content:flex-end;align-items:center;width:calc(100% - 280px);}
.userManageWrap .leftContentWrap .topDataWrap .rightDataWrap .searchWrap{border:1px solid #BBBBBB;border-radius:5px;margin:0 12px 0 0;padding:2px 10px;max-width:230px;width:100%;display:flex;align-items:center;justify-content:space-between;}
.userManageWrap .leftContentWrap .topDataWrap .rightDataWrap .searchWrap input{border:0;padding:0;outline:0;background-color:transparent;box-shadow:none;font-size:1.3rem;font-weight:500;color:#000000;}
.userManageWrap .leftContentWrap .topDataWrap .rightDataWrap .searchWrap input::placeholder{color:rgba(0,0,0,0.5);font-size:1.2rem;}
.userManageWrap .leftContentWrap .topDataWrap .rightDataWrap .searchWrap .btn{padding:0;outline:0;box-shadow:none;border:0;}
.userManageWrap .leftContentWrap .topDataWrap .rightDataWrap .btnAddNew{box-shadow:0px 3.59px 3.59px 0px #00000036;color:#ffffff;background-color:#1944F1;border-radius:5px;font-size:1.3rem;font-weight:500;letter-spacing:0.2px;padding:3px 16px;}
.userManageWrap .leftContentWrap .topDataWrap .rightDataWrap .btnAddNew svg{width:16px;height:16px;margin:0 6px 0 0;}
.userManageWrap .usersTblWrap{padding:10px 20px;}
.userManageWrap .usersTblWrap table{width:100%;border-collapse:collapse;}
.userManageWrap .usersTblWrap table thead{box-shadow:0px 3px 7.5px 0px #00000033;position:relative;border-radius:6px;}
.userManageWrap .usersTblWrap table thead tr th{text-align:center;padding:14px 8px 12px;color:#2E2E2E;border:0;font-weight:500;font-size:1.2rem;letter-spacing:0.2px;line-height:14px;}
.userManageWrap .usersTblWrap table thead tr th:nth-child(1),
.userManageWrap .usersTblWrap table thead tr th:nth-child(2),
.userManageWrap .usersTblWrap table tbody tr td:nth-child(1),
.userManageWrap .usersTblWrap table tbody tr td:nth-child(2){text-align:left;}
.userManageWrap .usersTblWrap table tbody tr:nth-child(1) td{padding:12px 8px 8px;background-color:#F6F6F6;border-radius:0 0 12px 12px;border:0;}
.userManageWrap .usersTblWrap table tbody tr td .toprowWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.userManageWrap .usersTblWrap table tbody tr td .toprowWrap .leftWrap{text-align:left;}
.userManageWrap .usersTblWrap table tbody tr td .toprowWrap .leftWrap p{margin:0;}
.userManageWrap .usersTblWrap table tbody tr td .toprowWrap ul.rightWrap{display:flex;flex-flow:row wrap;justify-content:flex-end;align-items:center;margin:0;}
.userManageWrap .usersTblWrap table tbody tr td .toprowWrap ul.rightWrap li{position:relative;font-size:1rem;font-weight:600;letter-spacing:0.2px;}
.userManageWrap .usersTblWrap table tbody tr td .toprowWrap ul.rightWrap li span{display:inline-block;margin:0 0 0 2px;}
.userManageWrap .usersTblWrap table tbody tr td .toprowWrap ul.rightWrap li:after{content:"";background-color:#CBCBCB;width:1px;height:22px;right:-12px;position:absolute;top:0px;border-radius:30px;}
.userManageWrap .usersTblWrap table tbody tr td .toprowWrap ul.rightWrap li.active{color:#56C63A;border:1px solid #56C63A;border-radius:5px;line-height:14px;padding:4px 10px;margin:0 20px 0 0;}
.userManageWrap .usersTblWrap table tbody tr td .toprowWrap ul.rightWrap li.inActive{color:#DBB83B;}
.userManageWrap .usersTblWrap table tbody tr td .toprowWrap ul.rightWrap li.inActive:after{content:none;}
.userManageWrap .usersTblWrap table tbody tr td{text-align:center;padding:8px;border-bottom:0.5px solid #0000004D;font-size:1.2rem;font-weight:500;color:#000000;}
.userManageWrap .usersTblWrap table tbody tr td .userWrap{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.userManageWrap .usersTblWrap table tbody tr td .userWrap .imgWrap{width:38px;height:38px;border-radius:100%;margin:0 10px 0 0;}
.userManageWrap .usersTblWrap table tbody tr td .userWrap .imgWrap img{width:100%;height:100%;object-fit:cover;border-radius:100%;}
.userManageWrap .usersTblWrap table tbody tr td .userWrap .dataWrap{text-align:left;}
.userManageWrap .usersTblWrap table tbody tr td .userWrap .dataWrap h2{color:#000000;margin:0;font-size:1.2rem;font-weight:600;}
.userManageWrap .usersTblWrap table tbody tr td .userWrap .dataWrap p{color:#ADB8CC;font-size:1rem;font-weight:400;margin:0;line-height:14px;}
.userManageWrap .usersTblWrap table tbody tr td>span{display:inline-block;background-color:#D9E0FF;border:1px solid #1944F1;color:#1944F1;font-size:1rem;font-weight:500;line-height:14px;padding:4px 10px;border-radius:30px;margin:0 2px;}
.userManageWrap .usersTblWrap table tbody tr td .btn{color:#00000080;padding:0;outline:0;border-radius:0;box-shadow:none;font-size:1.2rem;font-weight:500;border:0;}
.userManageWrap .usersTblWrap table tbody tr td .btn:hover{color:#ff0000;}
.userManageWrap .usersTblWrap table tbody tr td .btn svg{width:14px;height:14px;margin:0 4px 0 0;opacity:0.6;}
.userManageWrap .usersTblWrap table tbody tr td .btn:hover svg{opacity:1;}
.userManageWrap .usersTblWrap table tbody tr td .btn:hover svg path:nth-child(1){fill:#ff0000;}
.userManageWrap .profileWrap{box-shadow:-5px 0px 8.6px 0px #00000033;border-radius:12px;height:calc(100vh - 72px);padding:0;}
.profileWrap .titleWrap{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;border-bottom:1px solid #DADADA;padding:8px 12px;}
.profileWrap .titleWrap .icoWrap{margin:0 2px 0 0;}
.profileWrap .titleWrap .icoWrap svg{width:16px;height:16px;}
.profileWrap .titleWrap h2{margin:0;font-size:1.6rem;color:#000000;font-weight:600;}
.profileWrap .scrollContentWrap{height:calc(100vh - 132px);}
.profileWrap .scrollContentWrap .rcs-custom-scroll .kpmZQS{background:linear-gradient(180deg, #D9D9D9 0%, #D9D9D9 100%)!important;}
.profileViewWrap .userInfoWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;padding:12px;}
.profileViewWrap .userInfoWrap .imgWrap{width:120px;height:120px;border-radius:100%;}
.profileViewWrap .userInfoWrap .imgWrap img{border-radius:100%;}
.profileViewWrap .userInfoWrap .dataWrap{width:calc(100% - 160px);display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;position:relative;}
.profileViewWrap .userInfoWrap .dataWrap .editCta{text-align:right;margin:0 0 10px;width:100%;}
.profileViewWrap .userInfoWrap .dataWrap .editCta .btnEdit{padding:0;outline:0;border:0;box-shadow:none;color:#1944F1;background-color:transparent;font-size:1.4rem;font-weight:500;}
.profileViewWrap .userInfoWrap .dataWrap .editCta .btnEdit svg{margin:0 4px 0 0;width:16px;height:16px;}
.profileViewWrap .userInfoWrap .dataWrap:before{content:"";background-color:#E0E0E0;width:1px;height:100%;position:absolute;top:0px;left:-22px;}
.profileViewWrap .userInfoWrap ul{margin:0;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;}
.profileViewWrap .userInfoWrap ul li{width:100%;margin:0 0 10px;}
.profileViewWrap .userInfoWrap ul li.half{width:49.50%;}
.profileViewWrap .userInfoWrap ul li h3{font-size:1.4rem;color:#000000;font-weight:600;margin:0;}
.profileViewWrap .userInfoWrap ul li p{font-size:1.4rem;color:#8D98AA;font-weight:400;margin:0;line-height:18px;}
.profileViewWrap .subscribedWrap{padding:0 12px 8px;}
.profileViewWrap .subscribedWrap .titleWrap{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;border:0;padding:0;margin:0 0 12px;}
.profileViewWrap .subscribedWrap .titleWrap .icoWrap{width:26px;height:26px;border-radius:100%;background-color:#C0F9B0;border:1px solid #70D257;display:flex;align-items:center;justify-content:center;margin:0 6px 0 0;}
.profileViewWrap .subscribedWrap .titleWrap h2{margin:0;font-size:1.6rem;color:#000000;font-weight:600;}
.profileViewWrap .subscribedWrap ul{margin:0;}
.profileViewWrap .subscribedWrap ul li{margin:0 0 10px;}
.profileViewWrap .subscribedWrap ul li .itemWrap{border:1px solid #D6D6D6;border-radius:5px;padding:8px 16px;}
.profileViewWrap .subscribedWrap ul li .itemWrap .topBox{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;border-bottom:1px solid #DBDBDB;padding:0 0 2px;margin:0 0 2px;}
.profileViewWrap .subscribedWrap ul li .itemWrap .topBox .leftPanel{text-align:left;}
.profileViewWrap .subscribedWrap ul li .itemWrap .topBox .leftPanel h2{margin:0;text-transform:uppercase;color:#000000;font-size:1.6rem;font-weight:600;}
.profileViewWrap .subscribedWrap ul li .itemWrap .topBox .rightPanel{text-align:right;}
.profileViewWrap .subscribedWrap ul li .itemWrap .topBox .rightPanel h3{margin:0;font-size:1.5rem;color:#010101;font-weight:500;}
.profileViewWrap .subscribedWrap ul li .itemWrap .topBox .rightPanel p{margin:0;font-size:1.4rem;color:#8D98AA;font-weight:400;}
.profileViewWrap .subscribedWrap ul li .itemWrap .btmBox{text-align:right;}
.profileViewWrap .subscribedWrap ul li .itemWrap .btmBox .btn{padding:0;outline:0;border:0;box-shadow:none;background-color:transparent;color:#1944F1;font-size:1.2rem;font-weight:500;}
.profileViewWrap .subscribedWrap .btnPlan{padding:0;outline:0;border:0;box-shadow:none;background-color:transparent;color:#1944F1;font-size:1.2rem;font-weight:500;}
.profileEditWrap .titleWrap .btnBackWrap{margin:0 12px 0 0;}
.profileEditWrap .titleWrap .btnBackWrap .btn{padding:0;outline:0;border:0;box-shadow:none;background-color:transparent;}
.profileEditWrap .titleWrap .btnBackWrap .btn svg{width:16px;height:16px;}
.profileEditWrap .profileEdit{width:100%;padding:26px 42px 16px;}
.profileEditWrap .profileEdit .uploadContainer{border:1px dashed #1944F1;padding:20px;border-radius:18px;text-align:center;cursor:pointer;width:110px;height:112px;display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:#F1F4FF;position:relative;}
.profileEditWrap .profileEdit .uploadContainer .icoWrap{margin:0 auto 6px;}
.profileEditWrap .profileEdit .uploadContainer .uploadLabel{font-size:1.2rem;font-weight:500;line-height:16px;color:#838383;}
.profileEditWrap .profileEdit .uploadContainer #file{position:absolute;width:100%;height:100%;top:0;left:0;opacity:0;cursor:pointer;}
.profileEditWrap .profileEdit ul{margin:10px 0 0;}
.profileEditWrap .profileEdit ul li{margin:0 0 12px;}
.profileEditWrap .profileEdit ul li .inputField label{color:#A8A8A8;font-weight:500;font-size:1.3rem;display:block;}
.profileEditWrap .profileEdit ul li .inputField input,
.profileEditWrap .profileEdit ul li .inputField select{border:1px solid #E0E4EC;border-radius:6px;height:40px;width:100%;padding:2px 10px;color:#000000;font-size:1.3rem;font-weight:500;outline:0;box-shadow:none;}
.profileEditWrap .profileEdit ul li .ctaWrap{text-align:center;margin:20px auto 0;}
.profileEditWrap .profileEdit ul li .ctaWrap .btn{outline:0;border:0;background-color:#1944F1;font-weight:500;color:#ffffff;font-size:1.4rem;border-radius:8px;padding:10px 30px;}
.profileEditWrap .profileEdit ul li .react-tel-input .flag-dropdown,
.profileEditWrap .profileEdit ul li .react-tel-input .selected-flag:hover, 
.profileEditWrap .profileEdit ul li .react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag{background-color:transparent!important;border:0;}
.profileEditWrap .profileEdit ul li .react-tel-input .flag{display:none;}
.profileEditWrap .profileEdit ul li .react-tel-input .selected-flag{width:56px;padding:2px 6px;}
.profileEditWrap .profileEdit ul li  .react-tel-input .form-control{padding:2px 10px;font-family:"Poppins", sans-serif;}

.content.IntWorkflowPage{padding:60px 10px 0 90px;}
.IntWorkflowWrap{width:100%;border-radius:10px;background-color:#ffffff;}
.IntWorkflowWrap .topDataWrap{display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid #D3D3D3;padding:10px 20px;}
.IntWorkflowWrap .topDataWrap .titleWrap h2{font-size:1.8rem;color:#000000;font-weight:600;margin:0;}
.IntWorkflowWrap .topDataWrap .titleWrap p{margin:0;color:#515151;font-size:1.2rem;font-weight:400;line-height:16px;}
.IntWorkflowWrap .topDataWrap .rightDataWrap{display:flex;justify-content:flex-end;align-items:center;width:calc(100% - 280px);}
.IntWorkflowWrap .topDataWrap .rightDataWrap ul{margin:0 14px 0 0;display:flex;flex-flow:row wrap;justify-content:flex-end;align-items:center;}
.IntWorkflowWrap .topDataWrap .rightDataWrap ul li{margin:0 10px 0 0;}
.IntWorkflowWrap .topDataWrap .rightDataWrap ul li .btn{color:#939393;outline:0;box-shadow:none;border:1px solid #C1C1C1;font-size:1.2rem;font-weight:300;border-radius:30px;padding:6px 16px;background-color:#ffffff;}
.IntWorkflowWrap .topDataWrap .rightDataWrap ul li .btn.active{color:#1944F1;background-color:#EAEEFF;border:1px solid #1944F1}
.IntWorkflowWrap .topDataWrap .rightDataWrap .btnAddNew{box-shadow:0px 3.59px 3.59px 0px #00000036;color:#ffffff;background-color:#1944F1;border-radius:5px;font-size:1.3rem;font-weight:500;letter-spacing:0.2px;padding:3px 16px;}
.IntWorkflowWrap .topDataWrap .rightDataWrap .btnAddNew svg{width:16px;height:16px;margin:0 6px 0 0;}
.IntWorkflowWrap .IntWorkflowListWrap{padding:26px 0;}
.IntWorkflowListWrap .padRtLt{padding:0 20px;}
.IntWorkflowListWrap .itemWrap{border:1px solid #C9C9C9;background-color:#ffffff;box-shadow:0px 3.73px 4.66px 0px #00000040;border-radius:10px;padding:26px 20px 26px 36px;margin:0 0 40px;}
.IntWorkflowListWrap .itemWrap .topWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;}
.IntWorkflowListWrap .itemWrap .topWrap .icoWrap{box-shadow:0px 0px 6.3px 0px #00000040 inset;width:58px;height:58px;border-radius:5px;background-color:#ffffff;display:flex;flex-flow:row wrap;align-items:center;justify-content:center;}
.IntWorkflowListWrap .itemWrap .topWrap .icoWrap img{width:32px;height:32px;}
.IntWorkflowListWrap .itemWrap .topWrap .switchBtnWrap .switch{width:60px;height:30px;background:#e0dede;border-radius:30px;position:relative;cursor:pointer;transition:background 0.3s;}
.IntWorkflowListWrap .itemWrap .topWrap .switchBtnWrap .switch.on{background:#1944F1;}
.IntWorkflowListWrap .itemWrap .topWrap .switchBtnWrap .switch.off{background:#e0dede;}
.IntWorkflowListWrap .itemWrap .topWrap .switchBtnWrap .switchBtn{width:24px;height:24px;background:#ffffff;border-radius:100%;position:absolute;top:3px;left:4px;transition:left 0.3s;box-shadow:0px 0px 6.7px 0px #000000a1 inset;}
.IntWorkflowListWrap .itemWrap .topWrap .switchBtnWrap .switch.on .switchBtn{left:32px;}
.IntWorkflowListWrap .itemWrap .dataWrap{padding:40px 0 0;}
.IntWorkflowListWrap .itemWrap .dataWrap h2{color:#000000;font-size:1.8rem;font-weight:600;margin:0 0 4px;}
.IntWorkflowListWrap .itemWrap .dataWrap p{font-size:1.3rem;color:#000000;font-weight:400;letter-spacing:0.2px;line-height:20px;margin:0;}


.content.subscriptionPage{padding:60px 10px 0 90px;}
.subscriptionWrap{width:100%;border-radius:10px;background-color:#ffffff;}
.subscriptionWrap .topDataWrap{display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid #D3D3D3;padding:10px 30px;}
.subscriptionWrap .topDataWrap .titleWrap h2{font-size:1.8rem;color:#000000;font-weight:600;margin:0;}
.subscriptionWrap .topDataWrap .titleWrap p{margin:0;color:#515151;font-size:1.2rem;font-weight:400;line-height:16px;}
.subscriptionWrap .topDataWrap .rightDataWrap{display:flex;justify-content:flex-end;align-items:center;width:calc(100% - 280px);}
.subscriptionWrap .topDataWrap .rightDataWrap .filterWrap{border:1px solid #BBBBBB;border-radius:5px;height:38px;padding:2px 10px;max-width:280px;width:100%;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.subscriptionWrap .topDataWrap .rightDataWrap .filterWrap input{border:0;padding:0;outline:0;box-shadow:none;font-size:1.3rem;color:#000000;font-weight:500;background-color:transparent;height:100%;width:calc(100% - 30px);}
.subscriptionWrap .topDataWrap .rightDataWrap .filterWrap input::placeholder{color:rgba(0,0,0,0.5);font-size:1.2rem;font-weight:500;}
.subscriptionWrap .topDataWrap .rightDataWrap .filterWrap .btn{padding:0;outline:0;box-shadow:none;border:0;}
.subscriptionWrap .topDataWrap .rightDataWrap .filterWrap .btn svg{width:16px;height:16px;}
.subscriptionWrap .subscriptionTtlWrap{padding:12px 30px;}
.subscriptionWrap .subscriptionTtlWrap h2.upgradeTxt{color:#848484;font-weight:500;font-size:1.8rem;margin:0;}
.subscriptionWrap .subscriptionTtlWrap ul.tabsBtnsList{display:flex;flex-flow:row wrap;justify-content:flex-end;align-items:center;background-color:#EAEEFF;width:100%;margin:0;max-width:200px;border-radius:30px;padding:5px;}
.subscriptionWrap .subscriptionTtlWrap ul.tabsBtnsList li{width:50%;line-height:normal;}
.subscriptionWrap .subscriptionTtlWrap ul.tabsBtnsList li .btn{padding:0;outline:0;box-shadow:none;border:0;color:#1944F1;border-radius:30px;width:100%;padding:6px 10px;font-size:1.4rem;font-weight:600;}
.subscriptionWrap .subscriptionTtlWrap ul.tabsBtnsList li .btn.active{background-color:#1944F1;color:#ffffff;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;margin:0 -15px;height:100%;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li{width:33.33%;padding:0 15px;height:100%;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li .itemWrap{border:1px solid #1944F1;box-shadow:0px 3.73px 4.66px 0px #00000040;border-radius:10px;height:100%;background-color:#F2F5FF;display:flex;flex-flow:column;justify-content:space-between;position:relative;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li .itemWrap .icoWrap{position:absolute;right:12px;top:8px;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li.proplan .itemWrap{background-color:#F8F8F8;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li .itemWrap .topWrap{padding:30px 30px 0;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li .itemWrap .topWrap h2{font-size:1.8rem;color:#000000;font-weight:600;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li .itemWrap .topWrap p{color:#777777;margin:0;font-size:1.3rem;line-height:22px;letter-spacing:0.2px;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li .itemWrap .btmWrap .dataWrap{padding:30px 30px 6px;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li .itemWrap .btmWrap .dataWrap a{color:#1944F1;font-weight:500;font-size:1.4rem;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li .itemWrap .btmWrap .dataWrap p{color:#000000;font-size:1.1rem;font-weight:400;letter-spacing:0.3px;margin:4px 0 0;line-height:16px;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li .itemWrap .btmWrap .dataWrap h3{color:#000000;font-weight:600;font-size:3.8rem;margin:0;line-height:38px;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li .itemWrap .btmWrap .dataWrap h3 span{color:#6D6D6D;font-size:1.4rem;font-weight:400;letter-spacing:0.3px;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li .itemWrap .btmWrap .ctaWrap .btn{border:0;border-top:1px solid #1944F1;color:#1944F1;width:100%;border-radius:0;padding:14px 10px;outline:0;box-shadow:none;font-weight:600;font-size:1.6rem;letter-spacing:0.6px;}
.subscriptionWrap .subscriptionTtlWrap ul.subscriptionList li.proplan .itemWrap .btmWrap .ctaWrap .btn{color:#8FA6FF;}
.subPurHistoy{width:100%;padding:30px 30px 20px;}
.subPurHistoy .ttlTxtWrap{margin:0 0 16px;}
.subPurHistoy .ttlTxtWrap h2{color:#000000;font-size:2rem;font-weight:600;margin:0;}
.subPurHistoy .subPurHistoyTbl{position:relative;}
.subPurHistoy .subPurHistoyTbl:after{content:"";border:1px solid #D3D3D3;width:100%;height:calc(100% - 56px);position:absolute;bottom:0;left:0;border-radius:6px;}
.subPurHistoy .subPurHistoyTbl table{width:100%;border-collapse:collapse;}
.subPurHistoy .subPurHistoyTbl table thead{position:relative;border-radius:6px;text-align:center;padding:0 0 10px;}
.subPurHistoy .subPurHistoyTbl table thead tr th{text-align:center;padding:14px 8px;color:#000000;border:0;font-weight:500;font-size:1.4rem;letter-spacing:0.2px;line-height:14px;background-color:#F4F6FF;}
.subPurHistoy .subPurHistoyTbl table thead tr th:nth-child(1){border-radius:6px 0 0 6px;}
.subPurHistoy .subPurHistoyTbl table thead tr th:last-child{border-radius:0 6px 6px 0;}
.subPurHistoy .subPurHistoyTbl table tbody tr:nth-child(1){border-radius:6px 6px 0 0;}
.subPurHistoy .subPurHistoyTbl table tbody tr:nth-child(1) td{padding:20px 8px 8px;}
.subPurHistoy .subPurHistoyTbl table tbody tr td{text-align:center;padding:8px;border-bottom:0.5px solid #0000004D;font-size:1.3rem;font-weight:500;letter-spacing:0.2px;color:#000000;}
.subPurHistoy .subPurHistoyTbl table tbody tr:last-child td{border:0;}
.subPurHistoy .subPurHistoyTbl table tbody tr td span{display:inline-block;font-size:1.3rem;font-weight:500;border-radius:30px;position:relative;padding:4px 20px 4px 36px;}
.subPurHistoy .subPurHistoyTbl table tbody tr td span:before{content:"";width:8px;height:8px;border-radius:100%;position:absolute;left:16px;top:50%;transform:translateY(-50%);}
.subPurHistoy .subPurHistoyTbl table tbody tr td span.success{color:#70D257;border:1px solid #70D257;background-color:#C0F9B0;}
.subPurHistoy .subPurHistoyTbl table tbody tr td span.decline{color:#FE3737;border:1px solid #FE3737;background-color:#FCDBDB;}
.subPurHistoy .subPurHistoyTbl table tbody tr td span.success:before{background-color:#70D257;}
.subPurHistoy .subPurHistoyTbl table tbody tr td span.decline:before{background-color:#FE3737;}
.subPurHistoy .subPurHistoyTbl table tbody tr td .btn{padding:0;outline:0;box-shadow:none;border:0;font-size:1.3rem;color:#1944F1;font-weight:500;}

body.modal-open .content.contactListPage{filter:blur(5px);transition:filter 0.3s ease-in-out;}
.modal-backdrop.show{background-color:#00000036;opacity:1;}
.newContactPopup.modal.show .modal-dialog{max-width:920px;display:flex;align-items:center;justify-content:center;height:100%;}
.newContactPopup .modal-content{border:0;border-radius:10px;padding:0 40px 40px;}
.newContactPopup .modal-header{border:0;text-align:center;padding:20px;}
.newContactPopup .modal-header .modal-title{width:100%;color:#000000;font-weight:600;font-size:2.2rem;}
.newContactPopup .modal-header .btn-close{display:none;}
.newContactPopup .modal-body{padding:0;}
.newContactPopup .modal-body .leftBtnWrap .uploadContainer{width:100%;border:1px solid #939393;border-radius:8px;overflow:hidden;background-color:#F4F4F4;}
.newContactPopup .modal-body .leftBtnWrap .uploadData{display:flex;flex-direction:column;align-items:center;justify-content:center;position:relative;height:230px;border-bottom:1px solid #939393;}
.newContactPopup .modal-body .leftBtnWrap .uploadData:after{content:"";background-image:url(../img/uploadBg.png);background-repeat:no-repeat;background-size:contain;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);width:240px;
height:137px;z-index:1;}
/* .newContactPopup .modal-body .leftBtnWrap .uploadPlus{position:relative;z-index:2;top:50%;transform:translateY(-50%);} */
.newContactPopup .modal-body .leftBtnWrap .uploadPlus{z-index:2;top:50%;}
.newContactPopup .modal-body .leftBtnWrap .uploadInputs{opacity:0;width:100%;height:100%;position:absolute;top:0;left:0;
cursor:pointer;position:relative;z-index:2;}

.newContactPopup .modal-body .leftBtnWrap .uploadFooter{padding:6px;text-align:center;background-color:#F4F4F4;font-size:1.4rem;font-weight:500;color:#515151;}
.newContactPopup .modal-body .rightBtnWrap .tblDataWrap{border:1px solid #939393;border-radius:8px;} 
.newContactPopup .modal-body .rightBtnWrap .tblDataWrap .topTtl{border-bottom:1px solid #939393;padding:6px 10px;}
.newContactPopup .modal-body .rightBtnWrap .tblDataWrap .topTtl h3{margin:0;color:#515151;font-weight:500;font-size:1.6rem;}
.newContactPopup .modal-body .rightBtnWrap .tblWrap{padding:0px 0 0 10px;height:236px;}
.newContactPopup .modal-body .rightBtnWrap .tblWrap .rcs-custom-scroll .kpmZQS{background:linear-gradient(180deg, #939393 0%, #939393 100%);}
.newContactPopup .modal-body .rightBtnWrap .tblWrap table{width:calc(100% - 12px);} 
.newContactPopup .modal-body .rightBtnWrap .tblWrap table tr td{border-bottom:0.5px solid #C1C1C1;color: #515151;font-size:1.2rem;font-weight:400;padding:4px;}
.newContactPopup .modal-body .rightBtnWrap .tblWrap table tr td:nth-child(3){text-align:right;}
.newContactPopup .modal-body .rightBtnWrap .tblWrap table tr td .btn{padding:0;outline:0;box-shadow:none;background-color:transparent;border-radius:0;text-align:right;border:0;color:#1944F1;}   
.newContactPopup .uploadBox{border:1px dashed #1944F1;border-radius:10px;padding:30px 12px 36px;text-align:center;position:relative;margin:26px auto 0;}
.newContactPopup .uploadBox .fileInput{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer;}
.newContactPopup .uploadBox .icoWrap{margin:0 auto 6px;}
.newContactPopup .uploadBox .uploadText{font-size:1.8rem;font-weight:500;color:#282828;}
.newContactPopup .uploadBox .chooseLink{color:#282828;font-weight:700;}
.newContactPopup .uploadBox .uploadInfo{font-size:1.2rem;color:#676767;line-height:16px;}



/* .content.newContactListPage{padding:60px 10px 0 90px;} */
.content.newContactListPage{padding:60px 10px 0 10px;}
.newContactListWrap{width:100%;border-radius:10px;background-color:#ffffff;padding:10px 0 16px;height:calc(100vh - 70px);}
.newContactListWrap .ht100{height:100%;}
.newContactListWrap .leftPannelWrap{padding:6px 16px 0;height:100%;border-right:1px solid #C1C1C1;position:relative;}
.newContactListWrap .leftPannelWrap .topWrap{border-bottom:1px solid #C1C1C1;padding:0 0 12px;margin:0 0 10px;}
.newContactListWrap .leftPannelWrap .topWrap .ttlWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;}
.newContactListWrap .leftPannelWrap .topWrap .ttlWrap .icoWrap{margin:0 1rem 0 0;}
.newContactListWrap .leftPannelWrap .topWrap .ttlWrap .icoWrap svg{width:3rem;height:3rem;}
.newContactListWrap .leftPannelWrap .topWrap .ttlWrap h2{color:#000000;margin:0;font-size:2rem;font-weight:600;}
.newContactListWrap .leftPannelWrap .createCtaWrap{position:absolute;bottom:0px;right:16px;}
.newContactListWrap .leftPannelWrap .createCtaWrap .btn{background-color:#1944F1;border-radius:5px;color:#ffffff;font-weight:500;font-size:1.4rem;border:0;padding:6px 20px;box-shadow:none;outline:0;margin:0 0 0 0.6rem;}
.contactListNameWrap{padding:0 0 36px;}
.contactListNameWrap .ttl{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;}
.contactListNameWrap .ttl .icoWrap{margin:0 8px 0 0;}
.contactListNameWrap .ttl h3{color:#000000;font-size:1.6rem;font-weight:600;margin:0;}
.contactListNameWrap .inputField{position:relative;}
.contactListNameWrap .inputField input{border:1px solid #C1C1C1;border-radius:5px;height:36px;width:100%;outline:0;box-shadow:none;color:#000000;font-weight:400;font-size:1.4rem;padding:2px 10px;}
.contactListNameWrap .inputField input:disabled {background-color:#e9e9e9;color:#4e4e4e;opacity:0.6;}


.contactListNameWrap .inputField .errMsg span,
.contactListNameWrap .inputField .errMe span{color:#ff0000;line-height:10px;font-size:1rem;font-weight:500;letter-spacing:0.1px;padding:0;display:inline-block;text-align:left;width:100%;position:absolute;left:2px;bottom:-10px;}
.contactListNameWrap .inputField input::placeholder{color:#C1C1C1;}
.contactListNameWrap .inputField .btn{padding:0;outline:0;border:0;box-shadow:none;position:absolute;bottom:-18px;right:0;color:#1944F1;font-size:1rem;font-weight:400;}
.contactListNameWrap .inputField .btn svg{margin:0 0.2rem 0 0;}
.contactListLeftScroll{height:calc(100vh - 192px);}
.contactListFields .ttl{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;border-bottom:1px solid #C1C1C1;padding:0 0 4px;margin:0 0 26px;}
.contactListFields .ttl .icoWrap{margin:0 8px 0 0;}
.contactListFields .ttl h3{color:#000000;font-size:1.6rem;font-weight:600;margin:0;}
.contactListFields .ttl h3 svg{margin:0 0 0 6px;}
.contactListFields ul.formList li{margin:0 0 16px;}
.contactListFields ul.formList li .inputField{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;position:relative;}
.contactListFields ul.formList li .inputField label{color:#000000;margin:0 6px 0 0;font-size:1.2rem;font-weight:400;line-height:14px;width:100px;}
.contactListFields ul.formList li .inputField .inputsGroup{width:calc(100% - 110px);display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.contactListFields ul.formList li .inputField .inputsGroup input,
.contactListFields ul.formList li .inputField .inputsGroup select{border:1px solid #C1C1C1;border-radius:5px;height:32px;outline:none;box-shadow:none;color:#000000;font-size:1.1rem;font-weight:400;width:48.60%;}
.contactListFields ul.formList li .inputField input,
.contactListFields ul.formList li .inputField select{border:1px solid #C1C1C1;border-radius:5px;height:34px;outline:0;box-shadow:none;color:#000000;font-weight:400;font-size:1.1rem;padding:0.2rem 0.6rem;width:calc(100% - 106px);}
.contactListFields ul.formList li .inputField input::placeholder{color:#C1C1C1;}
.contactListFields ul.formList li .inputField .error,
.newBroadcastWrap .messageTempWrap .messageTempBox ul li .inputField .error,
.broadcastListImpWrap .leftWrap .inputWrap .error{color:#ff0000;line-height:10px;font-size:1rem;font-weight:500;letter-spacing:0.1px;padding:0;display:inline-block;text-align:right;width:100%;position:absolute;right:2px;bottom:-10px;}
.newBroadcastWrap .messageTempWrap .messageTempBox ul li .inputField .error{right:auto;left:2px;text-align:left;}
.contactListFields ul.formList li .inputGroup{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;position:relative;}
.contactListFields ul.formList li .inputGroup .inputField.half{width:45.60%;}
/* .contactListFields ul.formList li .inputGroup .inputField.third{width:33.33%;} */
.contactListFields ul.formList li .inputGroup .inputField.half input,
.contactListFields ul.formList li .inputGroup .inputField.half select,
.contactListFields ul.formList li .inputGroup .inputField.half>div{width:100%;}
.contactListFields ul.formList li .inputGroup .inputField.half>div .css-qbdosj-Input{margin:0;padding:0;}
.contactListFields ul.formList li .inputGroup .inputField.half>div .css-13cymwt-control{border:1px solid #C1C1C1;border-radius:5px;min-height:34px;}
.contactListFields ul.formList li .inputGroup .inputField.half>div .css-1fdsijx-ValueContainer{padding:0 8px;}
.contactListFields ul.formList li .inputGroup .inputField.half>div .css-1nmdiq5-menu{padding:0 1.2rem;margin:0;font-size:1.3rem;}
.contactListFields ul.formList li .inputGroup .inputField.half>div .css-hlgwow{padding:0 0.8rem!important;}
.contactListFields ul.formList li .inputGroup .inputField.half>div .css-19bb58m{margin:0!important;padding:0!important;}
.contactListFields ul.formList li .inputGroup .inputField.half>div .css-1jqq78o-placeholder{font-size:1.3rem;}
.contactListFields ul.formList li .inputGroup .btnDelete{padding:0;border:0;outline:0;box-shadow:none;background-color:transparent;}
.contactListFields ul.formList li .inputGroup .btnDelete svg{fill:#9ea0a3;}
.contactListFields ul.formList li .inputGroup .btnDelete:hover svg{fill:#ff0000;}
.contactListFields ul.formList li.newFieldsWrap{margin:0;}
.contactListFields ul.formList li.newFieldsWrap ul{position:relative;}
.contactListFields ul.formList li.newFieldsWrap ul::-webkit-scrollbar{width:4px;}
.contactListFields ul.formList li.newFieldsWrap ul::-webkit-scrollbar-thumb{background-color:#d9d9d9;border-radius:20px;}
.contactListFields ul.formList li.newFieldsWrap ul::-webkit-scrollbar-thumb:hover{background-color:#d9d9d9;}
.contactListFields ul.formList li.newFieldsWrap ul::-webkit-scrollbar-track{background-color:rgba(33, 31, 32, 0.1);border-radius:20px;}



.contactListFields ul.formList li .ctaWrap{margin:24px 0 0;transition: bottom 0.3s ease-in-out;}
.contactListFields ul.formList li .ctaWrap .btn{outline:0;box-shadow:none;background-color:#F6F6F6;border:1px solid #C1C1C1;border-radius:5px;color:#515151;font-size:1.2rem;font-weight:400;text-align:center;width:100%;padding:7px 20px;}
.contactListFields ul.formList  li.newFieldsWrap .ctaWrap{margin:10px 0 0;}
.newContactListWrap .rightPannelWrap{padding:6px 10px 0 0;}
.newContactListWrap .rightPannelWrap .topWrap{padding:0 0 12px;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.newContactListWrap .rightPannelWrap .topWrap .ttlWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;}
.newContactListWrap .rightPannelWrap .topWrap .ttlWrap .icoWrap{margin:0 1rem 0 0;}
.newContactListWrap .rightPannelWrap .topWrap .ttlWrap .icoWrap svg{width:3rem;height:3rem;}
.newContactListWrap .rightPannelWrap .topWrap .ttlWrap h2{color:#000000;margin:0;font-size:2rem;font-weight:600;}
.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-end;width:calc(100% - 40%);}
.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .btn{outline:0;border:0;box-shadow:none;border-radius:0;padding:0 20px;position:relative;}
.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .btn.btnFilter:after{content:"";width:1px;background-color:#C1C1C1;height:34px;position:absolute;right:0px;top:50%;transform:translateY(-50%);}
.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .searchWrap{border:1px solid #C1C1C1;border-radius:5px;height:36px;padding:2px 10px;max-width:230px;width:100%;}
.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .searchWrap input{border:0;border-radius:0;outline:0;box-shadow:none;color:#000000;font-size:1.2rem;font-weight:500;padding:0 6px 0 0;height:100%;width:calc(100% - 14px);}
.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .searchWrap .btnSearch{outline:0;border:0;background-color:transparent;border-radius:0;box-shadow:none;} 
.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .searchWrap .btnSearch svg{width:14px;height:14px;} 
.newContactListWrap .rightPannelWrap .tblWrap table{width:100%;border-collapse:collapse;}
.newContactListWrap .rightPannelWrap .tblWrap table thead{position:relative;background-color:#F4F6FF;}
.newContactListWrap .rightPannelWrap .tblWrap table thead tr th{text-align:left;padding:14px 8px;color:#515151;border:0;font-weight:500;font-size:1.2rem;letter-spacing:0;line-height:14px;}
.newContactListWrap .rightPannelWrap .tblWrap table tbody tr:nth-child(1) td{padding:12px 8px 8px;}
.newContactListWrap .rightPannelWrap .tblWrap table tbody tr td{text-align:left;padding:8px;border-bottom:0.5px solid #0000004D;font-size:1.2rem;font-weight:400;letter-spacing:0;color:#515151;}
.newContactListWrap .rightPannelWrap .tblWrap table thead tr th:nth-child(9),
.newContactListWrap .rightPannelWrap .tblWrap table tbody tr td:nth-child(9){text-align:center;}
.newContactListWrap .rightPannelWrap .tblWrap table tr input[type=checkbox]{border-radius:5px;}
.newContactListWrap .rightPannelWrap .tblWrap table thead tr th:last-child{text-align:center;}
table tbody tr td{position:relative;}
table tbody tr td div.error{position:absolute;color:#ff0000;line-height:10px;font-size:0.8rem;font-weight:500;letter-spacing:0.1px;left:12px;padding:0;bottom:-2px;display:inline-block;background-color:#ffffff;}

.newListWrap.newContactListWrap{padding:0;}
.newListWrap.newContactListWrap .pad0{padding:0;}
.newListWrap.newContactListWrap .rightPannelWrap{padding:0;position: relative;height:100%;}
.newListWrap.newContactListWrap .rightPannelWrap .topWrap{padding:16px 16px;}
.newListWrap.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .selectRowWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;position:relative;border:1px solid #C1C1C1;border-radius:5px;}
.newListWrap.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .selectRowWrap .icoWrap{position:absolute;left:10px;top:50%;transform:translateY(-50%);}
.newListWrap.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .selectRowWrap .dropdown .dropdown-toggle{background-color:transparent;color:#515151;font-weight:500;font-size:1.26rem;padding:6px 26px 6px 38px;}
.newListWrap.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .selectRowWrap .dropdown .dropdown-toggle::after{background-image:url(../img/vectorDown.png);background-repeat:no-repeat;border:0;width:10px;height:10px;background-size:contain;top:14px;position:absolute;right:8px;}
.newListWrap.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .selectRowWrap .dropdown .dropdown-menu.show{display:block;padding:10px 10px 0;border:0;border-radius:5px;box-shadow:0px 4px 6.2px 0px #00000024;min-width:162px;left:-22px!important;}
.newListWrap.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .selectRowWrap .dropdown .insertRowWrap .insertRow{display:flex;flex-flow:row wrap;align-items:center;justify-content:center;} 
.newListWrap.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .selectRowWrap .dropdown .insertRowWrap .insertRow span{color:#000000;font-size:1rem;font-weight:400;}
.newListWrap.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .selectRowWrap .dropdown .insertRowWrap .insertRow input{outline:0;box-shadow:none;border:1px solid #C1C1C1;color:#000000;font-weight:400;font-size:1.2rem;border-radius:3px;padding:2px 6px;width:4.2rem;height:22px;margin:0 0.4rem;}   
.newListWrap.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .selectRowWrap .dropdown .insertRowWrap p{margin:0;color:#9D9D9D;font-size:0.7rem;text-align:right;line-height:12px;}
.newListWrap.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .selectRowWrap .dropdown .inserCta{text-align:center; margin:6px 0 0;} 
.newListWrap.newContactListWrap .rightPannelWrap .topWrap .rightActionWrap .selectRowWrap .dropdown .inserCta .btn{border:0;border-top:0.5px solid #C1C1C1;width:100%;padding:8px 10px;outline:0;box-shadow:none;border-radius:0;color:#1944F1;font-size:1.2rem;font-weight:400;}  
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap{height:calc(100vh - 200px);}
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr:nth-child(1) td{padding:14px 10px 8px!important;}
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr th,
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr td{border:0;padding:8px 10px;}
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr th:nth-child(1),
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr td:nth-child(1){text-align:center;}
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr th:last-child,
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr td:last-child{text-align:center;width:96px;}
/* .newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tbody tr:nth-child(1) td{padding:12px 8px} */
/* .newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr td:nth-child(1),
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr th:nth-child(1),
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr td:nth-child(10),
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr th:nth-child(10){text-align:center;}
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr td:nth-child(9),
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr th:nth-child(9){text-align:left;} */
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table tr td .btnAddRow{background-color:#1944F1;color:#ffffff;border-radius:5px;font-size:1.4rem;font-weight:500;outline:0;border:0;box-shadow:none;padding:4px 16px;height:34px;position:fixed;bottom:20px;right:110px;}
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table .widGender{width:9.6rem;}
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table .widAge{width:8.4rem;}
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table .widCSC{width:12rem;}
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table input{width:100%;border-radius:5px;border:1px solid #C1C1C1;height:28px;color:#000000;font-size:1.2rem;font-weight:400;padding:2px 6px;}
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table input[type=checkbox]{width:16px;height:16px;
border:1px solid #B3B3B3;border-radius:5px;}
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table .ctaWrap{text-align:center;}
.newListWrap.newContactListWrap .rightPannelWrap .tblWrap table .ctaWrap .btn{padding:0 6px;outline:0;border:0;border-radius:0;box-shadow:none;background-color:transparent;}
.newListWrap.newContactListWrap .rightPannelWrap .ctaSave{position:absolute;bottom:10px;right:10px;}
.newListWrap.newContactListWrap .rightPannelWrap .ctaSave .btn{background-color:#1944F1;color:#ffffff;border-radius:5px;font-size:1.4rem;font-weight:500;outline:0;border:0;box-shadow:none;padding:6px 22px;height:34px;}


body.modal-open .content.newContactListPage{filter:blur(5px);transition:filter 0.3s ease-in-out;}
.addNewFieldsPopup.modal.show .modal-dialog{max-width:580px;display:flex;align-items:center;justify-content:center;height:100%;}
.addNewFieldsPopup .modal-content{border:0;border-radius:10px;padding:0 40px 40px;}
.addNewFieldsPopup .modal-header{border:0;text-align:center;padding:20px;}
.addNewFieldsPopup .modal-header .modal-title{width:100%;color:#000000;font-weight:600;font-size:2.2rem;}
.addNewFieldsPopup .modal-header .btn-close{display:none;}
.addNewFieldsPopup .modal-body{padding:0;}
.addNewFieldsPopup .modal-body ul.formList{min-height:380px;}
.addNewFieldsPopup .modal-body ul.formList li{margin:0 0 16px;}
.addNewFieldsPopup .modal-body ul.formList li .inputGroup{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.addNewFieldsPopup .modal-body ul.formList li .inputGroup input,
.addNewFieldsPopup .modal-body ul.formList li .inputGroup select{border:1px solid #C1C1C1;border-radius:5px;height:32px;outline:none;box-shadow:none;color:#000000;font-size:1.2rem;font-weight:400;width:31.80%;padding:2px 8px;}
.addNewFieldsPopup .modal-body ul.formList li .inputGroup.half input,
.addNewFieldsPopup .modal-body ul.formList li .inputGroup.half select{width:48.60%;}
.addNewFieldsPopup .modal-body ul.formList li .inputGroup input::placeholder{color:#C1C1C1;font-size:1.2rem;}
.addNewFieldsPopup .modal-body ul.formList h4{text-align:center;margin:0 auto;font-size:1.4rem;color:#a7a7a7;letter-spacing:0.2px;}
.addNewFieldsPopup .modal-body ul.formList li .ctaWrap{margin:0 auto;}
.addNewFieldsPopup .modal-body ul.formList li .ctaWrap .btn{outline:0;box-shadow:none;background-color:#F6F6F6;border:1px solid #C1C1C1;border-radius:5px;color:#C9C9C9;font-size:2.6rem;font-weight:400;text-align:center;width:100%;padding:4px 20px;line-height:26px;}
.addNewFieldsPopup .modal-body ul.formList.confDyVarList li .inputGroup label{font-size:1.2rem;font-weight:500;margin:0;width:90px;}
.addNewFieldsPopup .modal-body ul.formList.confDyVarList li .inputGroup .inputField{width:calc(100% - 308px);position:relative;}
.addNewFieldsPopup .modal-body ul.formList.confDyVarList li .inputGroup .inputField input,
.addNewFieldsPopup .modal-body ul.formList.confDyVarList li .inputGroup .inputField select{border:1px solid #C1C1C1;border-radius:5px;height:32px;outline:none;box-shadow:none;color:#000000;font-size:1.2rem;font-weight:400;width:31.80%;padding:2px 8px;width:100%;}
.addNewFieldsPopup .modal-body ul.formList.confDyVarList li .inputGroup .inputField .error{color:#ff0000;line-height:10px;font-size:0.8rem;font-weight:500;letter-spacing:0.1px;padding:0;display:inline-block;text-align:left;width:100%;position:absolute;left:2px;bottom:-4px;}
.addNewFieldsPopup .modal-body .addCtaWrap{text-align:center;margin:0 auto;}
.addNewFieldsPopup .modal-body .addCtaWrap .btn{background-color:#1944F1;border-radius:5px;color:#ffffff;font-weight:500;font-size:1.4rem;border:0;padding:6px 20px;box-shadow:none;outline:0;}
.addNewFieldsPopup.addedNewFieldsPopup .modal-content{padding:2rem;}
.addNewFieldsPopup.addedNewFieldsPopup .modal-body{text-align:center;}
.addNewFieldsPopup.addedNewFieldsPopup .modal-body h4{margin:0 auto 1.8rem;font-size:1.6rem;font-weight:400;color:#212121;}
.addNewFieldsPopup.addedNewFieldsPopup .modal-body .addCtaWrap .btn{padding:0.6rem 3.6rem;}


.content.billingPayPage{padding:60px 10px 0 90px;}
.billingPayWrap{width:100%;border-radius:10px;background-color:#ffffff;height:calc(100vh - 70px);position:relative;}
.billingPayWrap .pad0{padding:0;}
.billingPayWrap ul.nav-tabs{border-bottom:1px solid #DADADA;margin:0;padding:8px 16px;}
.billingPayWrap ul.nav-tabs li{margin:0 30px 0 0;}
.billingPayWrap ul.nav-tabs li:last-child{margin:0;}
.billingPayWrap ul.nav-tabs li .nav-link{border:0;margin:0;background-color:transparent;}
.billingPayWrap ul.nav-tabs li .nav-link .tab-title{color:#8A8A8A;font-size:1.6rem;font-weight:600;display:flex;align-items:center;justify-content:flex-start;position:relative;}
.billingPayWrap ul.nav-tabs li .nav-link .tab-title:after{content:"";background-image:url(../img/vectorArrow-1.png);background-repeat:no-repeat;background-size:contain;width:36px;height:10px;position:absolute;right:-44px;top:50%;transform:translateY(-50%);}
.billingPayWrap ul.nav-tabs li .nav-link .tab-title.noContent:after{content:none;}
.billingPayWrap ul.nav-tabs li .nav-link .tab-title.completed:after{background-image:url(../img/vectorArrow-2.png);}
.billingPayWrap ul.nav-tabs li .nav-link .tab-title span.circle{display:flex;align-items:center;justify-content:center;width:32px;height:32px;border-radius:100%;border:1px solid #BBBBBB;background-color:#D9D9D9;color:#8A8A8A;margin:0 12px 0 0;}
.billingPayWrap ul.nav-tabs li .nav-link.active .tab-title,
.billingPayWrap ul.nav-tabs li .nav-link .tab-title.completed{color:#000000;}
.billingPayWrap ul.nav-tabs li .nav-link.active .tab-title span.circle,
.billingPayWrap ul.nav-tabs li .nav-link .tab-title.completed span.circle{border:1px solid #1944F1;background-color:#E1E7FD;color:#1944F1;}
.billingPayWrap .tab-content{padding:30px 40px;}
.billingPayWrap .tab-content .planDtlTblWrap{border:1px solid #C1C1C1;border-radius:10px;overflow:hidden;}
.billingPayWrap .tab-content .scrollContentWrap{height:calc(100vh - 240px);}
.billingPayWrap .tab-content .scrollContentWrap .rcs-custom-scroll .kpmZQS{background:linear-gradient(180deg, #e1e1e1 0%, #d9d9d9 100%);}
.billingPayWrap .tab-content .planDtlTblWrap table{width:100%;}
.billingPayWrap .tab-content .planDtlTblWrap table tr th,
.billingPayWrap .tab-content .planDtlTblWrap table tr td{width:25%;text-align:center;padding:8px 12px;}
.billingPayWrap .tab-content .planDtlTblWrap table tr th:nth-child(3),
.billingPayWrap .tab-content .planDtlTblWrap table tr td:nth-child(3){width:50%;text-align:left;}
.billingPayWrap .tab-content .planDtlTblWrap table tr th{color:#8D8D8D;font-weight:600;font-size:1.4rem;border-bottom:1px solid #C1C1C1;}
.billingPayWrap .tab-content .planDtlTblWrap table tr th:nth-child(3){color:#000000;}
.billingPayWrap .tab-content .planDtlTblWrap table tr td{color:#000000;font-weight:400;font-size:1.4rem;}
.billingPayWrap .tab-content .planDtlTblWrap table tr td svg{margin:0 6px 0 0;}
.billingPayWrap .tab-content .planDtlTblWrap table tr td span{color:#777777;font-size:1.2rem;letter-spacing:0.2px;display:inline-block;margin:0 0 0 20px;}
.billingPayWrap .tab-content .planDtlTblWrap table tr:nth-child(even){background-color:#F4F6FF;}
.billingPayWrap .tab-content .planDtlTblWrap table tr:nth-child(odd){background-color:#ffffff;}
.billingPayWrap .tab-content .ctaNextWrap{position:absolute;right:40px;bottom:16px;}
.billingPayWrap .tab-content .ctaNextWrap .btn{background-color:#1944F1;color:#ffffff;font-size:1.4rem;font-weight:500;border:0;
outline:0;box-shadow:none;border-radius:4px;padding:6px 22px;}
.billingPayWrap .tab-content .paymentPlanWrap .billingDtlTbl{border:1px solid #C1C1C1;border-radius:10px;}
.billingPayWrap .tab-content .paymentPlanWrap .billingDtlTbl table{width:100%;}
.billingPayWrap .tab-content .paymentPlanWrap .billingDtlTbl table tr th{border-bottom:1px solid #C1C1C1;padding:10px 16px;color:#8D8D8D;font-size:1.8rem;font-weight:600;}
.billingPayWrap .tab-content .paymentPlanWrap .billingDtlTbl table tr td{padding:16px 16px;}
.billingPayWrap .tab-content .paymentPlanWrap .billingDtlTbl table tr td h4{color:#000000;margin:0 0 8px;font-size:1.6rem;font-weight:400;display:flex;align-items:center;flex-flow:row wrap;}
.billingPayWrap .tab-content .paymentPlanWrap .billingDtlTbl table tr td h4 svg{margin:0 0 0 8px;width:14px;height:14px;}
.billingPayWrap .tab-content .paymentPlanWrap .billingDtlTbl table tr td h2{font-size:2.2rem;color:#000000;font-weight:600;margin:0 0 2px;}
.billingPayWrap .tab-content .paymentPlanWrap .billingDtlTbl table tr td a{display:block;color:#1944F1;font-size:1.4rem;font-weight:400;line-height:24px;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl{padding:0 0 24px;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul{position:relative;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul:before{content:"";border-right:1px dashed #1944F1;width:1px;
height:calc(100% - 92px);position:absolute;top:64px;left:16px;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul:after{content:"";background-color:#D9D9D9;width:1px;height:90px;position:absolute;left:30%;top:60px;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li{display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:flex-start;position:relative;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li:nth-child(2){margin:16px 0 0;padding:0 0 10px;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li:nth-child(3){padding:0 0 36px;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li:nth-child(1):before{content:none;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li:before{content:"";background-color:#1944F1;border-radius:100%;width:16px;height:16px;position:absolute;left:8px;top:12px;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li.topHeader{background-color:#F4F6FF;display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li.topHeader .leftWrap,
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li.topHeader .rightWrap{padding:8px 80px;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li .leftWrap{width:40%;padding:8px 40px;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li.topHeader .leftWrap h4,
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li.topHeader .rightWrap h4{margin:0;color:#8D8D8D;font-weight:500;font-size:1.5rem;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li .rightWrap{width:60%;position:relative;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li:last-child .rightWrap:before{content:"";background-color:#D9D9D9;height:1px;width:100%;position:absolute;top:-18px;left:0;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li .leftWrap p{color:#000000;margin:0;font-size:1.6rem;font-weight:400;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li .rightWrap span{color:#000000;font-weight:400;font-size:1.4rem;} 
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li .rightWrap h6{color:#000000;font-weight:500;font-size:1.6rem;}
.billingPayWrap .tab-content .paymentPlanWrap .datePriceTbl ul li .rightWrap h6 span{color:#70D257;font-weight:500;}  
.billingPayWrap .tab-content .scrollContentWrap .topTtl{border-bottom:1px solid #E0E0E0;}
.billingPayWrap .tab-content .scrollContentWrap .topTtl h2{color:#8D8D8D;font-size:1.8rem;font-weight:600;}
.billingPayWrap .tab-content .paymentPlanWrap .payPlanList{padding:16px 0 36px;}
.billingPayWrap .tab-content .paymentPlanWrap .payPlanList ul{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.billingPayWrap .tab-content .paymentPlanWrap .payPlanList ul li{width:33.33%;}
.billingPayWrap .tab-content .paymentPlanWrap .payPlanList ul li p{color:#000000;font-size:1.4rem;font-weight:400;margin:0;display:flex;flex-flow:row wrap;align-items:center;}
.billingPayWrap .tab-content .paymentPlanWrap .payPlanList ul li p svg{margin:0 0 0 6px;}
.billingPayWrap .tab-content .paymentPlanWrap .payPlanList ul li h2{color:#000000;font-weight:600;font-size:2rem;margin:0;}

.billingPayWrap .tab-content .paymentTypeWrap{}
.billingPayWrap .tab-content .paymentTypeWrap .topTtl{margin:0 0 20px;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap{}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap .titleWrap{margin:0 auto 16px;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap .titleWrap h2{color:#000000;margin:0;text-align:center;font-size:1.4rem;font-weight:600;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul{}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap.optionOneWrap ul li{margin:0 0 12px;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputGroup{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputField.SelectWrap{margin:0 0 12px;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputGroup .imputField input,
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputGroup .imputField select,
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputField input,
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputField select{border:1px solid #C1C1C1;border-radius:5px;height:38px;width:100%;padding:2px 10px;color:#000000;font-size:1.3rem;font-weight:400;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputField input::placeholder{color:#ADADAD;font-size:1.2rem;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputField label{color:#000000;font-weight:500;font-size:1.2rem;margin:0;line-height:14px;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputGroup .imputField.wid1{width:calc(100% - 194px);position:relative;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputGroup .imputField.wid2{width:80px;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputGroup .imputField.wid3{width:100px;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputGroup .imputField.half{width:48.60%;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputGroup .imputField .inputImg{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputField .inputImg img{width:20px;position:absolute;left:10px;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputField .css-1dimb5e-singleValue img{width:40px;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputField .css-t3ipsp-control:focus,
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputField .css-t3ipsp-control:hover{outline:0;border-color:#C1C1C1!important;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputGroup .imputField.wid1 input{padding:2px 10px 2px 34px;}
.billingPayWrap .tab-content .paymentTypeWrap .optionOneWrap{padding:0 30px 0 0;} 
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputField .css-1fdsijx-ValueContainer{height:38px;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputField .css-1fdsijx-ValueContainer input{height:100%;}
.billingPayWrap .tab-content .paymentTypeWrap .optionWrap ul li .imputField .css-1nmdiq5-menu img{width:40px;} 
.billingPayWrap .tab-content .paymentTypeWrap .optionTwoWrap{padding:0 0 0 60px;}
.billingPayWrap .tab-content .paymentTypeWrap .cardDtlsWrap{border-radius:10px;border:1px solid #C1C1C1;padding:20px 100px 20px 20px;display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-between;}
.billingPayWrap .tab-content .paymentTypeWrap .cardDtlsWrap .leftTxtWrap{width:180px;}
.billingPayWrap .tab-content .paymentTypeWrap .cardDtlsWrap .leftTxtWrap h3{color:#000000;font-size:1.4rem;font-weight:600;}
.billingPayWrap .tab-content .paymentTypeWrap .cardDtlsWrap .leftTxtWrap p{margin:0;color:#000000;font-size:1rem;font-weight:400;line-height:12px;}
.billingPayWrap .tab-content .paymentTypeWrap .cardDtlsWrap .rightWrap{width:calc(100% - 180px);}
.billingPayWrap .tab-content .paymentTypeWrap .optionTwoWrap .payModeWrap .upiImgWrap{width:40px;} 
.billingPayWrap .tab-content .paymentTypeWrap .optionTwoWrap .payModeWrap .upiImgWrap img{width:100%;}
.billingPayWrap .tab-content .paymentTypeWrap .optionTwoWrap .payModeWrap .qrImgWrap{width:140px;margin:30px auto 0;text-align:center;}
.billingPayWrap .tab-content .paymentTypeWrap .optionTwoWrap .payModeWrap .qrImgWrap img{width:100%;}
.billingPayWrap .tab-content .paymentTypeWrap .masterCardWrap{margin:30px 0 0;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;}
.billingPayWrap .tab-content .custom-radio{display:flex;align-items:center;cursor:pointer;user-select:none;}
.billingPayWrap .tab-content .hidden-radio{display:none;}
.billingPayWrap .tab-content .custom-radio-button{width:26px;height:26px;border:1px solid #C1C1C1;border-radius:100%;display:inline-block;margin-right:8px;position:relative;}
.billingPayWrap .tab-content .custom-radio.checked .custom-radio-button::after{content:'';width:14px;height:14px;background-color:#1944F1;border-radius:100%;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}

.billingPayWrap .tab-content .paymentTypeWrap .masterCardWrap .rightWrap{border:1px solid #C1C1C1;border-radius:5px;width:calc(100% - 42px);padding:16px;display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:flex-start;}
.billingPayWrap .tab-content .paymentTypeWrap .masterCardWrap .rightWrap .imgWrap{width:34px;}
.billingPayWrap .tab-content .paymentTypeWrap .masterCardWrap .rightWrap .imgWrap img{width:100%;}
.billingPayWrap .tab-content .paymentTypeWrap .masterCardWrap .rightWrap .dataWrap{margin:0 0 0 12px;position:relative;width:calc(100% - 50px);}
.billingPayWrap .tab-content .paymentTypeWrap .masterCardWrap .rightWrap .dataWrap h2{margin:0 0 5px;color:#2C2C2C;font-size:1.5rem;font-weight:500;}
.billingPayWrap .tab-content .paymentTypeWrap .masterCardWrap .rightWrap .dataWrap h4{font-size:1.4rem;color:#2C2C2C;margin:0;}
.billingPayWrap .tab-content .paymentTypeWrap .masterCardWrap .rightWrap .dataWrap p{color:#696969;font-size:1.3rem;}
.billingPayWrap .tab-content .paymentTypeWrap .masterCardWrap .rightWrap .dataWrap span{color:#696969;font-size:1.3rem;margin:0 0 6px;}
.billingPayWrap .tab-content .paymentTypeWrap .masterCardWrap .rightWrap .dataWrap span svg{margin:0 6px 0 0;}
.billingPayWrap .tab-content .paymentTypeWrap .masterCardWrap .rightWrap .dataWrap .btnChangeWrap{position:absolute; right:0;top:0;}
.billingPayWrap .tab-content .paymentTypeWrap .masterCardWrap .rightWrap .dataWrap .btnChangeWrap .btn{color:#696969;border:1px solid #ECECEC;border-radius:5px;box-shadow:none;outline:none;font-size:1.4rem;font-weight:500;padding:8px 16px;}

.contactListFields ul.formList li .inputGroupWrapper{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.contactListFields ul.formList li .inputGroupWrapper label{color:#000000;margin:0 6px 0 0;font-size:1.2rem;font-weight:400;line-height:14px;width:100px;}
.contactListFields ul.formList li .inputGroupWrapper .inputWrap{width:calc(100% - 110px);display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.contactListFields ul.formList li .inputGroupWrapper .inputWrap .inputField.half input,
.contactListFields ul.formList li .inputGroupWrapper .inputWrap .inputField.half select{width:100%;}
.contactListFields ul.formList li .inputGroupWrapper .inputWrap .inputField.half{width:48%;}
.contactListFields ul.formList li .inputGroup{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;position:relative;}
.contactListFields ul.formList li .inputGroup .inputField.third{width:30%;}
.contactListFields ul.formList li .inputGroup .inputField.third input,
.contactListFields ul.formList li .inputGroup .inputField.third select,
.contactListFields ul.formList li .inputGroup .inputField.third>div{width:100%;}
.contactListFields ul.formList li .inputGroup .inputField.third>div .css-qbdosj-Input{margin:0;padding:0;}
.contactListFields ul.formList li .inputGroup .inputField.third>div .css-13cymwt-control{border:1px solid #C1C1C1;border-radius:5px;min-height:34px;}
.contactListFields ul.formList li .inputGroup .inputField.third select{background-position:right 3px center!important;background-size:9px;}
.contactListFields ul.formList li .inputGroup .inputField.third>div .css-1fdsijx-ValueContainer{padding:0 8px;}
.contactListFields ul.formList li .inputGroup .inputField.third>div .css-1nmdiq5-menu{padding:0 1.2rem;margin:0;font-size:1.3rem;}
.contactListFields ul.formList li .inputGroup .btnDelete{padding:0;border:0;outline:0;box-shadow:none;background-color:transparent;}
.content.msgTempListPage{padding:60px 10px 0 10px;}
.msgTempListWrap{width:100%;border-radius:10px;background-color:#ffffff;padding:10px 0 16px;height:calc(100vh - 70px);position:relative;}
.msgTempListWrap .topDataWrap{padding:0 10px 12px;border-bottom:1px solid #D3D3D3;}
.msgTempListWrap .topDataWrap .titleWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;}
.msgTempListWrap .topDataWrap .titleWrap .icoWrap{margin:0 1rem 0 0;}
.msgTempListWrap .topDataWrap .titleWrap .icoWrap svg{width:3.2rem;height:3.2rem;}
.msgTempListWrap .topDataWrap .titleWrap h2{color:#000000;margin:0;font-size:2rem;font-weight:600;}
.msgTempListWrap .topDataWrap .rightWrap{text-align:right;}
.msgTempListWrap .topDataWrap .rightWrap .btn{background-color:#1944F1;color:#ffffff;border:1px solid #1944F1;border-radius:5px;height:40px;margin:0 0 0 1.2rem;font-size:1.4rem;font-weight:500;padding:0.6rem 1.6rem;}
.msgTempListWrap .topDataWrap .rightWrap .btn.btnWithIco{background-color:transparent;color:#515151;border:1px solid #BBBBBB;}
.msgTempListWrap .topDataWrap .rightWrap .btn.btnWithIco svg{width:18px;height:18px;margin:0 1rem 0 0;}
.msgTempListWrap .topFilterWrap{text-align:right;padding:14px 12px 16px 6px;}
.msgTempListWrap .topFilterWrap ul{margin:0;display:flex;flex-flow:row wrap;justify-content:flex-end;align-items:center;}
.msgTempListWrap .topFilterWrap ul li{margin:0 0 0 1.4rem;}
.msgTempListWrap .topFilterWrap ul li .serachField input,
.msgTempListWrap .topFilterWrap ul li .selectWrap select{outline:0;box-shadow:none;border-radius:5px;border:0.05rem solid #BBBBBB;width:100%;height:36px;color:#515151;font-weight:500;font-size:1.2rem;padding:2px 10px}
.msgTempListWrap .topFilterWrap ul li .selectWrap{width:180px;}
.msgTempListWrap .topFilterWrap ul li .serachField{position:relative;width:260px;}
.msgTempListWrap .topFilterWrap ul li .serachField svg{position:absolute;top:50%;transform:translateY(-50%);width:16px;height:16px;left:10px;}
.msgTempListWrap .topFilterWrap ul li .serachField input{padding:2px 10px 2px 34px;} 
.msgTempListWrap .msgTempListTbl{height:calc(100vh - 204px);padding:0 16px;}
.msgTempListWrap .msgTempListTbl .rcs-inner-container{padding:4px 4px 0;}
.msgTempListWrap .msgTempListTbl table{width:100%;}
.msgTempListWrap .msgTempListTbl table thead{box-shadow:0px 3px 7.5px 0px #00000033;position:relative;border-radius:6px;}
.msgTempListWrap .msgTempListTbl table tr th{color:#000000;font-size:1.3rem;font-weight:500;padding:10px 12px;text-align:left;}
.msgTempListWrap .msgTempListTbl table tr td{padding:10px 12px 10px;text-align:center;color:#000000;font-size:1.2rem;font-weight:400;border-bottom:0.5px solid #0000004D;text-align:left;line-height:18px;}
.msgTempListWrap .msgTempListTbl table tr:nth-child(1) td{padding:14px 12px 8px;}
.msgTempListWrap .msgTempListTbl table tr td span{display:block;line-height:16px;color:#515151;font-size:1rem;}
.msgTempListWrap .msgTempListTbl table tr td .statusWrap{position:relative;border-radius:30px;width:90px;height:32px;margin:0 auto;padding:0 0 0 10px;align-items:center;display:flex;justify-content:center;}
.msgTempListWrap .msgTempListTbl table tr td .statusWrap:before{content:"";width:8px;height:8px;border-radius:100%;position:absolute;left:10px;top:50%;transform:translateY(-50%);}
.msgTempListWrap .msgTempListTbl table tr td .statusWrap p{margin:0;font-weight:500;font-size:1.2rem;}
.msgTempListWrap .msgTempListTbl table tr td .statusWrap.statusActive{background-color:#C0F9B0;border:0.5px solid #70D257;}
.msgTempListWrap .msgTempListTbl table tr td .statusWrap.statusActive:before{background-color:#70D257;}
.msgTempListWrap .msgTempListTbl table tr td .statusWrap.statusActive p{color:#70D257;}
.msgTempListWrap .msgTempListTbl table tr td .statusWrap.statusPending{background-color:#FFF8BC;border:0.5px solid #DEC804;}
.msgTempListWrap .msgTempListTbl table tr td .statusWrap.statusPending:before{background-color:#DEC804;}
.msgTempListWrap .msgTempListTbl table tr td .statusWrap.statusPending p{color:#DEC804;}
.msgTempListWrap .msgTempListTbl table tr td .statusWrap.statusDecline{background-color:#FDE4E4;border:0.5px solid #FF0A0A;}
.msgTempListWrap .msgTempListTbl table tr td .statusWrap.statusDecline:before{background-color:#FF0A0A;}
.msgTempListWrap .msgTempListTbl table tr td .statusWrap.statusDecline p{color:#FF0A0A;}
.msgTempListWrap .msgTempListTbl table tbody tr:hover{background-color:#e9eeff;cursor:pointer;}
.msgTempListWrap .msgTempListTbl table tr td .text-container{display:block;position:relative;}
.msgTempListWrap .msgTempListTbl table tr td .truncated-text{position:relative;cursor:pointer;display:inline-block;}
.msgTempListWrap .msgTempListTbl table tr td .popup{display:none;position:absolute;background-color:white;border:1px solid #e1e1e1;padding:0.6rem;box-shadow:0px 4px 4px 0px #00000036;z-index:1;white-space:pre-wrap;max-width:300px;word-break:break-word;top:0;left:0;margin-top:0;border-radius:5px;font-size:0.9rem;letter-spacing:0.1px;line-height:1.2rem;}
.msgTempListWrap .msgTempListTbl table tr td .truncated-text:hover .popup{display:block;}


.content.createTemplatePage{padding:60px 10px 0 10px;}
.createTemplateWrap{width:100%;border-radius:10px;background-color:#ffffff;}
.createTemplateWrap .padRt0{padding-right:0;}
.createTemplateWrap .leftContentWrap .titleWrap{display:flex;justify-content:flex-start;align-items:center;border-bottom:1px solid #D3D3D3;padding:10px 10px 10px 10px;}
.createTemplateWrap .leftContentWrap .titleWrap .icoWrap{margin:0 8px 0 0;}
.createTemplateWrap .leftContentWrap .titleWrap h2{font-size:1.8rem;color:#000000;font-weight:600;margin:0;}
.createTemplateWrap .leftContentWrap .scrollContentWrap{height:calc(100vh - 146px);}
.createTemplateWrap .leftContentWrap .scrollContentWrap .rcs-custom-scroll .kpmZQS{background:linear-gradient(180deg, #D9D9D9 0%, #D9D9D9 100%)!important;}
.createTemplateWrap .leftContentWrap{position:relative;}
.createTemplateWrap .leftContentWrap .tabsWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;height:calc(100vh - 178px);}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap{border:0;margin:0;padding:0;position:absolute;top:10px;right:6px;}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li{margin:0 28px 0 0;}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li:last-child{margin:0;}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link{border:0;margin:0;background-color:transparent;padding:0 10px;}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link .tab-title{color:#8A8A8A;font-size:1.4rem;font-weight:600;display:flex;align-items:center;justify-content:flex-start;position:relative;}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link .tab-title:after{content:"";background-image:url(../img/vectorArrow-1.png);background-repeat:no-repeat;background-size:contain;width:36px;height:10px;position:absolute;right:-44px;top:50%;transform:translateY(-50%);}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link .tab-title.noContent:after{content:none;}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link .tab-title.completed:after{background-image:url(../img/vectorArrow-2.png);}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link .tab-title span.circle{display:flex;align-items:center;justify-content:center;width:28px;height:28px;border-radius:100%;border:1px solid #BBBBBB;background-color:#D9D9D9;color:#8A8A8A;margin:0 6px 0 0;}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link.active .tab-title,
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link .tab-title.completed{color:#000000;}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link.active .tab-title span.circle,
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link.active .tab-title.completed span.circle{border:1px solid #1944F1;background-color:#E1E7FD;color:#1944F1;}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link.active .tab-title.completed span.circle:after{content:none;}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link .tab-title.completed span.circle{border:1px solid #31DB22;background-color:#B3FFAC;position:relative;color:transparent;}
.createTemplateWrap .leftContentWrap .tabsWrap ul.nav-tabs.topTabsWrap li .nav-link .tab-title.completed span.circle:after{content:"";background-image:url(../img/Done-1.png);background-repeat:no-repeat;background-size:contain;background-position:center;width:20px;height:10px;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);}
.createTemplateWrap .leftContentWrap .tabsWrap .tab-content{width:100%;}
.createTemplateWrap .setUpTempPanel{padding:16px;}
.createTemplateWrap .setUpTempWrap{border:1px solid #BBBBBB;border-radius:10px;padding:18px;}
.createTemplateWrap .setUpTempWrap .topTtlWrap{text-align:left;margin:0 0 10px;}
.createTemplateWrap .setUpTempWrap .topTtlWrap h2{color:#000000;font-weight:600;font-size:1.5rem;margin:0;}
.createTemplateWrap .setUpTempWrap .topTtlWrap p{margin:0;color:#000000;font-size:1.1rem;font-weight:400;line-height:22px;}
.createTemplateWrap .setUpTempWrap .topTtlWrap p a{color:#1944F1;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap ul.nav-tabs{border:0;margin:0;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap ul.nav-tabs li{width:33.33%;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap ul.nav-tabs li .nav-link{width:82%;border:0;margin:0;border-radius:0;text-align:center;color:#000000;font-size:1.4rem;font-weight:500;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap ul.nav-tabs li .nav-link .tabTtl{display:flex;flex-flow:row wrap;align-items:center;justify-content:center;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap ul.nav-tabs li .nav-link .tabTtl svg{width:14px;height:14px;margin:0 6px 0 0;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap ul.nav-tabs li .nav-link .tabTtl p{margin:0;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap ul.nav-tabs li .nav-link.active{background-color:#F4F6FF;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap .radioGroupWrap{margin:14px 0 0;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap .radioGroupWrap .radioWrap{margin:0 0 6px;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap .radioGroupWrap .custom-radio{display:flex;align-items:center;cursor:pointer;user-select:none;padding:12px;border:0.5px solid transparent;border-radius:10px;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap .radioGroupWrap .hidden-radio{display:none;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap .radioGroupWrap .custom-radio-button{width:24px;height:24px;border:1px solid #AEAEAE;border-radius:100%;display:inline-block;margin-right:12px;position:relative;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap .radioGroupWrap .custom-radio.checked .custom-radio-button::after{content:'';width:16px;height:16px;background-color:#1944F1;border-radius:100%;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.createTemplateWrap .setUpTempWrap .setupTabsWrap .radioGroupWrap .custom-radio.checked{border:0.5px solid #1944F1}
.createTemplateWrap .setUpTempWrap .setupTabsWrap .radioGroupWrap .customRadioLabel h4{color:#000000;font-weight:500;font-size:1.4rem;margin:0;}
.createTemplateWrap .setUpTempWrap .setupTabsWrap .radioGroupWrap .customRadioLabel p{margin:0;color:#000000;font-size:1.1rem;font-weight:400;line-height:18px;}

.createTemplateWrap .btnCtasWrap{display:flex;align-items:center;justify-content:space-between;padding:6px 16px 16px;}
.createTemplateWrap .btnCtasWrap .btn{padding:0;outline:0;border:0;border-radius:0;box-shadow:none;background-color:transparent;font-size:1.4rem;font-weight:500;}
.createTemplateWrap .btnCtasWrap .leftCta .btn{color:#9F9F9F;}
.createTemplateWrap .btnCtasWrap .rightCta .btn{color:#1944F1;}
.createTemplateWrap .btnCtasWrap .rightCta .btnWithBg{background-color:#1944F1;color:#ffffff;font-size:1.4rem;font-weight:500;box-shadow:0px 4px 4px 0px #00000036;border-radius:5px;border:0;margin:0 0 0 20px;padding:6px 24px;}

.createTemplateWrap .editTempPannel{width:100%;padding:22px 20px;}
.createTemplateWrap .editTempPannel .tempttlWrap{background-color:#F4F6FF;border-radius:10px;display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;padding:12px 16px;}
.createTemplateWrap .editTempPannel .tempttlWrap .icoWrap{margin:0 14px 0 0;}
.createTemplateWrap .editTempPannel .tempttlWrap .icoWrap svg{width:30px;}
.createTemplateWrap .editTempPannel .tempttlWrap .ttlWrap h2{margin:0 0 6px;color:#000000;font-size:1.6rem;font-weight:600;}
.createTemplateWrap .editTempPannel .tempttlWrap .ttlWrap ul{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;margin:0;}
.createTemplateWrap .editTempPannel .tempttlWrap .ttlWrap ul li{color:#919191;font-size:1rem;font-weight:400;line-height:12px;position: relative;padding:0 36px 0 0;}
.createTemplateWrap .editTempPannel .tempttlWrap .ttlWrap ul li:after{content:"";background-image:url(../img/vectorArrow-3.png);background-repeat:no-repeat;background-size:contain;position:absolute;width:30px;height:8px;right:-7px;top:2px;}
.createTemplateWrap .editTempPannel .tempttlWrap .ttlWrap ul li.noContent:after{content:none;}
.createTemplateWrap .editTempPannel .tempNameLangWrap{border:1px solid #EAEAEA;border-radius:10px;margin:14px 0 0;padding:16px 16px 20px;}
.createTemplateWrap .editTempPannel .tempNameLangWrap h2{font-size:1.6rem;font-weight:600;margin:0 0 8px;}
.createTemplateWrap .editTempPannel .tempNameLangWrap ul{margin:0;display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-between;}
.createTemplateWrap .editTempPannel .tempNameLangWrap ul li:nth-child(1){width:64%;}
.createTemplateWrap .editTempPannel .tempNameLangWrap ul li:nth-child(2){width:35%;}
.createTemplateWrap .editTempPannel .tempNameLangWrap ul li .inputField label{font-size:1.2rem;font-weight:400;color:#000000;}
.createTemplateWrap .editTempPannel .tempNameLangWrap ul li .inputField .inputWrap{width:100%;position:relative;}
.createTemplateWrap .editTempPannel .tempNameLangWrap ul li .inputField input,
.createTemplateWrap .editTempPannel .tempNameLangWrap ul li .inputField select{width:100%;padding:2px 10px;height:34px;border:1px solid #C1C1C1;border-radius:5px;color:#000000;font-weight:400;outline:0;box-shadow:none;font-size:1.2rem;}
.createTemplateWrap .editTempPannel .tempNameLangWrap ul li .inputField input::placeholder{color:#D1D1D1;font-size:1.2rem;}
.createTemplateWrap .editTempPannel .tempNameLangWrap ul li .inputField span{position:absolute;right:10px;top:50%;transform:translateY(-50%);color:#D1D1D1;font-size:1.2rem;font-weight:400;}
.createTemplateWrap .editTempPannel .tempContentWrap{border:1px solid #EAEAEA;border-radius:10px;margin:14px 0 0;padding:16px 16px 20px;}
.createTemplateWrap .editTempPannel .tempContentWrap .topTtl{margin:0 0 12px;}
.createTemplateWrap .editTempPannel .tempContentWrap .topTtl h2{font-size:1.6rem;font-weight:600;margin:0;}
.createTemplateWrap .editTempPannel .tempContentWrap .topTtl p{color:#919191;font-weight:400;margin:0;font-size:1rem;line-height:18px;}
.createTemplateWrap .editTempPannel .editTempContentWrap{display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-between;}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap{display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-between;width:260px;padding:0 30px 0 0;position:relative;}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap:after{content:"";width:1px;background-color:#515151;height:230px;position:absolute;right:24px;top:16px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap h4{color:#000000;font-weight:400;font-size:1.2rem;margin:0 0 10px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap h4 span{position:relative;display:inline-block;color:#919191;font-size:1rem;padding:0 0 0 8px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap h4 span:before{content:"";height:0.5px;width:6px;background-color:#919191;position:absolute;left:-1px;top:50%;transform:translateY(-50%);}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap ul{margin:0;display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:flex-start;}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap ul li{width:50%;padding:0 16px 16px 0;}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap ul li .btn{border:1px solid #E1E1E1;border-radius:10px;background-color:#F4F4F4;box-shadow:none;outline:0;width:100%;padding:16px 12px;text-align:center;}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap ul li .btn .icoWrap{margin:0 auto 10px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap ul li .btn .icoWrap svg{width:30px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap ul li .btn .dataWrap h2{color:#C7C7C7;font-size:1.2rem;font-weight:400;margin:0;margin:0;} 
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap ul li .btn.active{border:1px solid #1944F1;background-color:#F4F6FF;}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap ul li .btn.active .icoWrap svg path{stroke:#1944F1;}
.createTemplateWrap .editTempPannel .editTempContentWrap .leftWrap ul li .btn.active .dataWrap h2{color:#000000;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap{width:calc(100% - 260px);display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-between;padding:22px 0 0;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul{}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li{margin:0 0 12px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel{width:calc(100% - 130px);position:relative;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .inputField{width:100%;position:relative;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .inputField input{border:1px solid #C1C1C1;border-radius:5px;outline:0;box-shadow:none;color:#000000;font-size:1.2rem;font-weight:400;width:100%;height:34px;padding:2px 10px;} 
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .inputField span{position:absolute;color:#D1D1D1;font-size:1rem;font-weight:400;top:50%;transform:translateY(-50%);right:10px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap{background-color:#F4F6FF;border-radius:5px;margin:12px 0 0;padding:12px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap h3,
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .bodyWrap h3{color:#000000;font-size:1.2rem;font-weight:500;margin:0 0 4px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap p{color:#919191;font-size:1rem;font-weight:400;line-height:1.6rem;letter-spacing:0.2px;margin:0 0 6px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;margin:0 0 1rem}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField p{margin:0 12px 0 0;position:relative;color:#000000;font-weight:400;font-size:1.1rem;width: 40px;}
/* .createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField p:before{content:"{{";}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField p:after{content:"}}";}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField p:before,
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField p:after{color:#000000;} */
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField input{width:calc(100% - 52px);border:0;background-color:#ffffff;font-weight:400;color:#000000;height:26px;border-radius:4px;outline:0;box-shadow:none;padding:1px 8px;font-size:1.1rem;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField input::placeholder{color:#D1D1D1;font-size:1rem;font-weight:400;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .inputField input::placeholder{color:#D1D1D1;font-size:1rem;font-weight:400;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField .uploadBox{border:1.5px dashed #1944F1;border-radius:10px;padding:12px 12px 15px;text-align:center;position:relative;width:100%;display:flex;flex-flow:row wrap;justify-content:center;align-items:center;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField .uploadBox .fileInput{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField .uploadBox .icoWrap{margin:0 16px 0 0;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField .uploadBox .uploadDataWrap{text-align:left;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField .uploadBox .uploadText{font-size:1.5rem;font-weight:400;color:#282828;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField .uploadBox .chooseLink{color:#282828;font-weight:500;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .contentField .uploadBox .uploadInfo{font-size:1.2rem;color:#676767;line-height:16px;font-weight:400;}


.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .warningWrap{background-color:#FFE9E9;border-radius:5px;padding:5px 12px;display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;margin:10px 0 0;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .warningWrap svg{margin:0 6px 0 0;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .noteWrap .warningWrap p{color:#000000;margin:0;font-weight:400;font-size:0.9rem;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .rightPannel{width:120px;display:flex;flex-flow:row wrap;justify-content:flex-end;align-items:center;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .rightPannel.martTop{margin:12px 0 0;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .rightPannel .btn{padding:0;border:0;outline:0;box-shadow:none;color:#1944F1;font-size:1.1rem;font-weight:400;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .rightPannel .btn svg{width:10px;margin:0 4px 0 0;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .rightPannel .icoWrap{margin:0 0 4px 10px;} 
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .rightPannel .icoWrap .btn{padding:0;border:0;outline:0;box-shadow:none;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .rightPannel .icoWrap svg{width:13px;} 
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .bodyWrap .textareaWrap{width:100%;position:relative;height:100px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .bodyWrap .textareaWrap textarea{width:100%;border:1px solid #F8D069;border-radius:5px;resize:none;height:100px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .bodyWrap .textareaWrap span{position:absolute;color:#D1D1D1;font-size:1rem;font-weight:400;top:10px;right:10px;display:flex;line-height:12px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .bodyWrap .textareaWrap span svg{margin:0 0 0 4px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .warningYellowWrap{background-color:#FFF2D1;border-radius:5px;padding:8px 12px;display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-between;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .warningYellowWrap .icoWrap{margin:-4px 6px 0 0;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .warningYellowWrap .icoWrap svg{width:17px;height:18px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .warningYellowWrap p{color:#919191;margin:0;font-weight:400;font-size:1rem;width:calc(100% - 38px);line-height:1.6rem;letter-spacing:0.1px;position:relative;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .warningYellowWrap p:before{content:"";width:4px;height:4px;border-radius:100%;background-color:#000000;position:absolute;left:-13px;top:6px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .toolbar{text-align:right;} 
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .toolbar .btn{padding:0;border:0;outline:0;box-shadow:none;margin:0 0 0 1.2rem;} 
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .emojiPicker{position:absolute;
top:-220px;right:150px;z-index:1000;overflow-y:auto;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .footerConWrap h4{color:#000000;font-weight: 400;font-size:1.2rem;margin:0 0 10px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .footerConWrap h4 span{position:relative;display:inline-block;color:#919191;font-size:1rem;padding:0 0 0 8px;}
.createTemplateWrap .editTempPannel .editTempContentWrap .rightWrap ul li .itemWrap .leftPannel .footerConWrap h4 span:before{content:"";
height:0.5px;width:6px;background-color:#919191;position:absolute;left:-1px;top:50%;transform:translateY(-50%);}
.createTemplateWrap .editTempPannel .createCtaWrap{border:1px solid #EAEAEA;border-radius:10px;margin:14px 0 0;padding:16px 16px 20px;}
.createTemplateWrap .editTempPannel .createCtaWrap h4{color:#000000;font-weight: 400;font-size:1.2rem;margin:0 0 2px;}
.createTemplateWrap .editTempPannel .createCtaWrap h4 span{position:relative;display:inline-block;color:#919191;font-size:1rem;padding:0 0 0 8px;}
.createTemplateWrap .editTempPannel .createCtaWrap h4 span:before{content:"";height:0.5px;width:6px;background-color:#919191;position:absolute;left:-1px;top:50%;transform:translateY(-50%);}
.createTemplateWrap .editTempPannel .createCtaWrap p{color:#919191;font-weight:400;margin:0;font-size:1rem;line-height:18px;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap{margin:1rem 0 0;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .btn{position:relative;border-radius:5px;font-size:1.2rem;font-weight:500;padding:8px 10px;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .btn svg{margin:0 6px 0 0;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .btnConfirm{background-color:#DCE3FF;border:1px solid #1944F1;color:#1944F1;margin:1.2rem 0 0;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .confirmCta .btnConfirm{width:100%;max-width:190px;text-align:left;} 
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .confirmCta .btnConfirm:after{content:"";background-image:url(../img/icoCancel-1.png);background-repeat:no-repeat;background-size:contain;width:14px;height:14px;position:absolute;right:8px;top:50%;transform:translateY(-50%);}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .addCta{margin:14px 0;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .addCta .dropdown button{text-align:center;width:100%;max-width:190px;color:#9F9F9F;background-color:#EEEEEE;border:1px solid #D4D4D4;position:relative;border-radius:5px;font-size:1.2rem;font-weight:500;padding:8px 10px;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .addCta .dropdown .dropdown-menu{padding: 1rem 0.6rem;box-shadow:-5px 0px 11.3px 0px #00000033;border:1px solid #dddddd;width:190px;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .addCta .dropdown .dropdown-menu h3{font-size:1.3rem;font-weight:500;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .addCta .dropdown .dropdown-menu h3.bdrBtm{padding:0.8rem 0 0;
border-top:1px solid #dddddd;margin:0.6rem 0 0.6rem;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .addCta .dropdown .dropdown-menu a{padding:0.4rem 0;font-size:1.1rem;color:#707070;letter-spacing:0.1px;font-weight:400;line-height:1.6rem;margin:0;white-space:normal;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .addCta .dropdown .dropdown-menu a:hover{color:#1944F1;background-color:transparent!important;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .btnGray{color:#9F9F9F;background-color:#EEEEEE;border:1px solid #D4D4D4}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .ctaGroup .btn{margin:0 10px 0 0;padding:8px 42px 8px 8px;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .btnGreen{color:#2EB83C;border:1px solid #2EB83C;background-color:#D4FFD9;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .btnGreen:after{content:"";background-image:url(../img/icoCancel-3.png);background-repeat:no-repeat;background-size:contain;width:14px;height:14px;position:absolute;right:8px;top:50%;transform:translateY(-50%);}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .ctaGroup .btnGray{margin:0;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .ctaGroup .btnGray:after{content:"";background-image:url(../img/icoCancel-2.png);background-repeat:no-repeat;background-size:contain;width:14px;height:14px;position:absolute;right:8px;top:50%;transform:translateY(-50%);}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .ctaNoteWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;margin:0.6rem 0 1.6rem;border:1px solid #C1C1C1;border-radius:5px;padding:12px;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .ctaNoteWrap .icoWrap{width:56px;position:relative;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .ctaNoteWrap .icoWrap:after{content:"";width:4px;border-radius:10px;background-color:#2EB83C;height:100%;position:absolute;top:50%;transform:translateY(-50%);right:0;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .ctaNoteWrap .dataWrap{text-align:left;width:calc(100% - 56px);padding:0 0 0 16px;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .ctaNoteWrap .dataWrap h5{color:#000000;font-size:1.2rem;font-weight:500;margin:0 0 4px;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .ctaNoteWrap .dataWrap p{font-size:1.1rem;color:#919191;font-weight:400;margin:0;line-height:14px;letter-spacing:0.1px;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .ctaNoteWrap .dataWrap p a{color:#1944F1;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap{margin:1.4rem 0;background-color:#F4F6FF;border-radius:5px;padding:1.2rem 1.6rem 1.8rem;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul{margin:0;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li{margin:0 0 1.2rem;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li:last-child{margin:0;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li .itemsWrap{display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-between;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li .inputField label{color:#000000;font-size:1rem;font-weight:500;display:block;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li .inputField input,
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li .inputField select{background-color:#ffffff;height:36px;border-radius:4px;border:0;outline:0;box-shadow:none;padding:2px 10px;color:#000000;font-size:1rem;font-weight:400;width:100%;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li .inputField input::placeholder{color:#D1D1D1;font-size:1rem;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li .inputField{position:relative;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li .inputField span{position:absolute;color:#D1D1D1;right:10px;top:38px;line-height:12px;font-size:1.1rem;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li .ctaRemove{width:30px;margin:28px 0 0 10px;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li .ctaRemove .btn{outline:0;box-shadow:none;border:0;padding:0;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul.marketingOptOutList li .inputFieldType{width:22%;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul.marketingOptOutList li .inputFieldText{width:35.60%;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul.customList li .inputFieldType{width:22%;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul.customList li .inputFieldText{width:72.20%;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul.vistWebList li .inputFieldType{width:15%;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul.vistWebList li .inputFieldText{width:31.20%;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li label{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;cursor:pointer;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li label .checkboxStyle{display:none;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li label .boxStyle{width:18px;height:18px;border:1px solid #cccccc;border-radius:3px;display:inline-block;position:relative;margin-right:8px;cursor:pointer;background-color:#ffffff;transition:background-color 0.2s ease;}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li label .checkmarkStyle{position:absolute;top:2px;left:6px;width:5px;height:10px;border:solid #1a44f1;border-width:0 2px 2px 0;transform:rotate(45deg);}
.createTemplateWrap .editTempPannel .createCtaWrap .createCtaListWrap .urlWrap ul li label span{color:#919191;font-size:1rem;font-weight:400;line-height:1.6rem;letter-spacing:0.2px;user-select:none;}


.content.chooseTemplatePage{padding:60px 10px 0 10px;}
.chooseTemplate{width:100%;border-radius:10px;background-color:#ffffff;padding:10px 0 16px;height:calc(100vh - 70px);position:relative;}
.chooseTemplate .topDataWrap{padding:0 10px 12px;border-bottom:1px solid #D3D3D3;}
.chooseTemplate .topDataWrap .titleWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;}
.chooseTemplate .topDataWrap .titleWrap .icoWrap{margin:0 1rem 0 0;}
.chooseTemplate .topDataWrap .titleWrap .icoWrap svg{width:3.2rem;height:3.2rem;}
.chooseTemplate .topDataWrap .titleWrap h2{color:#000000;margin:0;font-size:2rem;font-weight:600;}
.chooseTemplate .tempOptionWrap{height:calc(100vh - 126px);display:flex;align-items:center;justify-content:center;}
.chooseTemplate .tempOptionWrap .ttlWrap{text-align:center;margin:0 auto 20px;}
.chooseTemplate .tempOptionWrap .ttlWrap h3{color:#515151;font-size:2rem;font-weight:600;margin:0 auto;}
.chooseTemplate .tempOptionWrap ul{display:flex;flex-flow:row wrap;align-items:center;justify-content:center;}
.chooseTemplate .tempOptionWrap ul li{width:320px;margin:0 10px;}
.chooseTemplate .tempOptionWrap ul li .itemWrap{box-shadow:0px 4px 4px 0px #00000033;border-radius:20px;border:1px solid #F0F0F0}
.chooseTemplate .tempOptionWrap ul li .itemWrap .imgWrap{height:140px;width:100%;background-color:#D9D9D9;border-radius:20px 20px 0 0;overflow:hidden;}
.chooseTemplate .tempOptionWrap ul li .itemWrap .imgWrap img{width:100%;height:100%;object-fit:cover;}
.chooseTemplate .tempOptionWrap ul li .itemWrap .dataWrap{background-color:#F4F6FF;border-radius:0 0 20px 20px;}
.chooseTemplate .tempOptionWrap ul li .itemWrap .dataWrap .topData{min-height:130px;padding:16px;}
.chooseTemplate .tempOptionWrap ul li .itemWrap .dataWrap .topData h2{color:#515151;font-size:1.4rem;font-weight:600;}
.chooseTemplate .tempOptionWrap ul li .itemWrap .dataWrap .topData p{color:#515151;font-size:1rem;font-weight:400;letter-spacing:0.1px;   margin:0;line-height:1.6rem;}
.chooseTemplate .tempOptionWrap ul li .itemWrap .dataWrap .ctaWrap{text-align:center;border-top:1px solid #CCCCCC;}
.chooseTemplate .tempOptionWrap ul li .itemWrap .dataWrap .ctaWrap .btn{outline:0;border:0;box-shadow:none;color:#1944F1;font-size:1.5rem;font-weight:400;width:100%;border-radius:0 0 20px 20px;padding:12px 10px;}
.chooseTemplate .tempOptionWrap ul li .itemWrap .dataWrap .ctaWrap .btn svg{margin:0 6px 0 0;}

.content.workinProgressWrap{padding:60px 4px 0 4px;}
.workinProgressWrap .itemWrap{width:100%;border-radius:10px;background-color:#ffffff;padding:10px 0 16px;height:calc(100vh - 70px);display:flex;flex-flow:column;justify-content:center;align-items:center;}
.workinProgressWrap .itemWrap .imgWrap{max-width:420px;}
.workinProgressWrap .itemWrap .imgWrap img{width:100%;}
.workinProgressWrap .itemWrap .dataWrap{text-align:center;margin:20px auto 0;}
.workinProgressWrap .itemWrap .dataWrap h2{color:#000000;font-weight:600;font-size:5rem;margin:0 0 16px;}
.workinProgressWrap .itemWrap .dataWrap p{color:#ACACAC;font-size:3rem;font-weight:500;margin:0;}


.deletePopupWrap.modal.show .modal-dialog{max-width:420px;display:flex;align-items:center;justify-content:center;height:100%;}
.deletePopupWrap .modal-content{border:0;border-radius:10px;padding:0;}
.deletePopupWrap .modal-header{border-bottom:1px solid #dee2e6;text-align:center;padding:0.8rem;}
.deletePopupWrap .modal-header .modal-title{width:100%;color:#000000;font-weight:600;font-size:2rem;}
.deletePopupWrap .modal-header .btn-close{display:none;}
.deletePopupWrap .modal-body{padding:1.6rem 1.2rem;}
.deletePopupWrap .modal-body p{margin:0;color:#282828;font-size:1.4rem;}
.deletePopupWrap .modal-footer .btn{font-size:1.1rem;font-weight:500;padding:0.6rem 1.6rem;outline:0;box-shadow:none;border:0;}
.deletePopupWrap .modal-footer .btn.btn-secondary{background-color:#acacac;}
.deletePopupWrap .modal-footer .btn.btn-secondary:hover{background-color:#969696;}
.deletePopupWrap .modal-footer .btn.btn-danger{background-color:#ff3a4d;}
.deletePopupWrap .modal-footer .btn.btn-danger:hover{background-color:#dc3545;}


.conformLangPopup.addNewFieldsPopup.modal.show .modal-dialog,
.msgTempSubPopup.addNewFieldsPopup.modal.show .modal-dialog{max-width:520px;}
.conformLangPopup.addNewFieldsPopup .modal-header,
.msgTempSubPopup.addNewFieldsPopup .modal-header {padding:0;}
.conformLangPopup.addNewFieldsPopup .modal-header .btn-close,
.msgTempSubPopup.addNewFieldsPopup .modal-header .btn-close{display:inline-block;outline:0;box-shadow:none;padding:0;border:0.1rem solid #5c5c5c;background-size:0.8rem;width:1.8rem;height:1.8rem;border-radius:100%;--bs-btn-close-opacity:0.3;--bs-btn-close-color:#B7B7B7;position:absolute;right:1.4rem;top:1.4rem;}
.conformLangPopup.addNewFieldsPopup .modal-content,
.msgTempSubPopup.addNewFieldsPopup .modal-content{padding:3rem 4.6rem;}
.conformLangPopup .dataWrap{text-align:left;}
.conformLangPopup .dataWrap h2{color:#000000;font-size:1.8rem;font-weight:500;margin:0 0 0.3rem;}
.conformLangPopup .dataWrap p{color:#000000;font-weight:400;font-size:1.2rem;margin:0;line-height:1.8rem;letter-spacing:0.1px;}
.conformLangPopup .selectLangWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;margin:2rem 0 1.6rem;}
.conformLangPopup .selectLangWrap .leftWrap h2{font-size:1.8rem;color:#000000;font-weight:600;margin:0 0 0.1rem;line-height:1.8rem;}
.conformLangPopup .selectLangWrap .leftWrap h2 svg{margin:0 0 0 0.2rem;}
.conformLangPopup .selectLangWrap .leftWrap p{color:#949494;margin:0;font-size:1.2rem;font-weight:400;}
.conformLangPopup .selectLangWrap .rightWrap .checkBoxWrap{margin:0;}
.conformLangPopup .selectLangWrap .rightWrap .checkBoxWrap .form-check-input{margin:0;outline:0;box-shadow:none;border:0.5px solid #B6B6B6;width:24px!important;height:24px!important;padding:0;border-radius:6px!important;}
.conformLangPopup .selectLangWrap .rightWrap .checkBoxWrap .form-check-input:checked{background-color:#1944F1;border-color:#1944F1;box-shadow:none;outline:0;}
.form-check-input:focus{box-shadow:none;outline:0;}
.conformLangPopup .btmCtaWrap{text-align:center;}
.conformLangPopup .btmCtaWrap .btn{outline:0;box-shadow:none;font-weight:500;font-size:1.2rem;margin:0 0.4rem;padding:0.8rem 2rem;}
.conformLangPopup .btmCtaWrap .btnCancel{border:0.5px solid #A1A1A1;background-color:#ffffff;color:#A0A0A0;}
.conformLangPopup .btmCtaWrap .btnSubmit{border:0.5px solid #1944F1;background-color:#1944F1;color:#ffffff;}
.msgTempSubPopup .itemWrap{text-align:center;}
.msgTempSubPopup .itemWrap .icoWrap{margin:0 auto 1.2rem;}
.msgTempSubPopup .itemWrap .icoWrap svg{width:50px;height:50px;}
.msgTempSubPopup .itemWrap .dataWrap{text-align:center;}
.msgTempSubPopup .itemWrap .dataWrap h2{color:#000000;font-size:1.8rem;font-weight:500;margin:0 0 0.3rem;}
.msgTempSubPopup .itemWrap .dataWrap p{color:#000000;font-weight:400;font-size:1.2rem;margin:0;line-height:1.8rem;letter-spacing:0.1px;}
.msgTempSubPopup .itemWrap .dataWrap p span{font-weight:500;}
.msgTempSubPopup .itemWrap .btmCtaWrap{margin:2rem auto 0;text-align:center;}
.msgTempSubPopup .itemWrap .btmCtaWrap .btn{color:#ffffff;background-color:#1944F1;border:0;box-shadow:none;outline:none;font-weight:500;font-size:1.2rem;padding:0.8rem 2rem;}

.content.smsFlowPage{padding:60px 10px 0;}
.smsFlowWrap{background-color:#ffffff;padding:1rem;border-radius:10px;} 
.smsFlowWrap .martBtm{margin:0;}
.smsFlowWrap .pad0{padding:0;}
.smsFlowWrap .topFieldWrap{width:320px;} 
.smsFlowWrap .topFieldWrap input{border:1px solid #C1C1C1;border-radius:5px;height:36px;width:100%;padding:0.2rem 1rem;color:#000000;font-size:1.3rem;color:#000000;} 
.smsFlowWrap .topFieldWrap input::placeholder{color:#cacaca;font-size:1.2rem;letter-spacing:0.02rem;}
.smsFlowWrap .topCtaWrap{text-align:right;} 
.smsFlowWrap .topCtaWrap .btn{border:0;color:#ffffff;background-color:#1944F1;font-size:1.3rem;font-weight:500;outline:0;border-radius:5px;padding:0.6rem 1.6rem;margin:0 0 0 0.8rem;} 
.smsFlowWrap .mainFlowWrap{height:calc(100vh - 126px);}
.smsFlowWrap .rightCardWrap{box-shadow:1px 1px 6px 1px #00000033;height:calc(100vh - 70px);position:relative;margin:-4.6rem -0.1rem 0 0;background-color:#ffffff;border-radius:10px;}
.smsFlowWrap .mainFlowWrap .messageNodeWrap .messageCard .bodyWrap{margin:0 1.3rem 0.5rem;}
.smsFlowWrap .mainFlowWrap .messageNodeWrap .messageCard .bodyWrap p{font-size:1.2rem;line-height:1.8rem;font-weight:500;}
.smsFlowWrap .mainFlowWrap .messageNodeWrap .messageCard .optionsWrap{margin:0 1.3rem;}
.smsFlowWrap .mainFlowWrap .messageNodeWrap .messageCard .optionsWrap p{margin:0 0 0.4rem;background-color:#bccbff;border-radius:5px;padding:0.4rem 1rem;font-size:1.2rem;line-height:1.8rem;font-weight:500;}
.smsFlowWrap .mainFlowWrap .messageNodeWrap .messageCard .optionsWrap p span{font-weight:400;display:inline-block;margin:0 0 0 0.4rem;}
.smsFlowWrap .mainFlowWrap .messageNodeWrap .messageCard button{background-color:#d8dfff;border:1px solid #7A94F2;color:#7A94F2;font-size:1rem;font-weight:600;letter-spacing:0.2px;padding:0.4rem 1.6rem;border-radius:5px;line-height:1.8rem;text-align:center;margin:0.8rem auto 0;display:flex;align-items:center;justify-content:center;text-transform:capitalize;}
.smsFlowWrap .rightCardWrap .topTtlWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;border-bottom:1px solid #c1c1c1;padding:1rem 1.4rem;}
.smsFlowWrap .rightCardWrap .topTtlWrap h3{margin:0;font-size:1.6rem;font-weight:600;color:#000000;}
.smsFlowWrap .rightCardWrap .topTtlWrap button{color:#ffffff;background-color:#1944F1;font-size:1.1rem;line-height:1.8rem;font-weight:500;border-radius:4px;padding:0.3rem 1rem}
.smsFlowWrap .rightCardWrap ul{padding:1rem 1.6rem;}
.smsFlowWrap .rightCardWrap ul li{margin:0;line-height:normal;}
.smsFlowWrap .rightCardWrap ul li .inputField{margin:0 0 0.8rem;}
.smsFlowWrap .rightCardWrap ul li .inputField label{width:100%;font-size:1.1rem;font-weight:500;letter-spacing:0.1px;margin:0;line-height:1.4rem;}
.smsFlowWrap .rightCardWrap ul li .inputField input,
.smsFlowWrap .rightCardWrap ul li .inputField textarea{border:1px solid #C1C1C1;border-radius:5px;width:100%;height:30px;padding:0.2rem 1rem;font-size:1.3rem;}
.smsFlowWrap .rightCardWrap ul li .inputField textarea{resize:none;height:50px;margin:0 0 -0.6rem;}
.smsFlowWrap .rightCardWrap ul li .ctaWrap{margin:1.2rem auto 0;}
.smsFlowWrap .rightCardWrap ul li .ctaWrap button{outline:0;box-shadow:none;border:0;width:100%;padding:0.6rem 1rem;font-size:1.2rem;font-weight:500;color:#ffffff;background-color:#1944F1;}

.workFlowChartWrap .flowNameField{width:100%;margin:0 auto 0.8rem;padding:0.2rem 1rem;border:1px solid #cfcfcf;border-radius:6px;font-size:1.1rem;color:#000000;font-weight:500;}
.workFlowChartWrap .flowNameField::placeholder{color:#B5B5B5;font-size:1.2rem;font-weight:400;}
.app-container.appWorkFlow .toggle-btn{display:none;}

.header .rightPannel .rightNavWrap ul li .dropdown .dropdown-toggle{background-color:transparent;border:0;padding:0;line-height:normal;display:flex;align-items:center;justify-content:center;outline:0;box-shadow:none;}
.header .rightPannel .rightNavWrap ul li .dropdown .dropdown-toggle .imgWrap{width:38px;height:38px;}
.header .rightPannel .rightNavWrap ul li .dropdown .dropdown-toggle .imgWrap img{width:100%;height:100%;object-fit:cover;}
.header .rightPannel .rightNavWrap ul li .dropdown .dropdown-menu{-webkit-transition:all .3s ease-out 0s;-moz-transition:all .3s ease-out 0s;-ms-transition:all .3s ease-out 0s;-o-transition:all .3s ease-out 0s;transition:all .3s ease-out 0s;padding:0;border:0.5px solid #D9D9D9;box-shadow:0px 4px 4px 0px #00000033;}
.header .rightPannel .rightNavWrap ul li .dropdown .dropdown-menu .dropdown-item{font-size:1.2rem;color:#000000;letter-spacing:0.04rem;}
.header .rightPannel .rightNavWrap ul li .dropdown .dropdown-menu .dropdown-item svg{margin:0 0.8rem 0 0;}
.dropdown-item.active, .dropdown-item:active{background-color:#1944F112!important;}
.header .rightPannel .rightNavWrap ul li .dropdown .dropdown-menu .dropdown-item:hover{color:#1944F1;background-color:#1944F112!important;}
.header .rightPannel .rightNavWrap ul li .dropdown .dropdown-menu .dropdown-item:hover svg path{fill:#1944F1;}

.switchContainer{display:inline-block;width:50px;height:24px;cursor:pointer;}
.switch{width:100%;height:100%;background-color:#cccccc;border-radius:30px;position:relative;transition:background-color 0.3s ease;}
.switchOn{background-color:#1944F1;}
.switchToggle{width:20px;height:20px;background-color:#ffffff;border-radius:100%;position:absolute;top:2px;left:2px;transition:left 0.3s ease;}
.switchOn .switchToggle{left:28px;}